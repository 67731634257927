import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { UpdateDoctorContactDetailsActionTypes } from "./ActionTypes";
import { UpdateDoctorContactDetailsBody, UpdateDoctorContactDetailsRes } from "./Model";
import {
  updateDoctorContactDetailsAPIResClearAction,
  updateDoctorContactDetailsErrorAction,
  updateDoctorContactDetailsLoadingAction,
  updateDoctorContactDetailsSuccessAction,
  updateDoctorContactDetailsUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as UpdateDoctorContactDetailsRes;
export const updateDoctorContactDetails = (payload: UpdateDoctorContactDetailsBody) => {
  return function (dispatch: Dispatch<UpdateDoctorContactDetailsActionTypes>) {
    dispatch(updateDoctorContactDetailsLoadingAction(true));
    axios
      .post(Url.baseUrl + `/adminapp/api/doctorContact/updateContactDetailsOfDoctor`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(updateDoctorContactDetailsLoadingAction(false));
        dispatch(
          updateDoctorContactDetailsSuccessAction(
            res.data,
            "Dr. contact details has been updated successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(updateDoctorContactDetailsLoadingAction(false));
        dispatch(
          updateDoctorContactDetailsErrorAction(
            {} as UpdateDoctorContactDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDoctorContactDetailsUpdateAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdateDoctorContactDetailsActionTypes>) {
    dispatch(
      updateDoctorContactDetailsUpdateAPIMsgAction(
        apiRes as UpdateDoctorContactDetailsRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearDoctorContactDetailsUpdateAPIRes = () => {
  return function (dispatch: Dispatch<UpdateDoctorContactDetailsActionTypes>) {
    dispatch(
      updateDoctorContactDetailsAPIResClearAction({} as UpdateDoctorContactDetailsRes, "", "", 0),
    );
  };
};
