import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { DoctorInactiveNoteListAPIRes } from "./ActionTypes";
import { DoctorInactiveListModel, DoctorInactiveListResponse } from "./Model";
import {
  doctorInactiveListAPIResClearAction,
  doctorInactiveListErrorAction,
  doctorInactiveListLoadingAction,
  doctorInactiveListSuccessAction,
  doctorInactiveListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as DoctorInactiveListResponse;
export const doctorInactiveListApi = (payload: DoctorInactiveListModel) => {
  return function (dispatch: Dispatch<DoctorInactiveNoteListAPIRes>) {
    dispatch(doctorInactiveListLoadingAction(true));
    axios
      .post(Url.baseUrl + `/erpapp/api/adminNote/get_admin_notes_by_doctor`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(doctorInactiveListLoadingAction(false));
        dispatch(
          doctorInactiveListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. Active Note List Not Available",
          ),
        );
      })
      .catch((error) => {
        dispatch(doctorInactiveListLoadingAction(false));
        dispatch(
          doctorInactiveListErrorAction(
            {} as DoctorInactiveListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDoctorInactiveNoteListAPIResMsg = () => {
  return function (dispatch: Dispatch<DoctorInactiveNoteListAPIRes>) {
    dispatch(doctorInactiveListAPIMsgAction(apiRes as DoctorInactiveListResponse, "", "", 0));
  };
};

export const clearDoctorInactiveNoteListAPIRes = () => {
  return function (dispatch: Dispatch<DoctorInactiveNoteListAPIRes>) {
    dispatch(doctorInactiveListAPIResClearAction({} as DoctorInactiveListResponse, "", "", 0));
  };
};
