import {
  SECOND_OPINION_TYPE_FAIL,
  SECOND_OPINION_TYPE_LOADING,
  SECOND_OPINION_TYPE_SUCCESS,
  SECOND_OPINION_TYPE_API_MSG,
  SECOND_OPINION_TYPE_API_RES,
  SecondOpinionTypeActionTypes,
} from "./ActionTypes";
import { SecondOpinionListResponse } from "./Model";

export const secondSecondOpinionTypeLoadingAction = (
  loading: boolean,
): SecondOpinionTypeActionTypes => {
  return {
    type: SECOND_OPINION_TYPE_LOADING,
    loading: loading,
  };
};

export const secondSecondOpinionTypeSuccessAction = (
  secondSecondOpinionTypeResponse: SecondOpinionListResponse,
  successMsg: string,
): SecondOpinionTypeActionTypes => {
  return {
    type: SECOND_OPINION_TYPE_SUCCESS,
    payload: secondSecondOpinionTypeResponse,
    successMsg: successMsg,
  };
};

export const secondSecondOpinionTypeErrorAction = (
  secondSecondOpinionTypeResponse: SecondOpinionListResponse,
  errMsg: string,
  status: number,
): SecondOpinionTypeActionTypes => {
  return {
    type: SECOND_OPINION_TYPE_FAIL,
    payload: secondSecondOpinionTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const secondSecondOpinionTypeAPIMsgAction = (
  secondSecondOpinionTypeResponse: SecondOpinionListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): SecondOpinionTypeActionTypes => {
  return {
    type: SECOND_OPINION_TYPE_API_MSG,
    payload: secondSecondOpinionTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const secondSecondOpinionTypeAPIResClearAction = (
  secondSecondOpinionTypeResponse: SecondOpinionListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): SecondOpinionTypeActionTypes => {
  return {
    type: SECOND_OPINION_TYPE_API_RES,
    payload: secondSecondOpinionTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
