import React, { useState } from "react";
import { Box, Container, Tab } from "@mui/material";
import ActiveDrNotes from "./ActiveDrNotes";
import InactiveDrNotes from "./InActiveDrNotes";
import AdminLoader from "../../AdminLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MWPageTitle from "../../../component/MWPageTitle";
import { t } from "i18next";

export default function NotesDetails() {
  const loadingMarkup = <AdminLoader />;
  const [activeNoteLoader, setActiveNoteLoader] = useState(false);
  const [inactiveNoteLoader, setInactiveNoteLoader] = useState(false);
  const [selected, setSelected] = useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  return (
    <Box>
      {activeNoteLoader === true || inactiveNoteLoader === true ? loadingMarkup : null}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <MWPageTitle backButton={true} title="Patient Notes" />
        <TabContext value={selected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab label={t("common.activeNoteList", { ns: ["home"] })!} value="1" />
              <Tab label={t("common.inactiveNoteList", { ns: ["home"] })!} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">{<ActiveDrNotes activeNoteLoader={setActiveNoteLoader} />}</TabPanel>
          <TabPanel value="2">
            {<InactiveDrNotes inactiveNoteLoader={setInactiveNoteLoader} />}
          </TabPanel>
        </TabContext>
      </Container>
    </Box>
  );
}
