import {
  ADD_CASE_FAIL,
  ADD_CASE_LOADING,
  ADD_CASE_SUCCESS,
  ADD_CASE_API_MSG,
  ADD_CASE_API_RES,
  AddCaseActionTypes,
} from "./ActionTypes";
import { AddCaseResponse } from "./Model";

export const addCaseLoadingAction = (loading: boolean): AddCaseActionTypes => {
  return {
    type: ADD_CASE_LOADING,
    loading: loading,
  };
};

export const addCaseSuccessAction = (
  addCaseResponse: AddCaseResponse,
  successMsg: string,
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_SUCCESS,
    payload: addCaseResponse,
    successMsg: successMsg,
  };
};

export const addCaseErrorAction = (
  addCaseResponse: AddCaseResponse,
  errMsg: string,
  status: number,
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_FAIL,
    payload: addCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addCaseAPIMsgAction = (
  addCaseResponse: AddCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_API_MSG,
    payload: addCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addCaseAPIResClearAction = (
  addCaseResponse: AddCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_API_RES,
    payload: addCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
