import {
  DOCTOR_QURIES_LIST_API_MSG,
  DOCTOR_QURIES_LIST_CLEAR_API_RES,
  DOCTOR_QURIES_LIST_FAIL,
  DOCTOR_QURIES_LIST_LOADING,
  DOCTOR_QURIES_LIST_SUCCESS,
  DoctorQuriesListDataState,
  DoctorQuriesListActionTypes,
} from "./ActionTypes";
import { DoctorQuriesListResponse } from "./Model";

const initialStateGetPosts: DoctorQuriesListDataState = {
  loading: false,
  doctorQueriesRes: {} as DoctorQuriesListResponse,
  message: "",
  status: 0,
};

export const doctorQuriesListReducer = (
  state = initialStateGetPosts,
  action: DoctorQuriesListActionTypes,
): DoctorQuriesListDataState => {
  switch (action.type) {
    case DOCTOR_QURIES_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DOCTOR_QURIES_LIST_SUCCESS:
      return {
        ...state,
        doctorQueriesRes: action.payload,
      };
    case DOCTOR_QURIES_LIST_FAIL:
      return {
        ...state,
        doctorQueriesRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case DOCTOR_QURIES_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        doctorQueriesRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case DOCTOR_QURIES_LIST_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        doctorQueriesRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
