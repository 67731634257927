import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorOnboardingIncompleteList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { DocotorOnboardIncompleteBody } from "../../redux/doctorsOnboardingImcomplete/Model";
import {
  clearDrApproveRejectAPIRes,
  onBoardDoctorIncomplete,
} from "../../redux/doctorsOnboardingImcomplete/API";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import { t } from "i18next";
import MWSnackbar from "../../component/MWSnackbar";

export default function DoctorsOnboardingImcomplete(props: any) {
  const { docOndoardingIncompleteLoading } = props;
  const [isLoading, setIsLoading] = useState(false);
  docOndoardingIncompleteLoading(isLoading);
  const dispatch = useDispatch();
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [addDrApproveRejectMsgType, setAddDrApproveRejectMsgType] = useState<AlertColor>("success");
  const [addDrApproveRejectMsg, setAddDrApproveRejectMsg] = useState("");
  // approve reject api call........
  const doctorOnBoardingApproveOrReject = (doctorId: any, approveOrreject: any) => {
    setIsLoading(true);
    console.log("doctorId=", doctorId);
    dispatch(
      onBoardDoctorIncomplete({
        isApproved: approveOrreject,
        onboardReqId: doctorId,
      } as DocotorOnboardIncompleteBody),
    );
  };
  // Call Dr Approve Reject store
  const drApproveRejectValue = useSelector((state: AppState) => state.doctorOnboardingStatus);

  useEffect(() => {
    if (drApproveRejectValue?.successMsg !== undefined) {
      setAddDrApproveRejectMsg(drApproveRejectValue?.successMsg);
      setAddDrApproveRejectMsgType("success");
      dispatch(getDoctorOnboardingIncompleteList());
    }
    if (drApproveRejectValue?.errorMsg !== "") {
      setAddDrApproveRejectMsgType("error");
      setAddDrApproveRejectMsg(drApproveRejectValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drApproveRejectValue]);

  useEffect(() => {
    dispatch(getDoctorOnboardingIncompleteList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(clearDrApproveRejectAPIRes());
    }
  };
  // Api Call from Store....
  const docotorList = useSelector((state: AppState) => state.doctorIncompleteValue);
  const docIncompleteList = docotorList.doctorIncompleteValue;
  useEffect(() => {
    if (docIncompleteList !== undefined) {
      let dataPointArr = docIncompleteList.map((element: any) => ({
        id: element.id,
        doctorId: element.id,
        name: element.fullName,
        qualification: element.qualification,
        specialty: element.specialty,
        yearsOfExperience: element.yearsOfExperience,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docotorList]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("docOnboarding.name", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "qualification",
      headerName: `${t("doctorlistpage.qualification", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "specialty",
      headerName: `${t("appointmentpage.speciality", { ns: ["home"] })!}`,
      type: "string",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "yearsOfExperience",
      headerName: `${t("docOnboarding.yearofExp", { ns: ["home"] })!}`,
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "doctorId",
      headerName: `${t("docOnboarding.approve", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<CheckIcon />}
          onClick={() => {
            doctorOnBoardingApproveOrReject(params.row.doctorId, "True");
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          {t("docOnboarding.approve", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "id",
      headerName: `${t("appointmentpage.reject", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<CloseIcon />}
          onClick={() => {
            doctorOnBoardingApproveOrReject(params.row.doctorId, "False");
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          {t("appointmentpage.reject", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];

  const doctorOnBoardingIncompleteList = (
    <Card>
      <CardHeader />
      <CardContent>
        {docIncompleteList && docIncompleteList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {docotorList.loading === true
        ? docOndoardingIncompleteLoading(true)
        : docOndoardingIncompleteLoading(false)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {doctorOnBoardingIncompleteList}
          {addDrApproveRejectMsg !== "" ? (
            <Box my={2}>
              <MWSnackbar
                msg={addDrApproveRejectMsg}
                type={addDrApproveRejectMsgType}
                alertClose={alertCloseAction}
              />
            </Box>
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
}
