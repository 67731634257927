import React, { useState, useCallback, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteOpinion, getOpinionApi } from "../../redux/Patient/FinalOpinion/ApiCall";
import { AppState } from "../../redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { t } from "i18next";

interface Props {
  appointmentFinalOpinionLoader: any;
}

export default function FinalOpinion({ appointmentFinalOpinionLoader }: Props) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [patientDetailsId, setPatientDetailsId] = useState("");
  const dispatch = useDispatch();

  // const { appointmentFinalOpinionLoader } = props;
  const [imageInfo, setImageInfo] = useState("");

  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive],
  );

  const gotoViewDocument = (supportingDocuments: any) => {
    setDocViwerActive(true);
    setImageInfo(supportingDocuments);
  };

  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const handleDeleteSecondOpinionModalChange = useCallback(
    () => setDeleteModalActive(!deleteModalActive),
    [deleteModalActive],
  );

  // Delete popup open ........
  function deleteMedicalSummary(patientDetailsID: any) {
    setPatientDetailsId(patientDetailsID);
    setDeleteModalActive((deleteModalActive) => !deleteModalActive);
  }

  const getSecondOpinionBody = {
    caseId: caseid,
    secondOpinionUiSection: "finalOpinion",
  };
  const getSecondOpinionList = () => {
    dispatch(getOpinionApi(getSecondOpinionBody));
  };

  // Opinion value
  const opininionValue = useSelector((state: AppState) => state.opinion);
  const opinionListValue = opininionValue?.opinionValue;
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    if (opininionValue?.opinionValue !== undefined) {
      let dataPointArr = opininionValue?.opinionValue.map((element: any) => ({
        id: element.id,
        description: element.description,
        dateStart:
          element.startDate !== null ? dayjs(element.startDate).format("MMMM D, YYYY") : "",
        supportingDocuments:
          element.patientDocument !== null ? element.patientDocument.document : "",
        dateEnd: element.endDate !== null ? dayjs(element.endDate).format("MMMM D, YYYY") : "",
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opininionValue]);

  const columns: GridColDef[] = [
    {
      field: "supportingDocuments",
      headerName: `${t("finalOpinion.doc", { ns: ["home"] })!}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DescriptionIcon />}
          variant="outlined"
          onClick={() => {
            gotoViewDocument(params.value);
          }}
        ></Button>
      ),
    },
    {
      field: "description",
      headerName: `${t("finalOpinion.description", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "dateStart",
      headerName: `${t("finalOpinion.startdate", { ns: ["home"] })!}`,
      width: 350,
      flex: 1,
    },
    {
      field: "dateEnd",
      headerName: `${t("finalOpinion.enddate", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("finalOpinion.delete", { ns: ["home"] })!}`,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DeleteIcon />}
          variant="outlined"
          onClick={() => {
            deleteMedicalSummary(params.value);
          }}
        ></Button>
      ),
      width: 250,
      flex: 1,
    },
  ];

  // Delete body....
  const deleteSecondOpinionRecordBody = {
    caseId: caseid,
    patientDetailId: patientDetailsId,
  };
  // delete api call here ....
  const DeleteSecondOpinionSubmit = () => {
    dispatch(deleteOpinion(deleteSecondOpinionRecordBody));
  };

  // Delete Opinion value
  const DeleteOpinionListFromAPI = useSelector((state: AppState) => state.deleteOpinion);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSecondOpinionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialogClose = () => {
    setDocViwerActive(false);
  };

  const secondOpinionTableBody = (
    <div>
      <Card>
        <CardHeader title={t("finalOpinion.documents", { ns: ["home"] })!}></CardHeader>
        <CardContent>
          {opinionListValue && opinionListValue.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </div>
  );

  const docViewerComponent = (
    <Dialog
      open={docViwerActive}
      onClose={handleDocViewerChange}
      PaperProps={{
        style: {
          minHeight: "40vh",
          minWidth: "50vw",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle id="customized-dialog-title">
        {t("finalOpinion.viewdoc", { ns: ["home"] })!}
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container>
          <Typography>
            <iframe src={imageInfo} title="Small document" width="800" height="700">
              This is an embedded{" "}
              <a target="_blank" href="http://office.com" rel="noopener noreferrer">
                Microsoft Office
              </a>{" "}
              document, powered by{" "}
              <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
                Office Online
              </a>
              .
            </iframe>
          </Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );

  // Delete modal section ....
  const deleteSecondOpinionDetailsBody = (
    <Dialog open={deleteModalActive} onClose={handleDeleteSecondOpinionModalChange} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography>{t("finalOpinion.deletetitle", { ns: ["home"] })!}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleDeleteSecondOpinionModalChange}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography style={{ fontWeight: "bold" }}>
            {t("finalOpinion.deletedoc", { ns: ["home"] })!}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={handleDeleteSecondOpinionModalChange}>
          {t("finalOpinion.deloption2", { ns: ["home"] })!}
        </Button>
        <Button variant="contained" size="medium" onClick={DeleteSecondOpinionSubmit}>
          {t("finalOpinion.deloption1", { ns: ["home"] })!}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <Box>
      {opininionValue?.loading === true || DeleteOpinionListFromAPI?.loading === true
        ? appointmentFinalOpinionLoader(true)
        : appointmentFinalOpinionLoader(false)}
      <MWPageTitle title={t("finalOpinion.finalopinion", { ns: ["home"] })!} />
      <Box mt={1}>
        {secondOpinionTableBody}
        {docViewerComponent}
        {deleteSecondOpinionDetailsBody}
      </Box>
    </Box>
  );
}
