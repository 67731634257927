import axios from "axios";
import Url from "../../../ApiConfig";
import { Dispatch } from "react";
import {
  CorporatePackageToggleTypes,
  CORPORATE_PACKAGE_TOGGLE_FAIL,
  CORPORATE_PACKAGE_TOGGLE_LOADING,
  CORPORATE_PACKAGE_TOGGLE_SUCCESS,
} from "./ActionTypes";
import { getCorporatepackageToggleActions } from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

// Active Corporate List API
export const getCorporatePackageToggleStatus = (packageId: Number) => {
  return function (dispatch: Dispatch<CorporatePackageToggleTypes>) {
    dispatch({
      type: CORPORATE_PACKAGE_TOGGLE_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
          `/paymentapp/api/corporatePackageForAdmin/${Number(
            packageId,
          )}/toggle_active_corporatePackage`,
        { headers: headers },
      )
      .then((res) => {
        let response = res.data;
        dispatch(getCorporatepackageToggleActions(response));
        dispatch({
          type: CORPORATE_PACKAGE_TOGGLE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        let response = error.response.data;
        dispatch({
          type: CORPORATE_PACKAGE_TOGGLE_FAIL,
          payload: response,
        });
      });
  };
};
