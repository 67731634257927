import {
  AddSummaryActionTypes,
  AddSummaryStateType,
  AddWorkScheduleActionTypes,
  AddWorkScheduleStateType,
  ADD_SUMMARY_FAIL,
  ADD_SUMMARY_LOADING,
  ADD_SUMMARY_SUCCESS,
  ADD_WORKSCHEDULE_FAIL,
  ADD_WORKSCHEDULE_LOADING,
  ADD_WORKSCHEDULE_SUCCESS,
  AdminAppointmentGetActionTypes,
  APPOINTMENT_LIST_FAIL,
  APPOINTMENT_LIST_LOADING,
  APPOINTMENT_LIST_SUCCESS,
  ContactDetailsActionTypes,
  ContactDetailsStateType,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_LOADING,
  CONTACT_DETAILS_SUCCESS,
  DeleteWorkScheduleActionTypes,
  DeleteWorkScheduleStateType,
  DELETE_WORK_SCHEDULE_FAIL,
  DELETE_WORK_SCHEDULE_LOADING,
  DELETE_WORK_SCHEDULE_SUCCESS,
  DoctorDetailsActionTypes,
  DoctorDetailsStateType,
  DOCTOR_DETAILS_FAIL,
  DOCTOR_DETAILS_LOADING,
  DOCTOR_DETAILS_SUCCESS,
  GetAppointmentListStateType,
  WeekDayListActionTypes,
  WeekdayListStateType,
  WEEKDAY_LIST_FAIL,
  WEEKDAY_LIST_LOADING,
  WEEKDAY_LIST_SUCCESS,
  WorkScheduleActionTypes,
  WorkScheduleStateType,
  WORK_SCHEDULE_FAIL,
  WORK_SCHEDULE_LOADING,
  WORK_SCHEDULE_SUCCESS,
} from "./ActionTypes";

const initialStateGetAppointment: GetAppointmentListStateType = {
  loading: false,
  appointmentValue: [],
};
const initialStateAddSummary: AddSummaryStateType = { loading: false, response: Object.assign({}) };
const initialStateContactDetail: ContactDetailsStateType = {
  loading: false,
  contactDetailValue: Object.assign({}),
};
const initialStateWorkSchedule: WorkScheduleStateType = { loading: false, workscheduleValue: [] };
const initialStateWorkScheduleDelete: DeleteWorkScheduleStateType = {
  loading: false,
  deleteResponse: Object.assign({}),
};

export const doctorAppointment = (
  state = initialStateGetAppointment,
  action: AdminAppointmentGetActionTypes,
): GetAppointmentListStateType => {
  switch (action.type) {
    case APPOINTMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentValue: action.payload,
      };
    case APPOINTMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        appointmentValue: [],
      };
    case APPOINTMENT_LIST_FAIL:
      return {
        ...state,
        appointmentValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const addSummary = (
  state = initialStateAddSummary,
  action: AddSummaryActionTypes,
): AddSummaryStateType => {
  switch (action.type) {
    case ADD_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_SUMMARY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const contactDetail = (
  state = initialStateContactDetail,
  action: ContactDetailsActionTypes,
): ContactDetailsStateType => {
  switch (action.type) {
    case CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactDetailValue: action.payload,
      };
    case CONTACT_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const workScheduleDetail = (
  state = initialStateWorkSchedule,
  action: WorkScheduleActionTypes,
): WorkScheduleStateType => {
  switch (action.type) {
    case WORK_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        workscheduleValue: action.payload,
      };
    case WORK_SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case WORK_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteworkScheduleDetail = (
  state = initialStateWorkScheduleDelete,
  action: DeleteWorkScheduleActionTypes,
): DeleteWorkScheduleStateType => {
  switch (action.type) {
    case DELETE_WORK_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteResponse: action.payload,
      };
    case DELETE_WORK_SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_WORK_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
// weekdays list
const initialStateGetWeekdayslist: WeekdayListStateType = {
  loading: false,
  weekdaysListValue: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getWeekdayListReducer = (
  state = initialStateGetWeekdayslist,
  action: WeekDayListActionTypes,
): WeekdayListStateType => {
  switch (action.type) {
    case WEEKDAY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        weekdaysListValue: action.payload,
        status: action.status,
        message: action.message,
      };
    case WEEKDAY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        weekdaysListValue: [],
      };
    case WEEKDAY_LIST_FAIL:
      return {
        ...state,
        weekdaysListValue: [],
        loading: false,
        status: action.status,
        message: action.message,
      };
    default:
      return state;
  }
};
// Add work schedule
const initialStateAddWorkschedule: AddWorkScheduleStateType = {
  loading: false,
  response: Object.assign({}),
};

export const addWorkScheduleReducer = (
  state = initialStateAddWorkschedule,
  action: AddWorkScheduleActionTypes,
): AddWorkScheduleStateType => {
  switch (action.type) {
    case ADD_WORKSCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_WORKSCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_WORKSCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};
// doctor details
const initialStateDoctorDetailslist: DoctorDetailsStateType = {
  loading: false,
  doctorDetailsValue: Object.assign({}),
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getDoctorDetailsReducer = (
  state = initialStateDoctorDetailslist,
  action: DoctorDetailsActionTypes,
): DoctorDetailsStateType => {
  switch (action.type) {
    case DOCTOR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorDetailsValue: action.payload,
        status: action.status,
        message: action.message,
      };
    case DOCTOR_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DOCTOR_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    default:
      return state;
  }
};
