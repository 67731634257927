import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import {
  getExecutiveList,
  deleteExecutive,
  addExecutive,
  editExecutiveList,
} from "../../redux/Executive/ApiCall";
import { AppState } from "../../redux/store/Store";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { t } from "i18next";

export default function ExecutiveList() {
  const dispatch = useDispatch();
  const [isLoading] = useState(false);
  const loadingMarkup = <AdminLoader />;
  const history = useHistory();

  const [executiveFirstName, setExecutiveFirstName] = useState("");
  const [errorExecutiveFirstName, setErrorExecutiveFirstName] = useState("");
  const [executiveLastName, setExecutiveLastName] = useState("");
  const [errorExecutiveLastName, setErrorExecutivelastName] = useState("");
  const [executiveEmail, setExecutiveEmail] = useState("");
  const [errorExecutiveEmail, setErrorExecutiveEmail] = useState("");
  const [executivePhoneNo, setExecutivePhoneNo] = useState("");
  const [errorExecutivePhoneNo, setErrorExecutivePhoneNo] = useState("");

  const [editExecutiveId, setEditExecutiveId] = useState("");
  const [userSelectedId, setUserSelectedId] = useState("");
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [listCount, setListCount] = React.useState("");

  // Add Executive Modal
  const [executiveModalActive, setExecutiveModalActive] = useState(false);
  const handleExecutiveModalToggle = () => {
    setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
    setExecutiveFirstName("");
    setExecutiveLastName("");
    setExecutiveEmail("");
    setExecutivePhoneNo("");
    setEditExecutiveId("");
    setErrorExecutiveFirstName("");
    setErrorExecutivelastName("");
    setErrorExecutiveEmail("");
    setErrorExecutivePhoneNo("");
  };

  // Edit Executive Modal
  const [editExecutiveModalActive, setEditExecutiveModalActive] = useState(false);
  const handleEditExecutiveModalToggle = () => {
    setEditExecutiveModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
    setExecutiveFirstName("");
    setExecutiveLastName("");
    setExecutiveEmail("");
    setExecutivePhoneNo("");
    setEditExecutiveId("");
    setErrorExecutiveFirstName("");
    setErrorExecutivelastName("");
    setErrorExecutiveEmail("");
    setErrorExecutivePhoneNo("");
  };

  // Delete Executive Modal
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const handleDeleteExecutiveModalChange = () => {
    setDeleteModalActive((deleteModalActive) => !deleteModalActive);
  };

  const handleCancelExecutiveModalToggle = () => {
    setEditExecutiveModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
    setDeleteModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
    setDeleteExecutiveModalSuccess("");
    setDeleteExecutiveModalError("");
  };

  // Add  Executive Store value...
  const AddExecutiveLoadingvalue = useSelector((state: AppState) => state.addExecutive.loading);
  const AddExecutiveStatus = useSelector((state: AppState) => state.addExecutive.response.status);
  const AddExecutiveMessage = useSelector((state: AppState) => state.addExecutive.response.message);

  // Add  Executive Success toast
  const [successAddExecutiveToastActive, setSuccessAddExecutiveToastActive] = useState(false);
  const toggleSuccessAddExecutiveActive = () => {
    setSuccessAddExecutiveToastActive(
      (successAddExecutiveToastActive) => !successAddExecutiveToastActive,
    );
    dispatch(getExecutiveList());
  };
  const successAddExecutiveToastMarkup = successAddExecutiveToastActive ? (
    <Snackbar autoHideDuration={6000} onClose={toggleSuccessAddExecutiveActive}>
      <Alert onClose={toggleSuccessAddExecutiveActive} severity="success" sx={{ width: "100%" }}>
        {AddExecutiveMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Add Executive error toast
  const [addExecutiveErrorToastActive, setAddExecutiveErrorToastActive] = useState(false);
  const handleAddExecutiveErrorToastChange = () => {
    setAddExecutiveErrorToastActive(
      (addExecutiveErrorToastActive) => !addExecutiveErrorToastActive,
    );
  };
  const errorAddExecutiveToastMarkup = addExecutiveErrorToastActive ? (
    <Snackbar autoHideDuration={6000} onClose={handleAddExecutiveErrorToastChange}>
      <Alert onClose={handleAddExecutiveErrorToastChange} severity="error" sx={{ width: "100%" }}>
        {AddExecutiveMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Edit  Executive Store value...
  const EditExecutiveLoadingvalue = useSelector((state: AppState) => state.editExecutive.loading);
  const EditExecutiveStatus = useSelector((state: AppState) => state.editExecutive.response.status);
  const EditExecutiveMessage = useSelector(
    (state: AppState) => state.editExecutive.response.message,
  );
  // Edit  Executive Success toast
  const [successEditExecutiveToastActive, setSuccessEditExecutiveToastActive] = useState(false);
  const toggleSuccessEditExecutiveActive = () => {
    setSuccessEditExecutiveToastActive(
      (successEditExecutiveToastActive) => !successEditExecutiveToastActive,
    );
    dispatch(getExecutiveList());
  };
  const successEditExecutiveToastMarkup = successEditExecutiveToastActive ? (
    <Snackbar autoHideDuration={6000} onClose={toggleSuccessEditExecutiveActive}>
      <Alert onClose={toggleSuccessEditExecutiveActive} severity="success" sx={{ width: "100%" }}>
        {EditExecutiveMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // edit Executive error toast
  const [editExecutiveErrorToastActive, setEditExecutiveErrorToastActive] = useState(false);
  const handleEditExecutiveErrorToastChange = () => {
    setEditExecutiveErrorToastActive(
      (editExecutiveErrorToastActive) => !editExecutiveErrorToastActive,
    );
  };
  const errorEditExecutiveToastMarkup = editExecutiveErrorToastActive ? (
    <Snackbar autoHideDuration={6000} onClose={handleEditExecutiveErrorToastChange}>
      <Alert onClose={handleEditExecutiveErrorToastChange} severity="error" sx={{ width: "100%" }}>
        {EditExecutiveMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Go to Executive Details Page
  const ViewExecutiveDetails = (executiveId: any) => {
    history.push(`/executivetaskdetails/${executiveId}`);
  };

  //For delete popup..
  const closeSuccess = () => {
    setDeleteExecutiveModalError("");
  };

  const [deleteExecutiveModalSuccess, setDeleteExecutiveModalSuccess] = useState("");
  const successExecutiveModalDiv = deleteExecutiveModalSuccess ? (
    <Alert onClick={closeSuccess} severity="success">
      <p>{deleteExecutiveModalSuccess}</p>
    </Alert>
  ) : (
    ""
  );
  const [deleteExecutiveModalError, setDeleteExecutiveModalError] = useState("");
  const errorDeleteExecutiveModalDiv = deleteExecutiveModalError ? (
    <Alert onClick={closeSuccess} severity="error">
      <p>{deleteExecutiveModalError}</p>
    </Alert>
  ) : (
    ""
  );

  // call delete executive store

  const DeleteExecutiveLoadingvalue = useSelector(
    (state: AppState) => state.deleteExecutive.loading,
  );
  const DeleteExecutiveStatus = useSelector(
    (state: AppState) => state.deleteExecutive.response.status,
  );
  const DeleteExecutiveMessage = useSelector(
    (state: AppState) => state.deleteExecutive.response.message,
  );
  const executiveListApi = () => {
    dispatch(getExecutiveList());
  };
  // Delete Executive success toast
  const [successDeleteExecutiveToastActive, setDeleteExecutiveToastActive] = useState(false);
  const toggleSuccessDeleteExecutiveActive = () => {
    setDeleteExecutiveToastActive(
      (successDeleteExecutiveToastActive) => !successDeleteExecutiveToastActive,
    );
    dispatch(getExecutiveList());
  };

  const toastDeleteExecutiveSuccessMarkup = successDeleteExecutiveToastActive ? (
    <Snackbar autoHideDuration={6000} onClose={toggleSuccessDeleteExecutiveActive}>
      <Alert onClose={toggleSuccessDeleteExecutiveActive} severity="success" sx={{ width: "100%" }}>
        {DeleteExecutiveMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Delete Executive error toast
  const [deleteExecutiveErrorToastActive, setdeleteExecutiveErrorToastActive] = useState(false);
  const handleDeleteExecutiveErrorToastChange = () => {
    setdeleteExecutiveErrorToastActive(
      (deleteExecutiveErrorToastActive) => !deleteExecutiveErrorToastActive,
    );
  };
  const toastDeleteExecutiveErrorMarkup = deleteExecutiveErrorToastActive ? (
    <Snackbar autoHideDuration={6000} onClose={handleDeleteExecutiveErrorToastChange}>
      <Alert
        onClose={handleDeleteExecutiveErrorToastChange}
        severity="error"
        sx={{ width: "100%" }}
      >
        {DeleteExecutiveMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Edit Executive Modal Open Function
  const editExecutive = (
    exeId: any,
    firstName: any,
    lastName: any,
    email: any,
    phoneNumber: any,
    userid: any,
  ) => {
    setEditExecutiveModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
    setEditExecutiveId(exeId);
    setUserSelectedId(userid);
    setExecutiveFirstName(firstName);
    setExecutiveLastName(lastName);
    setExecutiveEmail(email);
    setExecutivePhoneNo(phoneNumber);
  };
  // Get Executive List API
  const executiveListLoadingValue = useSelector(
    (state: AppState) => state.executiveListValue.loading,
  );
  const adminExecutiveList = useSelector((state: AppState) => state.executiveListValue);
  const executiveListValue = useSelector(
    (state: AppState) => adminExecutiveList.executiveListValue,
  );

  useEffect(() => {
    executiveListValue?.length !== undefined
      ? setListCount(String(executiveListValue?.length))
      : setListCount("0");
    if (executiveListValue !== undefined) {
      let dataPointArr = executiveListValue.map((element: any) => ({
        id: element.id,
        userid: element.userId,
        executiveEmail: element.email,
        executiveFirstName: element.first_name,
        executiveLastName: element.last_name,
        executivePhoneNumber: element.phoneNumber,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminExecutiveList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("common.view", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            ViewExecutiveDetails(params.row.id);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          {t("common.task", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "executiveFirstName",
      headerName: `${t("common.firstname", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "executiveLastName",
      headerName: `${t("common.lastname", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "executiveEmail",
      headerName: `${t("execuList.exeEmail", { ns: ["home"] })!}`,
      type: "string",
      width: 250,
      align: "left",
      flex: 1,
    },
    {
      field: "executivePhoneNumber",
      headerName: `${t("accountdetail.phoneno", { ns: ["home"] })!}`,
      sortable: false,
      width: 80,
      align: "left",
      flex: 1,
    },

    {
      field: "userid",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          sx={{ textTransform: "none" }}
          onClick={() => {
            editExecutive(
              params.row.id,
              params.row.executiveFirstName,
              params.row.executiveLastName,
              params.row.executiveEmail,
              params.row.executivePhoneNumber,
              params.row.userId,
            );
          }}
          variant="contained"
        >
          {t("userprofilepage.edit", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];

  // Executive Add Body
  const executiveAddBody = {
    first_name: executiveFirstName,
    last_name: executiveLastName,
    email: executiveEmail,
    phoneNumber: executivePhoneNo,
  };
  // Add / Edit Executive API
  const addUpdateExecutive = () => {
    if (
      executiveFirstName === "" &&
      executiveLastName === "" &&
      executiveEmail === "" &&
      executivePhoneNo === ""
    ) {
      setErrorExecutiveFirstName(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setErrorExecutivelastName(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
      setErrorExecutiveEmail(`${t("accountdetail.enteremail", { ns: ["home"] })!}`);
      setErrorExecutivePhoneNo(`${t("accountdetail.enterphnno", { ns: ["home"] })!}`);
    } else if (executiveFirstName !== "" && executiveLastName === "" && executiveEmail !== "") {
      setErrorExecutiveFirstName(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setErrorExecutivelastName(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
      setErrorExecutiveEmail(`${t("accountdetail.enteremail", { ns: ["home"] })!}`);
    } else if (executiveFirstName === "" && executiveLastName !== "" && executiveEmail === "") {
      setErrorExecutiveFirstName(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setErrorExecutivelastName("");
      setErrorExecutiveEmail(`${t("accountdetail.enteremail", { ns: ["home"] })!}`);
    } else if (executiveFirstName === "" && executiveLastName !== "" && executiveEmail !== "") {
      setErrorExecutiveFirstName(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setErrorExecutivelastName("");
      setErrorExecutiveEmail("");
    } else if (executiveFirstName !== "" && executiveLastName === "" && executiveEmail === "") {
      setErrorExecutiveFirstName("");
      setErrorExecutivelastName(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
      setErrorExecutiveEmail(`${t("accountdetail.enteremail", { ns: ["home"] })!}`);
    } else if (executiveFirstName !== "" && executiveLastName === "" && executiveEmail !== "") {
      setErrorExecutiveFirstName("");
      setErrorExecutivelastName(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
      setErrorExecutiveEmail("");
    } else if (executiveFirstName !== "" && executiveLastName !== "" && executiveEmail === "") {
      setErrorExecutiveFirstName("");
      setErrorExecutivelastName("");
      setErrorExecutiveEmail(`${t("accountdetail.enteremail", { ns: ["home"] })!}`);
    } else {
      //setIsLoading(true);
      let executiveEditSubmitBody;
      if (editExecutiveId) {
        executiveEditSubmitBody = Object.assign(executiveAddBody, {
          executiveId: String(editExecutiveId),
          userId: userSelectedId,
        });
        dispatch(editExecutiveList(executiveEditSubmitBody));
        setEditExecutiveModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
        setSuccessEditExecutiveToastActive(
          (successEditExecutiveToastActive) => !successEditExecutiveToastActive,
        );
        setEditExecutiveErrorToastActive(
          (editExecutiveErrorToastActive) => !editExecutiveErrorToastActive,
        );
      } else {
        dispatch(addExecutive(executiveAddBody));
        setSuccessAddExecutiveToastActive(
          (successAddExecutiveToastActive) => !successAddExecutiveToastActive,
        );
        setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
        setAddExecutiveErrorToastActive(
          (addExecutiveErrorToastActive) => !addExecutiveErrorToastActive,
        );
      }
    }
  };

  // Delete Executive Body
  const deleteExecutiveBody = {
    executiveId: String(editExecutiveId),
    isActive: false,
  };

  // Delete Executive API ..
  const DeleteExecutiveSubmit = () => {
    dispatch(deleteExecutive(deleteExecutiveBody));
    setDeleteModalActive((deleteModalActive) => !deleteModalActive);
    setDeleteExecutiveToastActive(
      (successDeleteExecutiveToastActive) => !successDeleteExecutiveToastActive,
    );
    setdeleteExecutiveErrorToastActive(
      (deleteExecutiveErrorToastActive) => !deleteExecutiveErrorToastActive,
    );
  };

  useEffect(() => {
    dispatch(getExecutiveList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add Executive Modal
  const addExecutiveModalMarkup = (
    <Dialog open={executiveModalActive} onClose={handleExecutiveModalToggle} maxWidth="lg">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5">
              {t("execuList.addMediWisExecu", { ns: ["home"] })!}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleExecutiveModalToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("common.firstname", { ns: ["home"] })!}
              placeholder={t("common.enterFirstName", { ns: ["home"] })!}
              value={executiveFirstName}
              onChange={(e) => {
                setExecutiveFirstName(e.target.value);
              }}
              id="executiveFName"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutiveFirstName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("common.lastname", { ns: ["home"] })!}
              placeholder={t("common.enterlastname", { ns: ["home"] })!}
              value={executiveLastName}
              onChange={(e) => {
                setExecutiveLastName(e.target.value);
              }}
              id="executiveLName"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutiveLastName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("accountdetail.phoneno", { ns: ["home"] })!}
              placeholder={t("accountdetail.enterphnno", { ns: ["home"] })!}
              value={executivePhoneNo}
              onChange={(e) => {
                setExecutivePhoneNo(e.target.value);
              }}
              id="executivePhoneNumber"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutivePhoneNo}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("patientteamlistpage.email", { ns: ["home"] })!}
              placeholder={t("accountdetail.enteremail", { ns: ["home"] })!}
              value={executiveEmail}
              onChange={(e) => {
                setExecutiveEmail(e.target.value);
              }}
              id="executiveEmail"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutiveEmail}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={handleExecutiveModalToggle}>
              {t("accountdetail.cancel", { ns: ["home"] })!}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={addUpdateExecutive}>
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  // Edit Executive Modal
  const editExecutiveModalMarkup = (
    <Dialog open={editExecutiveModalActive} onClose={handleEditExecutiveModalToggle} maxWidth="lg">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5">
              {
                t("editMedicalWisdomExecutive.editMediWisdExecutive", {
                  ns: ["home"],
                })!
              }
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleEditExecutiveModalToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("common.firstname", { ns: ["home"] })!}
              placeholder={t("common.enterFirstName", { ns: ["home"] })!}
              value={executiveFirstName}
              onChange={(e) => {
                setExecutiveFirstName(e.target.value);
              }}
              id="executiveFName"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutiveFirstName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("common.lastname", { ns: ["home"] })!}
              placeholder={t("common.enterlastname", { ns: ["home"] })!}
              value={executiveLastName}
              onChange={(e) => {
                setExecutiveLastName(e.target.value);
              }}
              id="executiveLName"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutiveLastName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("patientteamlistpage.email", { ns: ["home"] })!}
              placeholder={t("accountdetail.enteremail", { ns: ["home"] })!}
              value={executiveEmail}
              onChange={(e) => {
                setExecutiveEmail(e.target.value);
              }}
              id="executiveEmail"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutiveEmail}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              label={t("accountdetail.phoneno", { ns: ["home"] })!}
              placeholder={t("accountdetail.enterphnno", { ns: ["home"] })!}
              value={executivePhoneNo}
              onChange={(e) => {
                setExecutivePhoneNo(e.target.value);
              }}
              id="executivePhoneNumber"
              fullWidth
              size="small"
            />
            <Typography sx={{ color: "#c62828" }}>{errorExecutivePhoneNo}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={handleCancelExecutiveModalToggle}>
              {t("common.del", { ns: ["home"] })!}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={addUpdateExecutive}>
              {t("demographicdetailpage.update", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  // Executive List Table
  const ExecutiveTable = (
    <Card>
      <CardContent>
        {executiveListValue && executiveListValue.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  // Delete Executive Modal
  const deleteExecutiveComponent = (
    <Dialog open={deleteModalActive} onClose={handleDeleteExecutiveModalChange} maxWidth="lg">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5">
              {t("editMedicalWisdomExecutive.delExecu", { ns: ["home"] })!}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleDeleteExecutiveModalChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography variant="body1">
              Are you sure, you want to delete this Executive : {executiveFirstName}{" "}
              {executiveLastName} ?
            </Typography>
            <Typography sx={{ color: "#c62828" }}>{successExecutiveModalDiv}</Typography>
            <Typography>{errorDeleteExecutiveModalDiv}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Grid item>
            <Button onClick={handleDeleteExecutiveModalChange}>
              {t("filePrepPage.no", { ns: ["home"] })!}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={DeleteExecutiveSubmit}>
              {t("filePrepPage.yes", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      {isLoading === true ||
      executiveListLoadingValue === true ||
      DeleteExecutiveLoadingvalue === true ||
      AddExecutiveLoadingvalue === true ||
      EditExecutiveLoadingvalue === true ? (
        <Stack>{loadingMarkup}</Stack>
      ) : (
        <Container maxWidth="xl">
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            pt={2}
          >
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <MWPageTitle
                  title={t("common.executivelist", { ns: ["home"] })}
                  enableCount={true}
                  count={listCount}
                  reload={true}
                  reloadAction={executiveListApi}
                />
              </Grid>
              <Grid item mt={2}>
                <Button
                  variant="contained"
                  onClick={handleExecutiveModalToggle}
                  sx={{ textTransform: "none" }}
                >
                  {t("execuList.exeList", { ns: ["home"] })}
                </Button>
              </Grid>
            </Grid>
            {ExecutiveTable}
            {DeleteExecutiveStatus! === 0 ? toastDeleteExecutiveErrorMarkup : ""}
            {DeleteExecutiveStatus! === 1 ? toastDeleteExecutiveSuccessMarkup : ""}
            {editExecutiveModalMarkup}
            {deleteExecutiveComponent}
            {addExecutiveModalMarkup}
            {errorAddExecutiveToastMarkup}
            {toastDeleteExecutiveErrorMarkup}
            {toastDeleteExecutiveSuccessMarkup}
            {AddExecutiveStatus! === 1 ? successAddExecutiveToastMarkup : ""}
            {AddExecutiveStatus! === 0 ? errorAddExecutiveToastMarkup : ""}
            {EditExecutiveStatus! === 1 ? successEditExecutiveToastMarkup : ""}
            {EditExecutiveStatus! === 0 ? errorEditExecutiveToastMarkup : ""}
          </Stack>
        </Container>
      )}
    </Box>
  );
}
