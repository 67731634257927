import React, { useState, useCallback, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientTeam,
  getAppointmentPatientTeamList,
} from "../../redux/Patient/AppointmentPatientTeam/ApiCall";
import { AppState } from "../../redux/store/Store";
import MWExceptionList from "../../component/MWExceptionList";
import MWResourceList from "../../component/MWResourceList";
import { t } from "i18next";

interface Props {
  appointmentPatientTeamLoader: any;
}

export default function AddPatientTeam({ appointmentPatientTeamLoader }: Props) {
  const dispatch = useDispatch();

  // const { appointmentPatientTeamLoader } = props;

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [patientteamemail, setPatientTeamEmail] = useState("");
  const handleOtherValueChange = useCallback(
    (event: any) => setPatientTeamEmail(event.target.value),
    [],
  );

  const [patientTeamOwnerName, setPatientTeamOwnerName] = useState("");
  const [patientTeamOwnerEmail, setPatientTeamOwnerEmail] = useState("");
  const closebanner = () => {
    setbannerbody("");
  };

  const [bannerbody, setbannerbody] = useState("");
  // const errorbannermarkup = bannerbody ? (
  //   <Alert severity="error" onClick={closebanner}>
  //     <Typography>{bannerbody}</Typography>
  //   </Alert>
  // ) : null;

  const errorbannermarkup = bannerbody ? (
    <Alert
      severity="error"
      onClose={() => {
        closebanner();
      }}
    >
      {bannerbody}
    </Alert>
  ) : null;

  // const successBannerMarkup = successBannerBody ? (
  //   <Alert
  //     severity="success"
  //     onClose={() => {
  //       closeBanner();
  //     }}
  //   >
  //     {successBannerBody}
  //   </Alert>
  // ) : null;

  // call patientTeam List from store
  const PatientTeamListLoadingvalue = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.loading,
  );
  const patientTeamListFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.patientTeam,
  );
  const patientOwner = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.ownerDetails,
  );
  const patientTeamEmailFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.ownerDetails.email,
  );
  const patientTeamNameFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.ownerDetails.name,
  );

  let selectedPatientTeamList: any[] = patientTeamListFromAPI;

  // call Confirm Appointment Store
  const addPatientTeamLoadingValue = useSelector(
    (state: AppState) => state.addPatientTeamValue.loading,
  );
  const addPatientTeamMessage = useSelector((state: AppState) => state.addPatientTeamValue.message);
  const addPatientTeamStatus = useSelector((state: AppState) => state.addPatientTeamValue.status);

  // Confirm appointment success toast
  const [sucessAddPatientTeamToastActive, setSucessAddPatientTeamToastActive] = useState(false);
  const toggleSuccessAddPatientTeamToggleActive = () => {
    setSucessAddPatientTeamToastActive(
      (sucessAddPatientTeamToastActive) => !sucessAddPatientTeamToastActive,
    );
    dispatch(getAppointmentPatientTeamList(caseid));
  };

  const toastSucessAddpatientTeamMarkup = sucessAddPatientTeamToastActive ? (
    <Snackbar
      open={sucessAddPatientTeamToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessAddPatientTeamToggleActive}
    >
      <Alert
        severity="success"
        onClose={toggleSuccessAddPatientTeamToggleActive}
        sx={{ width: "100%" }}
      >
        {addPatientTeamMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Confirm appointment error toast
  const [errorAddPatientTeamToastActive, setErrorAddPatientTeamToastActive] = useState(false);
  const toggleErrorAddPatientTeamToggleActive = () => {
    setErrorAddPatientTeamToastActive(
      (errorAddPatientTeamToastActive) => !errorAddPatientTeamToastActive,
    );
    dispatch(getAppointmentPatientTeamList(caseid));
  };
  const toastErrorAddpatientTeamMarkup = errorAddPatientTeamToastActive ? (
    <Snackbar
      open={errorAddPatientTeamToastActive}
      autoHideDuration={6000}
      onClose={toggleErrorAddPatientTeamToggleActive}
    >
      <Alert
        severity="error"
        onClose={toggleErrorAddPatientTeamToggleActive}
        sx={{ width: "100%" }}
      >
        {addPatientTeamMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Get Patient Team List API
  const getSelectedPatientTeam = () => {
    dispatch(getAppointmentPatientTeamList(caseid));
  };
  // Add PatientTeam API
  const body = {
    caseId: caseid,
    email: patientteamemail,
  };

  // Add patient Team API
  const addPatientTeamInCase = () => {
    if (patientteamemail === "") {
      setbannerbody(`${t("common.emailEmpty", { ns: ["home"] })!}`);
    } else {
      dispatch(addPatientTeam(body));
      setSucessAddPatientTeamToastActive(
        (sucessAddPatientTeamToastActive) => !sucessAddPatientTeamToastActive,
      );
      setErrorAddPatientTeamToastActive(
        (errorAddPatientTeamToastActive) => !errorAddPatientTeamToastActive,
      );
    }
  };

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    getSelectedPatientTeam();
    Object.keys(patientOwner).length > 0
      ? setPatientTeamOwnerName(patientTeamNameFromAPI)
      : setPatientTeamOwnerName("");
    Object.keys(patientOwner).length > 0
      ? setPatientTeamOwnerEmail(patientTeamEmailFromAPI)
      : setPatientTeamOwnerEmail("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, patientTeamNameFromAPI]);
  const symptomsAddInfo = (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <Card>
        <CardHeader title={t("addPatTeam.patTeamDetail", { ns: ["home"] })} />
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("addPatTeam.nm", { ns: ["home"] })}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerName}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("common.emailorphno", { ns: ["home"] })}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerEmail}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Contact Number:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {patientTeamOwnerPhone}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box>
            <CardHeader
              title={
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("addPatTeam.selPatTeamList", { ns: ["home"] })}
                </Typography>
              }
            />
            <Box>
              {selectedPatientTeamList !== undefined ? (
                selectedPatientTeamList.length > 0 ? (
                  selectedPatientTeamList.map((element: any) => (
                    <MWResourceList
                      icon={false}
                      title={element?.email}
                      showSubtitle={false}
                      showNavigation={false}
                    />
                  ))
                ) : (
                  <MWExceptionList />
                )
              ) : (
                <MWExceptionList />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <Typography variant="subtitle2" fontWeight="bold">
                  {t("addPatTeam.patCaseContNum", { ns: ["home"] })}:
                </Typography>
                <Typography variant="subtitle2">
                  {t("addPatTeam.patTeamEm", { ns: ["home"] })}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} sm={10} md={10} lg={8}>
                    <TextField
                      fullWidth
                      multiline
                      rows={1}
                      value={patientteamemail}
                      type="email"
                      label=""
                      placeholder="e.g. abc@xyz.com"
                      id="patientteamEmail"
                      onChange={handleOtherValueChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={4} style={{ marginTop: "5px" }}>
                    <Button variant="outlined" onClick={addPatientTeamInCase}>
                      {t("common.add", { ns: ["home"] })}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box mt={2}>{errorbannermarkup}</Box>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
  return (
    <Typography>
      {PatientTeamListLoadingvalue === true || addPatientTeamLoadingValue === true
        ? appointmentPatientTeamLoader(true)
        : appointmentPatientTeamLoader(false)}
      {symptomsAddInfo}
      {addPatientTeamStatus! === 1 ? toastSucessAddpatientTeamMarkup : ""}
      {addPatientTeamStatus! === 0 ? toastErrorAddpatientTeamMarkup : ""}
    </Typography>
  );
}
