import { FindUserResponse } from "./Model";
export const FIND_USER_LOADING = "FIND_USER_LOADING";
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
export const FIND_USER_FAIL = "FIND_USER_FAIL";
export const FIND_USER_DATA = "FIND_USER_DATA";
export const FIND_USER_UPDATE_API_MSG = "FIND_USER_UPDATE_API_MSG";
export const FIND_USER_UPDATE_API_RES = "FIND_USER_UPDATE_API_RES";

export interface FindUserState {
  findUserRes: FindUserResponse;
  loading: boolean;
  message: string;
  status: number;
}
export interface FindUserLoading {
  type: typeof FIND_USER_LOADING;
  loading: boolean;
}
export interface FindUserSuccess {
  type: typeof FIND_USER_SUCCESS;
  payload: FindUserResponse;
}
export interface FindUserFail {
  type: typeof FIND_USER_FAIL;
  payload: FindUserResponse;
  message: string;
  status: number;
}
export interface FindUserUpdateAPIMsg {
  type: typeof FIND_USER_UPDATE_API_MSG;
  payload: FindUserResponse;
  message: string;
  status: number;
}
export interface FindUserUpdateAPIRes {
  type: typeof FIND_USER_UPDATE_API_RES;
  payload: FindUserResponse;
  message: string;
  status: number;
}
interface FindUserAction {
  type: typeof FIND_USER_DATA;
  payload: FindUserResponse;
}

export type FindUserActionTypes =
  | FindUserAction
  | FindUserLoading
  | FindUserSuccess
  | FindUserFail
  | FindUserUpdateAPIMsg
  | FindUserUpdateAPIRes;
