import { UpdateDoctorDetailsRes } from "./Model";
export const UPDATE_DOCTOR_DETAILS_LOADING = "UPDATE_DOCTOR_DETAILS_LOADING";
export const UPDATE_DOCTOR_DETAILS_SUCCESS = "UPDATE_DOCTOR_DETAILS_SUCCESS";
export const UPDATE_DOCTOR_DETAILS_FAIL = "UPDATE_DOCTOR_DETAILS_FAIL";
export const UPDATE_DOCTOR_DETAILS_DATA = "UPDATE_DOCTOR_DETAILS_DATA";
export const UPDATE_DOCTOR_DETAILS_UPDATE_API_MSG = "UPDATE_DOCTOR_DETAILS_UPDATE_API_MSG";
export const UPDATE_DOCTOR_DETAILS_UPDATE_API_RES = "UPDATE_DOCTOR_DETAILS_UPDATE_API_RES";

export interface UpdateDoctorDetailsState {
  updateNoteResRes: UpdateDoctorDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorDetailsLoading {
  type: typeof UPDATE_DOCTOR_DETAILS_LOADING;
  loading: boolean;
}
export interface UpdateDoctorDetailsSuccess {
  type: typeof UPDATE_DOCTOR_DETAILS_SUCCESS;
  payload: UpdateDoctorDetailsRes;
}
export interface UpdateDoctorDetailsFail {
  type: typeof UPDATE_DOCTOR_DETAILS_FAIL;
  payload: UpdateDoctorDetailsRes;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorDetailsUpdateAPIMsg {
  type: typeof UPDATE_DOCTOR_DETAILS_UPDATE_API_MSG;
  payload: UpdateDoctorDetailsRes;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorDetailsUpdateAPIRes {
  type: typeof UPDATE_DOCTOR_DETAILS_UPDATE_API_RES;
  payload: UpdateDoctorDetailsRes;
  errorMsg: string;
  status: number;
}

export type UpdateDoctorDetailsActionTypes =
  | UpdateDoctorDetailsLoading
  | UpdateDoctorDetailsSuccess
  | UpdateDoctorDetailsFail
  | UpdateDoctorDetailsUpdateAPIMsg
  | UpdateDoctorDetailsUpdateAPIRes;
