import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { GetAdminDetailsActionTypes } from "./ActionTypes";
import { GetAdminDetailsRes, LoginBody } from "./Model";
import {
  getAdminDetailsAPIResClearAction,
  getAdminDetailsErrorAction,
  getAdminDetailsLoadingAction,
  getAdminDetailsSuccessAction,
  getAdminDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
// import SecureLS from "secure-ls";

let apiRes = {} as GetAdminDetailsRes;
export const adminLoginDetailsApi = (payload: LoginBody) => {
  let APIHeader = headers;
  APIHeader.Authorization = `Bearer ${payload.access}`;
  return function (dispatch: Dispatch<GetAdminDetailsActionTypes>) {
    dispatch(getAdminDetailsLoadingAction(true));
    axios
      .get(baseUrl.baseUrl + `/adminapp/api/adminActions/adminLoginDetails`, {
        headers: APIHeader,
      } as any)
      .then((res) => {
        apiRes = res.data;
        dispatch(getAdminDetailsLoadingAction(false));
        dispatch(
          getAdminDetailsSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "Login successful",
          ),
        );
        localStorage.setItem("loginDetails", JSON.stringify(res.data!)!);
      })
      .catch((error) => {
        dispatch(getAdminDetailsLoadingAction(false));
        dispatch(
          getAdminDetailsErrorAction(
            {} as GetAdminDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
        localStorage.clear();
      });
  };
};

export const getAdminDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetAdminDetailsActionTypes>) {
    dispatch(getAdminDetailsAPIMsgAction(apiRes as GetAdminDetailsRes, "", 0));
  };
};

export const clearGetAdminDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetAdminDetailsActionTypes>) {
    dispatch(getAdminDetailsAPIResClearAction({} as GetAdminDetailsRes, "", 0));
  };
};
