import { AddAnswer, AdminQuestionList, QuestionVerifyResponse } from "./Model";

// Case Status
export const ANSWER_LOADING = "ANSWER_LOADING";
export const ANSWER_SUCCESS = "ANSWER_SUCCESS";
export const ANSWER_FAIL = "ANSWER_FAIL";
export const ANSWER_STATUS = "ANSWER_STATUS";

export interface AnswerStateType {
  loading: boolean;
  payload: AddAnswer;
}
export interface AnswerLoading {
  type: typeof ANSWER_LOADING;
}
export interface AnswerSuccess {
  type: typeof ANSWER_SUCCESS;
  payload: AddAnswer;
}
export interface AnswerFail {
  type: typeof ANSWER_FAIL;
  payload: AddAnswer;
}

interface AnswerType {
  type: typeof ANSWER_STATUS;
  payload: AddAnswer;
}

export type AnswerTypes = AnswerType | AnswerLoading | AnswerSuccess | AnswerFail;

export const GET_QUESTION_LIST = "GET_QUESTION_LIST";
export const QUESTION_LIST_LOADING = "QUESTION_LIST_LOADING";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAIL = "QUESTION_LIST_FAIL";

export interface GetQuestionListStateType {
  questionListValue: AdminQuestionList[];
  loading: boolean;
}

interface AdminGetQuestionListActionType {
  type: typeof GET_QUESTION_LIST;
  payload: AdminQuestionList[];
}

export interface QuestionListLoading {
  type: typeof QUESTION_LIST_LOADING;
}
export interface QuestionListSuccess {
  type: typeof QUESTION_LIST_SUCCESS;
  payload: AdminQuestionList[];
}
export interface QuestionListFail {
  type: typeof QUESTION_LIST_FAIL;
}
export type AdminGetQuestionListActionTypes =
  | AdminGetQuestionListActionType
  | QuestionListLoading
  | QuestionListSuccess
  | QuestionListFail;

export const QUESTION_VERIFY_LIST = "QUESTION_VERIFY_LIST";
export const QUESTION_VERIFY_LOADING = "QUESTION_VERIFY_LOADING";
export const QUESTION_VERIFY_SUCCESS = "QUESTION_VERIFY_SUCCESS";
export const QUESTION_VERIFY_FAIL = "QUESTION_VERIFY_FAIL";

export interface QuestionVerifyStateType {
  payload: QuestionVerifyResponse;
  loading: boolean;
}

interface QuestionVerifyActionType {
  type: typeof QUESTION_VERIFY_LIST;
  payload: QuestionVerifyResponse;
}

export interface QuestionVerifyLoading {
  type: typeof QUESTION_VERIFY_LOADING;
}
export interface QuestionVerifySuccess {
  type: typeof QUESTION_VERIFY_SUCCESS;
  payload: QuestionVerifyResponse;
}
export interface QuestionVerifyFail {
  type: typeof QUESTION_VERIFY_FAIL;
  payload: QuestionVerifyResponse;
}
export type verifyActionTypes =
  | QuestionVerifyActionType
  | QuestionVerifyLoading
  | QuestionVerifySuccess
  | QuestionVerifyFail;
