import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoginScreen from "../../image/featureimage-admin.png";
import MWSelectLang from "../../component/MWSelectLang";

interface OnboardingLayoutProps {
  children?: ReactNode;
}
const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
  const { t } = useTranslation(["home"]);
  return (
    <Grid container item direction="row" justifyContent="space-between" sx={{ height: "100vh" }}>
      <Grid container item direction="column" alignItems="flex-end" xs={12}>
        <MWSelectLang />
      </Grid>
      <Grid xs={12} container item>
        <Grid xs={12} md={6} container item alignItems="center" justifyContent="center">
          <Grid container item alignItems="center" justifyContent="center">
            <Grid container item xs={11} sm={7}>
              {children}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          md={6}
          container
          item
          alignItems="center"
          justifyContent="center"
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box>
            <img
              src={LoginScreen}
              alt=""
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item direction="column" justifyContent="center" xs={12}>
        <Grid container item direction="row" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="caption">© {t("common.copyright", { ns: ["home"] })}</Typography>
          <Typography variant="caption">
            <Link
              underline="none"
              href="https://web.cloud-doctor.de/en/termsofuse/"
              target="_blank"
              rel="noreferrer"
            >
              {t("home.termofuse", { ns: ["home"] })}
            </Link>
            &nbsp;&nbsp;
            <Link
              underline="none"
              href="https://web.cloud-doctor.de/en/privacystatement/"
              target="_blank"
              rel="noreferrer"
            >
              {t("home.privacypolicy", { ns: ["home"] })}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

OnboardingLayout.propTypes = {
  children: PropTypes.node,
};

export default OnboardingLayout;
