import {
  EDIT_CASE_FAIL,
  EDIT_CASE_LOADING,
  EDIT_CASE_SUCCESS,
  EDIT_CASE_API_MSG,
  EDIT_CASE_API_RES,
  EditCaseActionTypes,
} from "./ActionTypes";
import { EditCaseResponse } from "./Model";

export const editCaseLoadingAction = (loading: boolean): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_LOADING,
    loading: loading,
  };
};

export const editCaseSuccessAction = (
  editCaseResponse: EditCaseResponse,
  successMsg: string,
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_SUCCESS,
    payload: editCaseResponse,
    successMsg: successMsg,
  };
};

export const editCaseErrorAction = (
  editCaseResponse: EditCaseResponse,
  errMsg: string,
  status: number,
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_FAIL,
    payload: editCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editCaseAPIMsgAction = (
  editCaseResponse: EditCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_API_MSG,
    payload: editCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editCaseAPIResClearAction = (
  editCaseResponse: EditCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_API_RES,
    payload: editCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
