import {
  PATIENT_IMPORT_CONFIG_LIST_FAIL,
  PATIENT_IMPORT_CONFIG_LIST_LOADING,
  PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
  PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
  PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
  PatientImportConfigListActionTypes,
} from "./ActionTypes";
import { PatientImportConfigListValueRes } from "./Model";

export const patientImportConfigListLoadingAction = (
  loading: boolean,
): PatientImportConfigListActionTypes => {
  return {
    type: PATIENT_IMPORT_CONFIG_LIST_LOADING,
    loading: loading,
  };
};

export const patientImportConfigListSuccessAction = (
  patientImportConfigListResponse: PatientImportConfigListValueRes,
  successMsg: string,
): PatientImportConfigListActionTypes => {
  return {
    type: PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
    payload: patientImportConfigListResponse,
    successMsg: successMsg,
  };
};

export const patientImportConfigListErrorAction = (
  patientImportConfigListResponse: PatientImportConfigListValueRes,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: PATIENT_IMPORT_CONFIG_LIST_FAIL,
    payload: patientImportConfigListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const patientImportConfigListUpdateAPIMsgAction = (
  patientImportConfigListResponse: PatientImportConfigListValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
    payload: patientImportConfigListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const patientImportConfigListAPIResClearAction = (
  patientImportConfigListResponse: PatientImportConfigListValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
    payload: patientImportConfigListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
