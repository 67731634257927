import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  ImportPhoneActionTypes,
  IMPORT_FROM_PHONE_FAIL,
  IMPORT_FROM_PHONE_LOADING,
  IMPORT_FROM_PHONE_SUCCESS,
} from "./ActionTypes";
import { headers } from "../../component/Utility";

export const importFromPhone = () => {
  return function (dispatch: Dispatch<ImportPhoneActionTypes>) {
    dispatch({
      type: IMPORT_FROM_PHONE_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(baseUrl.baseUrl + `/ehrapp/api/incomeCall/import_recent_calls`, { headers: headers })
      .then((res) => {
        dispatch({
          type: IMPORT_FROM_PHONE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: IMPORT_FROM_PHONE_FAIL,
        });
      });
  };
};
