import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from "./English/translation.json";
import translationGerman from "./German/translation.json";
import translationFrench from "./French/translation.json";
import translationItalia from "./Italia/translation.json";

//Different namespaces
const resources = {
  en: {
    home: translationEnglish,
  },
  de: {
    home: translationGerman,
  },
  fr: {
    home: translationFrench,
  },
  it: {
    home: translationItalia,
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lng") || "de", //set default language
});

export default i18next;
