import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { t } from "i18next";
import { useHistory, useParams } from "react-router-dom";
import { AddCaseBody } from "../../../redux/ConsultationOption/AddCase/Model";
import { SecondOpinionReasons } from "../../../redux/ConsultationOption/OpinionType/Model";
import MWSnackbar from "../../../component/MWSnackbar";
import MWTextField from "../../../component/MWTextField";
import { patientCaseDetails } from "../../../redux/ConsultationOption/ApiCall";
import {
  addPatientCase,
  clearAddCaseAPIRes,
} from "../../../redux/ConsultationOption/AddCase/ApiCall";
import { CaseDetailsBody } from "../../../redux/ConsultationOption/Model";
import { editPatientCase } from "../../../redux/ConsultationOption/EditCase/ApiCall";
import { patientOpinionType } from "../../../redux/ConsultationOption/OpinionType/ApiCall";
import { patientSecondOpinionType } from "../../../redux/ConsultationOption/SecondOpinionType/ApiCall";

export default function OpinionType() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { patientid } = useParams() as {
    patientid: string;
  };

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [isEdit, setIsEdit] = useState(caseid ? true : false);
  const [isEnableSubmit, setEnableSubmit] = useState(true);

  const [value, setValue] = useState("1");
  const [requestList, setRequestList] = useState<SecondOpinionReasons[]>([]);
  const [questionValue, setQuestion] = useState("");

  const [alertMsg, setAlertMsg] = useState("");
  const [alertMsgType, setAlertMsgType] = useState<AlertColor>("success");
  const closeAlert = (passedVal: boolean) => {
    if (passedVal) {
      setAlertMsg("");
      dispatch(clearAddCaseAPIRes());
    }
  };

  const handleRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let checkedObj = SecondOpinionListValue?.find(
        (i: any) => i.value.toString() === event.target.value,
      ) as SecondOpinionReasons;
      setRequestList((oldArray) => (oldArray ? [...oldArray!, checkedObj] : [checkedObj]));
    } else {
      setRequestList(requestList.filter((i) => i.value.toString() !== event.target.value));
    }
  };

  const isRequestSelected = (request: any) => {
    return requestList?.find((i) => i.value === request.value) ? true : false;
  };

  const OpinionTypeListValue = useSelector(
    (state: AppState) => state.opinionTypeValue?.opinionTypeResponse?.opinionTypeList,
  );

  const SecondOpinionListValue = useSelector(
    (state: AppState) =>
      state.secondOpinionValue?.secondSecondOpinionTypeResponse?.secondOpinionList,
  );

  const addPatientCaseIdvalue = useSelector((state: AppState) => state.addCase);

  const PatientCaseDetailsValue = useSelector(
    (state: AppState) => state.getPatientCaseDetails?.caseDetailsResponse?.patientCaseDetail,
  );

  const getBody = {
    caseId: caseid,
  } as CaseDetailsBody;

  const addBody = {
    patientId: patientid,
    opinionTypeId: value,
    whyWant2ndOpinion: questionValue,
    secondOpinionReasons: requestList?.map((item) => item.value),
  } as AddCaseBody;

  const createCase = async () => {
    if (caseid) {
      await dispatch(editPatientCase(Object.assign(addBody, { caseId: caseid })));
      await setIsEdit((isEdit) => !isEdit);
      await setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
    } else {
      if (questionValue) {
        await dispatch(addPatientCase(addBody));
      } else {
        setAlertMsg(`${t("consultOpPage.alertMsg", { ns: ["home"] })}`);
        setAlertMsgType("error");
      }
    }
  };

  useEffect(() => {
    dispatch(patientOpinionType());
    dispatch(patientSecondOpinionType());
    dispatch(patientCaseDetails(getBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addPatientCaseIdvalue?.addCaseResponse?.patientCaseId !== undefined) {
      history.push(
        `/casedetails/${patientid}/${addPatientCaseIdvalue?.addCaseResponse?.patientCaseId}/?tab=consultationType`,
      );
      window.location.reload();
    }
    if (addPatientCaseIdvalue?.successMsg !== "") {
      setAlertMsg(addPatientCaseIdvalue?.successMsg);
      setAlertMsgType("success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientCaseIdvalue]);

  useEffect(() => {
    PatientCaseDetailsValue === undefined
      ? setValue("1")
      : setValue(String(PatientCaseDetailsValue?.opinionType!));
    PatientCaseDetailsValue?.secondOpinionReasons === undefined
      ? setRequestList([])
      : setRequestList(PatientCaseDetailsValue?.secondOpinionReasons);
    PatientCaseDetailsValue === undefined
      ? setQuestion("")
      : setQuestion(PatientCaseDetailsValue?.whyWant2ndOpinion!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientCaseDetailsValue]);

  const firstComponent = (
    <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item>
        {OpinionTypeListValue !== undefined && OpinionTypeListValue.length > 0
          ? OpinionTypeListValue?.map((opinion: any) => (
              <FormControl key={opinion.value} disabled={isEdit}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setValue((event.target as HTMLInputElement).value)
                  }
                >
                  <FormControlLabel
                    value={opinion.value}
                    control={<Radio />}
                    label={opinion.label}
                  />
                </RadioGroup>
              </FormControl>
            ))
          : null}
      </Grid>
      {value === "1" ? (
        <Grid item>
          <Typography variant="h6" fontWeight="bold">
            {t("consultOpPage.2ndOpReq", { ns: ["home"] })}
          </Typography>
          <Box py={2}>
            {SecondOpinionListValue !== undefined && SecondOpinionListValue.length > 0
              ? SecondOpinionListValue?.map((request: any) => (
                  <FormGroup key={request.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={request.value}
                          onChange={(event) => handleRequest(event)}
                          checked={isRequestSelected(request)}
                        />
                      }
                      label={request.label}
                      disabled={isEdit}
                    />
                  </FormGroup>
                ))
              : null}
          </Box>
        </Grid>
      ) : null}
      <Grid item>
        <Box>
          {value === "1" ? (
            <Typography variant="h6" fontWeight="bold">
              {OpinionTypeListValue !== undefined && OpinionTypeListValue.length > 0
                ? OpinionTypeListValue[0]?.question
                : ""}
            </Typography>
          ) : (
            <Typography variant="h6" fontWeight="bold">
              {OpinionTypeListValue !== undefined && OpinionTypeListValue.length > 0
                ? OpinionTypeListValue[1]?.question
                : ""}
            </Typography>
          )}
          <Box mt={3}>
            <MWTextField
              fullWidth
              label={t("consultOpPage.cause", { ns: ["home"] })}
              placeholder={t("consultOpPage.exCauseAnx", { ns: ["home"] })!}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setQuestion(event.target.value);
              }}
              value={questionValue}
              disabled={isEdit}
            />
          </Box>
          {alertMsg !== "" ? (
            <MWSnackbar type={alertMsgType} msg={alertMsg} alertClose={closeAlert} />
          ) : null}
        </Box>
      </Grid>
      <Grid item>
        {caseid ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit
                  ? `${t("userprofilepage.edit", { ns: ["home"] })}`
                  : `${t("accountdetail.cancel", { ns: ["home"] })}`}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                {t("demographicdetailpage.update", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button size="large" variant="contained" onClick={createCase}>
              {t("common.add", { ns: ["home"] })}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return <Container maxWidth="xl">{firstComponent}</Container>;
}
