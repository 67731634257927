import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddDoctorActionTypes } from "./ActionTypes";
import { AddDoctorBody, AddDoctorResponse } from "./Model";
import {
  addDoctorAPIResClearAction,
  addDoctorErrorAction,
  addDoctorLoadingAction,
  addDoctorSuccessAction,
  addDoctorAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as AddDoctorResponse;
export const addDrOnBoarding = (payload: AddDoctorBody) => {
  return function (dispatch: Dispatch<AddDoctorActionTypes>) {
    dispatch(addDoctorLoadingAction(true));
    axios
      .post(Url.baseUrl + `/adminapp/api/adminActions/add_doctor_onboarding_request`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addDoctorLoadingAction(false));
        dispatch(
          addDoctorSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. award has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addDoctorLoadingAction(false));
        dispatch(
          addDoctorErrorAction(
            {} as AddDoctorResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddDoctorAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDoctorActionTypes>) {
    dispatch(addDoctorAPIMsgAction(apiRes as AddDoctorResponse, "", "", 0));
  };
};

export const clearAddDoctorAPIRes = () => {
  return function (dispatch: Dispatch<AddDoctorActionTypes>) {
    dispatch(addDoctorAPIResClearAction({} as AddDoctorResponse, "", "", 0));
  };
};
