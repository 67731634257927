import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  listvalue?: any;
  selectedItem?: any;
  placeHolder?: string;
  visibility?: boolean;
  dropdownValue?: any;
}

const MWMultipleAutocomplete: React.FC<Props> = ({
  listvalue,
  selectedItem,
  placeHolder,
  visibility,
  dropdownValue,
}) => {
  const [itemValue, setItemValue] = useState([] as any[]);
  const [itemPlaceHolder, setItemPlaceHolder] = React.useState("");
  const [itemVisibility, setItemVisibility] = React.useState(false);
  const [selectedItemValue, setItemSelected] = useState<[]>([]);

  const filterItem = (dropdownPatientName: any, e: any) => {
    setItemSelected(dropdownPatientName);
  };

  useEffect(() => {
    setItemPlaceHolder(placeHolder!);
    setItemVisibility(visibility!);
    setItemValue(listvalue!);
    setItemSelected(selectedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listvalue, placeHolder, visibility, selectedItem]);

  useEffect(() => {
    dropdownValue(selectedItemValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemValue]);

  return (
    <div>
      <Autocomplete
        value={selectedItemValue}
        multiple
        id="tags-outlined"
        options={itemValue}
        onChange={(event: any, newValue: any) => {
          filterItem(newValue, event);
        }}
        getOptionLabel={(option) => option.label}
        // defaultValue={[itemValue[0]]}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} label={itemPlaceHolder} />}
        disableClearable
        disabled={itemVisibility}
        size="small"
      />
    </div>
  );
};

export default MWMultipleAutocomplete;
