import { GetWeekDayListRes } from "./Model";
export const GET_WEEKDAY_LIST_LOADING = "GET_WEEKDAY_LIST_LOADING";
export const GET_WEEKDAY_LIST_SUCCESS = "GET_WEEKDAY_LIST_SUCCESS";
export const GET_WEEKDAY_LIST_FAIL = "GET_WEEKDAY_LIST_FAIL";
export const GET_WEEKDAY_LIST_UPDATE_API_MSG = "GET_WEEKDAY_LIST_UPDATE_API_MSG";
export const GET_WEEKDAY_LIST_UPDATE_API_RES = "GET_WEEKDAY_LIST_UPDATE_API_RES";

export interface GetWeekDayListState {
  getWeekDayListRes: GetWeekDayListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetWeekDayListLoading {
  type: typeof GET_WEEKDAY_LIST_LOADING;
  loading: boolean;
}
export interface GetWeekDayListSuccess {
  type: typeof GET_WEEKDAY_LIST_SUCCESS;
  payload: GetWeekDayListRes;
  successMsg: string;
}
export interface GetWeekDayListFail {
  type: typeof GET_WEEKDAY_LIST_FAIL;
  payload: GetWeekDayListRes;
  errorMsg: string;
  status: number;
}
export interface GetWeekDayListUpdateAPIMsg {
  type: typeof GET_WEEKDAY_LIST_UPDATE_API_MSG;
  payload: GetWeekDayListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetWeekDayListUpdateAPIRes {
  type: typeof GET_WEEKDAY_LIST_UPDATE_API_RES;
  payload: GetWeekDayListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetWeekDayListActionTypes =
  | GetWeekDayListLoading
  | GetWeekDayListSuccess
  | GetWeekDayListFail
  | GetWeekDayListUpdateAPIMsg
  | GetWeekDayListUpdateAPIRes;
