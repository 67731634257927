import * as React from "react";
import { Box, Button, Grid } from "@mui/material";
import { Link, useHistory, useParams } from "react-router-dom";
import MWResourceList from "../../../component/MWResourceList";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWPageTitle from "../../../component/MWPageTitle";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";
import AdminLoader from "../../AdminLoader";
import PageLayout from "../../Layout/PageLayout";
import MWExceptionList from "../../../component/MWExceptionList";
import { useEffect } from "react";
import { t } from "i18next";

export default function CaseListExpanded() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  // Reload Patient Case List API
  const reloadPatientCaseList = () => {
    dispatch(getPatientCaseList(patientid));
  };
  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}?&tab=consultationType`);
  };

  useEffect(() => {
    dispatch(getPatientCaseList(patientid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const caseHistory = useSelector((state: AppState) => state.caseValue);

  const pageBody = (
    <Box py={2}>
      {caseHistory?.caseValue !== undefined ? (
        caseHistory?.caseValue.length > 0 ? (
          caseHistory?.caseValue.map((element: any) => (
            <Box key={element.id} style={{ textDecoration: "none", textTransform: "none" }}>
              <Link
                to={`/casedetails/${element.patientId}/${element.caseId}`}
                style={{ textDecoration: "none" }}
              >
                <MWResourceList
                  icon={true}
                  title={element?.whyWant2ndOpinion}
                  showSubtitle={true}
                  subtitle={element?.secondOpinionReasons}
                  showReason={true}
                  reason={element?.consultationType}
                  patient={element?.patientName}
                  showPatient={true}
                  showNavigation={true}
                />
              </Link>
            </Box>
          ))
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );
  return (
    <Box>
      {caseHistory?.loading ? <AdminLoader /> : null}
      <PageLayout>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={t("patientcaselistpage.title", { ns: ["home"] })!}
              enableCount={true}
              count={caseHistory?.caseValue !== undefined ? caseHistory?.caseValue.length : "0"}
              reload={true}
              reloadAction={reloadPatientCaseList}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => handleAddEncounterToggle()}
              sx={{ textTransform: "none" }}
            >
              {t("common.addCase", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
        {pageBody}
      </PageLayout>
    </Box>
  );
}
