import { PatienTeamtList } from "./Model";

export const PATIENT_TEAM_LIST = "PATIENT_TEAM_LIST";
export const PATIENT_TEAM_LIST_LOADING = "PATIENT_TEAM_LIST_LOADING";
export const PATIENT_TEAM_LIST_SUCCESS = "PATIENT_TEAM_LIST_SUCCESS";
export const PATIENT_TEAM_LIST_FAIL = "PATIENT_TEAM_LIST_FAIL";

// Get Patient Team List
export interface GetPatientTeamListStateType {
  patientTeamListValue: PatienTeamtList[];
  loading: boolean;
  message: string;
  status: Number;
}
interface PatientTeamListActionType {
  type: typeof PATIENT_TEAM_LIST;
  payload: PatienTeamtList[];
  message: string;
  status: Number;
}
export interface PatientTeamListLoading {
  type: typeof PATIENT_TEAM_LIST_LOADING;
}
export interface PatientTeamListSuccess {
  type: typeof PATIENT_TEAM_LIST_SUCCESS;
  payload: PatienTeamtList[];
  status: Number;
  message: string;
}
export interface PatientTeamListFail {
  type: typeof PATIENT_TEAM_LIST_FAIL;
  message: string;
  status: Number;
}
export type PatientTeamListTypes =
  | PatientTeamListActionType
  | PatientTeamListLoading
  | PatientTeamListSuccess
  | PatientTeamListFail;

export const ADD_PATIENT_IN_CORPORATE_PACKAGE = "ADD_PATIENT_IN_CORPORATE_PACKAGE";
export const ADD_PATIENT_IN_CORPORATE_PACKAGE_LOADING = "PATIENT_TEAM_LIST_LOADING";
export const ADD_PATIENT_IN_CORPORATE_PACKAGE_SUCCESS = "ADD_PATIENT_IN_CORPORATE_PACKAGE_SUCCESS";
export const ADD_PATIENT_IN_CORPORATE_PACKAGE_FAIL = "ADD_PATIENT_IN_CORPORATE_PACKAGE_FAIL";

// Add Patient in Corporate Package State
export interface AddPatientInCorporatePacckageStateType {
  loading: boolean;
  message: string;
  status: Number;
}
interface AddPatientInCorporatePacckageActionType {
  type: typeof ADD_PATIENT_IN_CORPORATE_PACKAGE;
  message: string;
  status: Number;
}
export interface AddPatientInCorporatePacckageLoading {
  type: typeof ADD_PATIENT_IN_CORPORATE_PACKAGE_LOADING;
}
export interface AddPatientInCorporatePacckageSuccess {
  type: typeof ADD_PATIENT_IN_CORPORATE_PACKAGE_SUCCESS;
  status: Number;
  message: string;
}
export interface AddPatientInCorporatePacckageFail {
  type: typeof ADD_PATIENT_IN_CORPORATE_PACKAGE_FAIL;
  message: string;
  status: Number;
}
export type AddPatientInCorporatePackageTypes =
  | AddPatientInCorporatePacckageActionType
  | AddPatientInCorporatePacckageLoading
  | AddPatientInCorporatePacckageSuccess
  | AddPatientInCorporatePacckageFail;
