import {
  ExecutiveListDataListStateType,
  AdminGetExecutiveListActionTypes,
  EXECUTIVE_LOADING,
  EXECUTIVE_SUCCESS,
  EXECUTIVE_FAIL,
  DELETE_EXECUTIVE_SUCCESS,
  DELETE_EXECUTIVE_LOADING,
  DELETE_EXECUTIVE_FAIL,
  DeleteExecutiveTypes,
  DeleteExecutiveStateType,
  AddExecutiveStateType,
  AddExecutiveTypes,
  ADD_EXECUTIVE_SUCCESS,
  ADD_EXECUTIVE_LOADING,
  ADD_EXECUTIVE_FAIL,
  EditExecutiveStateType,
  EditExecutiveTypes,
  EDIT_EXECUTIVE_SUCCESS,
  EDIT_EXECUTIVE_LOADING,
  EDIT_EXECUTIVE_FAIL,
} from "./ActionTypes";

const initialStateGetExecutivelist: ExecutiveListDataListStateType = {
  loading: false,
  executiveListValue: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getExecutiveListReducer = (
  state = initialStateGetExecutivelist,
  action: AdminGetExecutiveListActionTypes,
): ExecutiveListDataListStateType => {
  switch (action.type) {
    case EXECUTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        executiveListValue: action.payload,
        status: action.status,
        message: action.message,
      };
    case EXECUTIVE_LOADING:
      return {
        ...state,
        loading: true,
        executiveListValue: [],
      };
    case EXECUTIVE_FAIL:
      return {
        ...state,
        executiveListValue: [],
        loading: false,
        status: action.status,
        message: action.message,
      };
    default:
      return state;
  }
};

//For delete executive
const initialStateDeleteExecutive: DeleteExecutiveStateType = {
  loading: false,
  response: Object.assign({}),
};

export const deleteExecutiveReducer = (
  state = initialStateDeleteExecutive,
  action: DeleteExecutiveTypes,
): DeleteExecutiveStateType => {
  switch (action.type) {
    case DELETE_EXECUTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DELETE_EXECUTIVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EXECUTIVE_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};

//For Add executive
const initialStateAddExecutive: AddExecutiveStateType = {
  loading: false,
  response: Object.assign({}),
};

export const addExecutiveReducer = (
  state = initialStateAddExecutive,
  action: AddExecutiveTypes,
): AddExecutiveStateType => {
  switch (action.type) {
    case ADD_EXECUTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_EXECUTIVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_EXECUTIVE_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};

//For Edit executive
const initialStateEditExecutive: EditExecutiveStateType = {
  loading: false,
  response: Object.assign({}),
};

export const editExecutiveReducer = (
  state = initialStateEditExecutive,
  action: EditExecutiveTypes,
): EditExecutiveStateType => {
  switch (action.type) {
    case EDIT_EXECUTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case EDIT_EXECUTIVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EDIT_EXECUTIVE_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};
