import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { getInactiveCorporatePatinetList } from "../../../redux/effects/ApiCall";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import MWExceptionList from "../../../component/MWExceptionList";
import AdminLoader from "../../AdminLoader";
import { t } from "i18next";

export default function InactivePatientList(id: any) {
  let packageId = id.packageId;

  const history = useHistory();
  const dispatch = useDispatch();

  const loadingMarkup = <AdminLoader />;

  const gotoDetails = (profileId: any) => {
    history.push(`/inactivepatientpaymentdetails/${packageId}/${profileId}`);
  };

  useEffect(() => {
    dispatch(getInactiveCorporatePatinetList(packageId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const inActiveCorporatepatientListDetails = useSelector(
    (state: AppState) => state.inactiveCorporatePatientValue,
  );
  const inActiveCorporatePatientListValues =
    inActiveCorporatepatientListDetails.inActiveCorporatePatientListValue;
  const activeCorporatePatientTeamCount = inActiveCorporatepatientListDetails.totalNoOfPatientTeam;

  let inActiveCorporatePatientListConfig: any = [];
  for (var i = 0; i < inActiveCorporatePatientListValues.length; i++) {
    let profileId =
      inActiveCorporatePatientListValues[i].profileId === null
        ? ""
        : inActiveCorporatePatientListValues[i].profileId;
    let firstname =
      inActiveCorporatePatientListValues[i].firstname === null ||
      inActiveCorporatePatientListValues[i].firstname === ""
        ? ""
        : inActiveCorporatePatientListValues[i].firstname;
    let lastname =
      inActiveCorporatePatientListValues[i].lastname === null ||
      inActiveCorporatePatientListValues[i].lastname === ""
        ? ""
        : inActiveCorporatePatientListValues[i].lastname;
    let email =
      inActiveCorporatePatientListValues[i].email === null ||
      inActiveCorporatePatientListValues[i].email === ""
        ? ""
        : inActiveCorporatePatientListValues[i].email;
    inActiveCorporatePatientListConfig.push([
      <Button variant="outlined" onClick={() => gotoDetails(profileId)} startIcon={<PreviewIcon />}>
        {t("common.view", { ns: ["home"] })}
      </Button>,
      firstname,
      lastname,
      email,
    ]);
  }
  const corporateInactivePatientListTableValue: any[] = inActiveCorporatePatientListConfig;

  const actualPageMarkup = (
    <Box sx={{ pt: 4 }}>
      <Card sx={{ backgroundColor: "#142329" }}>
        <Grid>
          <CardHeader title={t("common.patientTeamDetail", { ns: ["home"] })}></CardHeader>
          <CardContent>
            <Box pl={3}>
              {t("corporatepackage.numberof", { ns: ["home"] })}: {activeCorporatePatientTeamCount}
              {corporateInactivePatientListTableValue &&
              corporateInactivePatientListTableValue.length ? (
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("corporatepackage.viewdetails", { ns: ["home"] })}</TableCell>
                        <TableCell>
                          {t("patientteamlistpage.firstname", { ns: ["home"] })}
                        </TableCell>
                        <TableCell>{t("patientteamlistpage.lastname", { ns: ["home"] })}</TableCell>
                        <TableCell>{t("patientteamlistpage.email", { ns: ["home"] })}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {corporateInactivePatientListTableValue.map((row: any) => (
                        <TableRow
                          key={row.Email}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row[0]}</TableCell>
                          <TableCell align="left">{row[1]}</TableCell>
                          <TableCell align="left">{row[2]}</TableCell>
                          <TableCell align="left">{row[3]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>
                  <MWExceptionList />
                </Typography>
              )}
            </Box>
          </CardContent>
        </Grid>
      </Card>
    </Box>
  );
  return (
    <Container maxWidth="xl">
      {inActiveCorporatepatientListDetails === undefined ? "" : loadingMarkup}
      {actualPageMarkup}
    </Container>
  );
}
