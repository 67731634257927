import {
  CreateInstantMeetingActionTypes,
  CREATE_INSTANT_MEETING_FAIL,
  CREATE_INSTANT_MEETING_LOADING,
  CREATE_INSTANT_MEETING_SUCCESS,
  CreateInstantMeetingState,
  CREATE_INSTANT_MEETING_UPDATE_API_MSG,
  CREATE_INSTANT_MEETING_UPDATE_API_RES,
} from "./ActionTypes";
import { CreateInstantMeetingValueRes } from "./Model";

const initialStateGetPosts: CreateInstantMeetingState = {
  loading: false,
  createInstantMeetingRes: {} as CreateInstantMeetingValueRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const createInstantMeetingReducer = (
  state = initialStateGetPosts,
  action: CreateInstantMeetingActionTypes,
): CreateInstantMeetingState => {
  switch (action.type) {
    case CREATE_INSTANT_MEETING_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case CREATE_INSTANT_MEETING_SUCCESS:
      return {
        ...state,
        createInstantMeetingRes: action.payload,
        successMsg: action.successMsg,
      };
    case CREATE_INSTANT_MEETING_FAIL:
      return {
        ...state,
        createInstantMeetingRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CREATE_INSTANT_MEETING_UPDATE_API_MSG:
      return {
        ...state,
        createInstantMeetingRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CREATE_INSTANT_MEETING_UPDATE_API_RES:
      return {
        ...state,
        createInstantMeetingRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
