import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleIcon from "@mui/icons-material/People";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

export const DrawerList = [
  {
    name: `backtohome`,
    path: "/dashboard",
    icon: <KeyboardBackspaceIcon />,
  },
  {
    name: `dashboard`,
    path: "/dashboard",
    icon: <HomeIcon />,
  },

  {
    name: `corporatepackages`,
    path: "/corporatepackages",
    icon: <AccountBalanceIcon />,
  },
  {
    name: `doctorList`,
    path: "/doctors",
    icon: <PersonAddIcon />,
  },
  {
    name: `patientteamlist`,
    path: "/patientteam",
    icon: <GroupAddIcon />,
  },
  {
    name: `patientlist`,
    path: "/patients",
    icon: <PeopleAltIcon />,
  },
  {
    name: `patientcaselist`,
    path: "/allcaselist",
    icon: <PeopleIcon />,
  },
  {
    name: `doctoronboardingrequests`,
    path: "/doctoronboardingrequests",
    icon: <VisibilityIcon />,
  },
  {
    name: `executivelist`,
    path: "/executive",
    icon: <SupervisorAccountIcon />,
  },

  {
    name: `appointmentlist`,
    path: "/appointmentlist",
    icon: <DisplaySettingsIcon />,
  },
  {
    name: `patientqueries`,
    path: "/patient-queries",
    icon: <PersonSearchIcon />,
  },
  {
    name: `doctorqueries`,
    path: "/doctor-queries",
    icon: <ZoomInIcon />,
  },
  {
    name: `payment`,
    path: "/payment",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    name: `importdata`,
    path: "/importdata",
    icon: <ImportExportIcon />,
  },
  {
    name: `task`,
    path: "/task",
    icon: <NoteAltIcon />,
  },
  {
    name: `questionanswer`,
    path: "/question-answer",
    icon: <QuestionAnswerIcon />,
  },
  {
    name: `telehealth`,
    path: "/telehealth",
    icon: <PhoneIcon />,
  },
  {
    name: `userprofile`,
    path: "/admindetails",
    icon: <AccountCircleIcon />,
  },
  {
    name: `signout`,
    path: "/dashboard",
    icon: <LogoutIcon />,
  },
];
