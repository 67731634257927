import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { AddScheduleActionTypes } from "./ActionTypes";
import { AddScheduleBody, AddScheduleRes } from "./Model";
import {
  addScheduleAPIResClearAction,
  addScheduleErrorAction,
  addScheduleLoadingAction,
  addScheduleSuccessAction,
  addScheduleUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as AddScheduleRes;
export const addSchedule = (payload: AddScheduleBody) => {
  return function (dispatch: Dispatch<AddScheduleActionTypes>) {
    dispatch(addScheduleLoadingAction(true));
    axios
      .post(Url.baseUrl + `/appointmentapp/api/workSchedule/add_workSchedule`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addScheduleLoadingAction(false));
        dispatch(
          addScheduleSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "New schedule has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addScheduleLoadingAction(false));
        dispatch(
          addScheduleErrorAction(
            {} as AddScheduleRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateAddScheduleAPIResMsg = () => {
  return function (dispatch: Dispatch<AddScheduleActionTypes>) {
    dispatch(addScheduleUpdateAPIMsgAction(apiRes as AddScheduleRes, "", "", 0));
  };
};

export const clearAddWorkScheduleAPIRes = () => {
  return function (dispatch: Dispatch<AddScheduleActionTypes>) {
    dispatch(addScheduleAPIResClearAction({} as AddScheduleRes, "", "", 0));
  };
};
