import axios from "axios";
import { Dispatch } from "redux";
import { PatientImportConfigListActionTypes } from "./ActionTypes";
import { AddPatientImportConfigDetailsBody, AddPatientImportConfigValueRes } from "./Model";
import {
  addPatientImportConfigListAPIResClearAction,
  addPatientImportConfigListErrorAction,
  addPatientImportConfigListLoadingAction,
  addPatientImportConfigListSuccessAction,
  addPatientImportConfigListUpdateAPIMsgAction,
} from "./Actions";
import baseUrl from "../../ApiConfig";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "../Authentication/Interceptors";

let apiRes = {} as AddPatientImportConfigValueRes;
export const addPatientImportConfigApi = (payload: AddPatientImportConfigDetailsBody) => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(addPatientImportConfigListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/patientapp/api/dataimport/add_patient_import_config`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addPatientImportConfigListLoadingAction(false));
        dispatch(
          addPatientImportConfigListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Imported Config List added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addPatientImportConfigListLoadingAction(false));
        dispatch(
          addPatientImportConfigListErrorAction(
            {} as AddPatientImportConfigValueRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddPatientImportConfigListAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(
      addPatientImportConfigListUpdateAPIMsgAction(
        apiRes as AddPatientImportConfigValueRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearAddPatientImportConfigListAPIRes = () => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(
      addPatientImportConfigListAPIResClearAction({} as AddPatientImportConfigValueRes, "", "", 0),
    );
  };
};
