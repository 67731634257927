import {
  UPDATE_DOCTOR_DETAILS_FAIL,
  UPDATE_DOCTOR_DETAILS_LOADING,
  UPDATE_DOCTOR_DETAILS_SUCCESS,
  UPDATE_DOCTOR_DETAILS_UPDATE_API_MSG,
  UPDATE_DOCTOR_DETAILS_UPDATE_API_RES,
  UpdateDoctorDetailsActionTypes,
} from "./ActionTypes";
import { UpdateDoctorDetailsRes } from "./Model";

export const updateDoctorDetailsLoadingAction = (
  loading: boolean,
): UpdateDoctorDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_DETAILS_LOADING,
    loading: loading,
  };
};

export const updateDoctorDetailsSuccessAction = (
  updateDoctorDetailsResponse: UpdateDoctorDetailsRes,
): UpdateDoctorDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_DETAILS_SUCCESS,
    payload: updateDoctorDetailsResponse,
  };
};

export const updateDoctorDetailsErrorAction = (
  updateDoctorDetailsResponse: UpdateDoctorDetailsRes,
  errMsg: string,
  status: number,
): UpdateDoctorDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_DETAILS_FAIL,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updateDoctorDetailsUpdateAPIMsgAction = (
  updateDoctorDetailsResponse: UpdateDoctorDetailsRes,
  errMsg: string,
  status: number,
): UpdateDoctorDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_DETAILS_UPDATE_API_MSG,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const updateDoctorDetailsAPIResClearAction = (
  updateDoctorDetailsResponse: UpdateDoctorDetailsRes,
  errMsg: string,
  status: number,
): UpdateDoctorDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_DETAILS_UPDATE_API_RES,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
