import {
  CorporatePackageToggleTypes,
  CORPORATE_PACKAGE_TOGGLE_FAIL,
  CORPORATE_PACKAGE_TOGGLE_LOADING,
  CORPORATE_PACKAGE_TOGGLE_SUCCESS,
  GetCorporatePackageToggleStateType,
} from "./ActionTypes";

const initialStateGetActiveCorporateToggleValue: GetCorporatePackageToggleStateType = {
  loading: false,
  corporatePackageToggleValue: Object.assign({}),
};

export const getCorporatePackageToggleReducer = (
  state = initialStateGetActiveCorporateToggleValue,
  action: CorporatePackageToggleTypes,
): GetCorporatePackageToggleStateType => {
  switch (action.type) {
    case CORPORATE_PACKAGE_TOGGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        corporatePackageToggleValue: action.payload,
      };
    case CORPORATE_PACKAGE_TOGGLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CORPORATE_PACKAGE_TOGGLE_FAIL:
      return {
        ...state,
        loading: false,
        corporatePackageToggleValue: action.payload,
      };
    default:
      return state;
  }
};
