import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  AlertColor,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import AdminLoader from "../../AdminLoader";
import MWSnackbar from "../../../component/MWSnackbar";
import { GetDoctorWorkScheduleListBody } from "../../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/Model";
import { getDoctorWorkScheduleList } from "../../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/API";
import { DeleteScheduleBody } from "../../../redux/Doctor/Schedule/deleteSchedule/Model";
import {
  clearDeleteScheduleAPIRes,
  deleteSchedule,
  updateDeleteScheduleAPIResMsg,
} from "../../../redux/Doctor/Schedule/deleteSchedule/API";
import { t } from "i18next";
type Props = {
  itemId?: string | any;
  deleteScheduleDialog?: boolean | any;
  updateDeleteScheduleDialogUpdatedState: boolean | any;
};

export default function DeleteSchedule({
  itemId,
  deleteScheduleDialog,
  updateDeleteScheduleDialogUpdatedState,
}: Props) {
  const dispatch = useDispatch();
  const { id } = useParams() as {
    id: string;
  };
  const [deleteScheduleDialogVal, setDeleteScheduleDialogVal] = useState(false);
  useEffect(() => {
    setDeleteScheduleDialogVal(deleteScheduleDialog);
  }, [deleteScheduleDialog]);
  // popup close action...........
  const closeSchedule = () => {
    setDeleteScheduleDialogVal((deleteScheduleDialogVal) => !deleteScheduleDialogVal);
    updateDeleteScheduleDialogUpdatedState(false);
  };

  // Delete body....
  const deleteScheduleBody = {
    workScheduleId: itemId,
  } as DeleteScheduleBody;

  const deleteDrSchedule = () => {
    dispatch(deleteSchedule(deleteScheduleBody));
  };
  const deleteScheduleValRes = useSelector((state: AppState) => state.deleteSchedule);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<AlertColor>("success");

  const [errMessage, setErrMessage] = useState("");
  const [errMessageType, setErrMessageType] = useState<AlertColor>("success");

  const getScheduleBody = {
    doctorId: id,
  } as GetDoctorWorkScheduleListBody;

  useEffect(() => {
    if (deleteScheduleValRes?.successMsg !== "") {
      setMessage(deleteScheduleValRes?.successMsg);
      setMessageType("success");
      closeSchedule();
      dispatch(getDoctorWorkScheduleList(getScheduleBody));
    } else if (deleteScheduleValRes?.errorMsg !== "") {
      setErrMessage(deleteScheduleValRes?.errorMsg);
      setErrMessageType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteScheduleValRes]);
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateDeleteScheduleAPIResMsg());
      dispatch(clearDeleteScheduleAPIRes());
    }
  };
  const closeBanner = () => {
    dispatch(updateDeleteScheduleAPIResMsg());
    dispatch(clearDeleteScheduleAPIRes());
  };

  return (
    <Box>
      <Dialog open={deleteScheduleDialogVal} onClose={closeSchedule} fullWidth>
        {deleteScheduleValRes?.loading ? <AdminLoader /> : null}
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">{t("Workschedulepage.delSch", { ns: ["home"] })}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={closeSchedule}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            {t("Workschedulepage.qDelSch", { ns: ["home"] })}
          </Typography>
          {errMessageType === "error" && errMessage !== "" ? (
            <Box my={1}>
              <Alert severity={errMessageType} onClose={() => closeBanner()}>
                {errMessage}
              </Alert>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeSchedule()} variant="outlined">
            {t("filePrepPage.no", { ns: ["home"] })}
          </Button>
          <Button
            onClick={() => deleteDrSchedule()}
            variant="contained"
            disabled={deleteScheduleValRes?.loading}
          >
            {t("filePrepPage.yes", { ns: ["home"] })}
          </Button>
        </DialogActions>
      </Dialog>
      <MWSnackbar msg={message} type={messageType} alertClose={checkAlertClose} />
    </Box>
  );
}
