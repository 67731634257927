import { Card, CardHeader, CardContent, Button, Box } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import { PatientQuestionnaireList } from "../../../../../redux/FilePreparation/Questionnaire/Model";
import MWExceptionList from "../../../../../component/MWExceptionList";
import ViewDocument from "../ViewDocument";
import { t } from "i18next";

export default function Questionnaire() {
  const questionnaireListRes = useSelector((state: AppState) => state.patientQuestionnaireListRes);
  const [questionnaireList, setQuestionnaireList] = useState([] as PatientQuestionnaireList[]);
  useEffect(() => {
    if (questionnaireListRes?.getQuestionnaireListRes?.patientQuestionnaireList !== undefined) {
      let questionItem =
        questionnaireListRes?.getQuestionnaireListRes?.patientQuestionnaireList.map(
          (item: PatientQuestionnaireList) => {
            return {
              id: item.id,
              name:
                item?.questionnaireType?.displayValue !== undefined
                  ? item.questionnaireType?.displayValue
                  : "",
              docUrl: item?.templateUrl !== undefined ? item?.templateUrl : "",
            };
          },
        );
      setQuestionnaireList(questionItem);
    } else setQuestionnaireList([] as PatientQuestionnaireList[]);
  }, [questionnaireListRes]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogUpdateState = (passedVal: boolean) => {
    setDialogOpen(passedVal);
  };
  const [docName, setDocName] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const columns: GridColDef[] = [
    { field: "name", headerName: `${t("appointmentpage.quesname", { ns: ["home"] })!}`, flex: 1 },
    {
      field: "docUrl",
      headerName: `${t("common.view", { ns: ["home"] })!}`,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setDialogOpen(true);
            setDocUrl(params.row.docUrl);
            setDocName(params.row.name);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("appointmentpage.viewques", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];
  return (
    <Box>
      <Card>
        <CardHeader title={t("appointmentpage.questionnaire", { ns: ["home"] })!} />
        <CardContent>
          {questionnaireList.length > 0 ? (
            <DataGrid
              rows={questionnaireList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {dialogOpen ? (
        <ViewDocument
          dialogEnable={dialogOpen}
          dialogUpdateState={dialogUpdateState}
          docUrl={docUrl}
          docName={docName}
        />
      ) : null}
    </Box>
  );
}
