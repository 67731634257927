import axios from "axios";
import Url from "../../../ApiConfig";
import { Dispatch } from "react";
import { getPatientListActions } from "./Actions";
import {
  CorporatePackagePaymentListTypes,
  CORPORATE_PACKAGE_PAYMENT_LIST_FAIL,
  CORPORATE_PACKAGE_PAYMENT_LIST_LOADING,
  CORPORATE_PACKAGE_PAYMENT_LIST_SUCCESS,
} from "./ActionTypes";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

// Active Corporate Package PaymentList API
export const getCorporatePackagePaymentList = (body: Object) => {
  return function (dispatch: Dispatch<CorporatePackagePaymentListTypes>) {
    dispatch({
      type: CORPORATE_PACKAGE_PAYMENT_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/paymentapp/api/corporatePackageForAdmin/corporatePackagePatientPaymentList`,
        body,
        { headers: headers },
      )
      .then((res) => {
        let paymentList = res.data.paymentList;
        let status = res.data.status;
        dispatch(getPatientListActions(paymentList, status));
        dispatch({
          type: CORPORATE_PACKAGE_PAYMENT_LIST_SUCCESS,
          payload: paymentList,
          status: status,
        });
      })
      .catch((error) => {
        let status = error.response.data.status;
        dispatch({
          type: CORPORATE_PACKAGE_PAYMENT_LIST_FAIL,
          status: status,
        });
      });
  };
};
