import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { importCallApi } from "../../redux/ImportData/ImportFromPhone/ApiCall";
import { AppState } from "../../redux/store/Store";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SearchIcon from "@mui/icons-material/Search";
import SurroundSoundIcon from "@mui/icons-material/SurroundSound";
import { importFromPhone } from "../../redux/importFromPhone/API";
import { AlertColor, Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import { clearfindUserAPIRes, findUserApi } from "../../redux/ImportData/FindUserNumber/API";
import { FindUserBody } from "../../redux/ImportData/FindUserNumber/Model";
import MWSnackbar from "../../component/MWSnackbar";
import { CallList } from "../../redux/ImportData/ImportFromPhone/Model";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { t } from "i18next";

export default function ImportFromPhone(props: any) {
  const dispatch = useDispatch();
  const { importFromPhoneLoader } = props;
  const importDatacolumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "createDate",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })!}`,
      width: 150,
    },
    {
      field: "exoTelCreateDate",
      headerName: `${t("importdadaPage.exotel", { ns: ["home"] })!}`,
      width: 150,
    },
    {
      field: "phoneNumber",
      headerName: `${t("accountdetail.phoneno", { ns: ["home"] })!}`,
      width: 120,
    },
    {
      field: "fromNumber",
      headerName: `${t("importdadaPage.from", { ns: ["home"] })!}`,
      width: 100,
    },
    { field: "toNumber", headerName: `${t("importdadaPage.to", { ns: ["home"] })!}`, width: 100 },
    {
      field: "recordingUrl",
      headerName: `${t("importdadaPage.recordUrl", { ns: ["home"] })!}`,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<SurroundSoundIcon />}
          onClick={() => {
            gotoRecordingUrl(params.row.recordingUrl);
          }}
        >
          {t("importdadaPage.recordUrl", { ns: ["home"] })!}
        </Button>
      ),
      width: 150,
    },
    {
      field: "findUser",
      headerName: `${t("importdadaPage.findUser", { ns: ["home"] })!}`,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => findUser(Number(params.row.fromNumber))}
          startIcon={<SearchIcon />}
        >
          {t("importdadaPage.find", { ns: ["home"] })!}
        </Button>
      ),
      width: 120,
    },
    { field: "sidNumber", headerName: "Sid", flex: 1 },
  ];

  const gotoRecordingUrl = (url: any) => {
    if (url === "" || url === null) {
      alert("No Url Found");
    } else {
      window.open(url);
    }
  };
  const findUserValue = useSelector((state: AppState) => state.findUserValue);
  const importListValue = useSelector((state: AppState) => state.importPhoneDataValue);

  const findUser = (fromNumber: number) => {
    dispatch(findUserApi({ phone: fromNumber } as FindUserBody));
  };

  const importRecentDataFromPhone = () => {
    dispatch(importFromPhone());
  };

  const [responseType, setResponseType] = useState<AlertColor>("success");
  const [responseMsg, setResponseMsg] = useState("");
  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(clearfindUserAPIRes());
    }
  };
  const [importDataListValue, setImportData] = useState([] as any[]);
  useEffect(() => {
    if (importListValue !== undefined) {
      let imprtList = importListValue?.importCallRes?.callList?.map((element: CallList) => ({
        id: element.id,
        createDate:
          element?.createDate !== "" ? dayjs(element?.createDate).format("ddd, MMM D, YYYY") : "",
        exoTelCreateDate:
          element?.exoTelCreateDate !== ""
            ? dayjs(element.exoTelCreateDate).format("ddd, MMM D, YYYY")
            : "",
        phoneNumber: element?.phoneNumber !== 0 ? element?.phoneNumber : "",
        fromNumber: element?.fromNumber !== "" ? element.fromNumber : "",
        toNumber: element?.toNumber !== "" ? element?.toNumber : "",
        recordingUrl: element.recordingUrl !== "" ? element.recordingUrl : "",
        findUser: element.fromNumber !== "" ? element.fromNumber : "",
        sidNumber: element.sidNumber,
      }));
      setImportData(imprtList);
    } else setImportData([] as any[]);
  }, [importListValue]);
  const bodyMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<ImportExportIcon />}
                    onClick={() => importRecentDataFromPhone()}
                  >
                    {t("importdadaPage.impRecent", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {importDataListValue && importDataListValue.length ? (
          <DataGrid
            rows={importDataListValue}
            columns={importDatacolumns}
            hideFooter={true}
            autoHeight
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(importCallApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (findUserValue?.message !== "") {
      setResponseType("error");
      setResponseMsg(findUserValue?.message);
    }
    // else if (findUserValue?.status !== undefined) {
    //   setResponseType("success");
    //   setResponseMsg(findUserValue?.findUserRes?.userType);
    // }
  }, [findUserValue]);

  return (
    <Box>
      {importFromPhoneLoader(importListValue?.loading || findUserValue?.loading)}
      {bodyMarkup}
      {responseMsg !== "" ? (
        <MWSnackbar msg={responseMsg} type={responseType} alertClose={alertCloseAction} />
      ) : null}
    </Box>
  );
}
