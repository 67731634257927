import {
  AddDrOnboardingRequestStateType,
  AddDrOnboardingRequestTypes,
  ADD_DR_ONBOARDING_REQUEST_FAIL,
  ADD_DR_ONBOARDING_REQUEST_LOADING,
  ADD_DR_ONBOARDING_REQUEST_SUCCESS,
  DrApproveOrRejectStateType,
  DrApproveOrRejectTypes,
  DR_APPROVE_REJECT_REQUEST_FAIL,
  DR_APPROVE_REJECT_REQUEST_LOADING,
  DR_APPROVE_REJECT_REQUEST_SUCCESS,
} from "./ActionTypes";

const initialStateGetActiveCorporateList: AddDrOnboardingRequestStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const addDoctorReducer = (
  state = initialStateGetActiveCorporateList,
  action: AddDrOnboardingRequestTypes,
): AddDrOnboardingRequestStateType => {
  switch (action.type) {
    case ADD_DR_ONBOARDING_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case ADD_DR_ONBOARDING_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_DR_ONBOARDING_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};

const initialStateDrApproveOrReject: DrApproveOrRejectStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const drApproveOrReject = (
  state = initialStateDrApproveOrReject,
  action: DrApproveOrRejectTypes,
): DrApproveOrRejectStateType => {
  switch (action.type) {
    case DR_APPROVE_REJECT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case DR_APPROVE_REJECT_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DR_APPROVE_REJECT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
