import { GetDoctorDetailsByIdRes } from "./Model";
export const GET_DOCTOR_DETAILS_BY_ID_LOADING = "GET_DOCTOR_DETAILS_BY_ID_LOADING";
export const GET_DOCTOR_DETAILS_BY_ID_SUCCESS = "GET_DOCTOR_DETAILS_BY_ID_SUCCESS";
export const GET_DOCTOR_DETAILS_BY_ID_FAIL = "GET_DOCTOR_DETAILS_BY_ID_FAIL";
export const GET_DOCTOR_DETAILS_BY_ID_DATA = "GET_DOCTOR_DETAILS_BY_ID_DATA";
export const GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_MSG = "GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_MSG";
export const GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_RES = "GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_RES";

export interface GetDoctorDetailsByIdState {
  getDoctorDetailsByIdRes: GetDoctorDetailsByIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorDetailsByIdLoading {
  type: typeof GET_DOCTOR_DETAILS_BY_ID_LOADING;
  loading: boolean;
}
export interface GetDoctorDetailsByIdSuccess {
  type: typeof GET_DOCTOR_DETAILS_BY_ID_SUCCESS;
  payload: GetDoctorDetailsByIdRes;
  successMsg: string;
}
export interface GetDoctorDetailsByIdFail {
  type: typeof GET_DOCTOR_DETAILS_BY_ID_FAIL;
  payload: GetDoctorDetailsByIdRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorDetailsByIdUpdateAPIMsg {
  type: typeof GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_MSG;
  payload: GetDoctorDetailsByIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorDetailsByIdUpdateAPIRes {
  type: typeof GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_RES;
  payload: GetDoctorDetailsByIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorDetailsByIdActionTypes =
  | GetDoctorDetailsByIdLoading
  | GetDoctorDetailsByIdSuccess
  | GetDoctorDetailsByIdFail
  | GetDoctorDetailsByIdUpdateAPIMsg
  | GetDoctorDetailsByIdUpdateAPIRes;
