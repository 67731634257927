import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import AdminLoader from "./AdminLoader";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { Box, Button, Card, CardContent, Container, Stack } from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import { t } from "i18next";
import { getAdminAppointmentList } from "../redux/Doctor/GetDoctorAppointmentList/API";
import {
  AppointmentRequestList,
  GetDoctorAppointmentListBody,
} from "../redux/Doctor/GetDoctorAppointmentList/Model";
import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function DoctorAppointments() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { doctorId } = useParams() as {
    doctorId: string;
  };
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const [listCount, setListCount] = React.useState("");

  function gotoDetails(patientId: number, caseId: Number) {
    history.push(`/casedetails/${patientId}/${caseId}`);
  }

  const appointmentBody = {
    targetDoctorId: doctorId,
  } as GetDoctorAppointmentListBody;

  // Selection Type GET API
  const getAppoinmentRequestList = () => {
    setIsLoading(true);
    dispatch(getAdminAppointmentList(appointmentBody));
  };

  // CAll Appontment list from Store
  const appointmentList = useSelector((state: AppState) => state.getDoctorAppointmentListValue);

  const appointmentListValue = appointmentList?.getDoctorAppointmentListRes?.appointmentRequestList;

  useEffect(() => {
    appointmentList?.getDoctorAppointmentListRes?.appointmentRequestList !== undefined
      ? setListCount(
          String(appointmentList?.getDoctorAppointmentListRes?.appointmentRequestList.length),
        )
      : setListCount("0");

    if (appointmentListValue !== undefined) {
      let dataPointArr = appointmentListValue.map((element: AppointmentRequestList) => ({
        id: element.id,
        name: element.name,
        patientId: element.patientId,
        caseId: element.caseId,
        createDate: dayjs(element.createDate).format("ddd, MMM D, YYYY"),
        end: dayjs(element.end).format("ddd, MMM D, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("ddd, MMM D, YYYY"),
        status: element.status,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("doctorAppointmentDetails.viewDetails", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.patientId, params.row.caseId);
          }}
        >
          {t("doctorAppointmentDetails.view", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "name",
      headerName: `${t("doctorAppointmentDetails.des", { ns: ["home"] })!}`,
      width: 500,
    },

    {
      field: "createDate",
      headerName: `${t("doctorAppointmentDetails.createDate", { ns: ["home"] })!}`,
      width: 400,
      flex: 1,
    },
    {
      field: "endDate",
      headerName: `${t("doctorAppointmentDetails.endDate", { ns: ["home"] })!}`,
      width: 300,
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: `${t("doctorAppointmentDetails.modifiedDate", { ns: ["home"] })!}`,
      width: 300,
      flex: 1,
    },
    {
      field: "status",
      headerName: `${t("doctorAppointmentDetails.status", { ns: ["home"] })!}`,
      width: 300,
      flex: 1,
    },
  ];

  useEffect(() => {
    // Do api call here....
    getAppoinmentRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selfTaskDetailsApi() {
    dispatch(getAppoinmentRequestList());
  }

  const tableMarkup = (
    <Card>
      <CardContent>
        {appointmentListValue && appointmentListValue.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {appointmentList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack pt={2}>
          <MWPageTitle
            title={`${t("doctorAppointmentDetails.docListAppo", { ns: ["home"] })!}`}
            enableCount={true}
            count={listCount}
            backButton={true}
            reload={true}
            reloadAction={selfTaskDetailsApi}
          />
          {tableMarkup}
        </Stack>
      </Container>
    </Box>
  );
}
