import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Divider,
  AlertColor,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { DeletePatientDataByIdBody } from "../../../../../redux/FilePreparation/DeletePatientDataById/Model";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeletePatientDataByIdAPIRes,
  deletePatientDataById,
  updateDeletePatientDataByIdAPIResMsg,
} from "../../../../../redux/FilePreparation/DeletePatientDataById/API";
import { AppState } from "../../../../../redux/store/Store";
import AdminLoader from "../../../../AdminLoader";
import { GetPatientDataDetailsBody } from "../../../../../redux/FilePreparation/GetPatientDataList/Model";
import { getPatientDataList } from "../../../../../redux/FilePreparation/GetPatientDataList/API";
import { t } from "i18next";
interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  docTypeName?: string;
  itemId?: number | any;
  type?: string | any;
}

export default function DeletePatientData({
  dialogEnable,
  dialogUpdateState,
  docTypeName,
  itemId,
  type,
}: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    dialogUpdateState(false);
    dispatch(updateDeletePatientDataByIdAPIResMsg());
    dispatch(clearDeletePatientDataByIdAPIRes());
  };
  useEffect(() => {
    dialogEnable !== undefined ? setDialogOpen(dialogEnable) : setDialogOpen(false);
  }, [dialogEnable]);
  const [delItemid, setDeleteItemId] = useState(0);
  useEffect(() => {
    itemId !== undefined ? setDeleteItemId(itemId) : setDeleteItemId(0);
  }, [itemId]);
  const deletePatientDateBody = {
    caseId: caseid,
    patientDetailId: delItemid,
  } as DeletePatientDataByIdBody;
  const deleteItem = () => {
    dispatch(deletePatientDataById(deletePatientDateBody));
  };

  const deletePatientDataListRes = useSelector((state: AppState) => state.deletePatientDataByIdRes);

  const [deletePatientDataMsgType, setDeletePatientDataMsgType] = useState<AlertColor>("success");
  const [deletePatientDataErrorMsg, setDeletePatientDataErrorMsg] = useState("");

  const getPatientDataDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetPatientDataDetailsBody;
  useEffect(() => {
    if (deletePatientDataListRes?.successMsg !== "") {
      dispatch(getPatientDataList(getPatientDataDetails));
      handleClose();
    } else if (deletePatientDataListRes?.errorMsg !== "") {
      setDeletePatientDataErrorMsg(deletePatientDataListRes?.errorMsg);
      setDeletePatientDataMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePatientDataListRes]);

  return (
    <Dialog open={dialogOpen} fullWidth>
      {deletePatientDataListRes?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            {t("common.del", { ns: ["home"] })} {t(`filePrepPage.${docTypeName}`, { ns: ["home"] })}
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {t("common.rYouWantDel", { ns: ["home"] })}{" "}
        {t(`filePrepPage.${docTypeName}`, { ns: ["home"] })}?
      </DialogContent>
      <Divider />
      {deletePatientDataErrorMsg !== "" ? (
        <Alert severity={deletePatientDataMsgType} onClose={() => {}}>
          {deletePatientDataErrorMsg}
        </Alert>
      ) : null}
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()} sx={{ textTransform: "none" }}>
          {t("filePrepPage.no", { ns: ["home"] })}
        </Button>
        <Button
          variant="contained"
          onClick={() => deleteItem()}
          disabled={deletePatientDataListRes?.loading}
          sx={{ textTransform: "none" }}
        >
          {t("filePrepPage.yes", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
