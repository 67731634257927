import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { DeleteScheduleActionTypes } from "./ActionTypes";
import { DeleteScheduleBody, DeleteScheduleRes } from "./Model";
import {
  deleteScheduleAPIResClearAction,
  deleteScheduleErrorAction,
  deleteScheduleLoadingAction,
  deleteScheduleSuccessAction,
  deleteScheduleUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as DeleteScheduleRes;
export const deleteSchedule = (payload: DeleteScheduleBody) => {
  return function (dispatch: Dispatch<DeleteScheduleActionTypes>) {
    dispatch(deleteScheduleLoadingAction(true));
    axios
      .post(Url.baseUrl + `/appointmentapp/api/workSchedule/delete_workSchedule`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(deleteScheduleLoadingAction(false));
        dispatch(
          deleteScheduleSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "New schedule has been deleteed successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(deleteScheduleLoadingAction(false));
        dispatch(
          deleteScheduleErrorAction(
            {} as DeleteScheduleRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDeleteScheduleAPIResMsg = () => {
  return function (dispatch: Dispatch<DeleteScheduleActionTypes>) {
    dispatch(deleteScheduleUpdateAPIMsgAction(apiRes as DeleteScheduleRes, "", "", 0));
  };
};

export const clearDeleteScheduleAPIRes = () => {
  return function (dispatch: Dispatch<DeleteScheduleActionTypes>) {
    dispatch(deleteScheduleAPIResClearAction({} as DeleteScheduleRes, "", "", 0));
  };
};
