import { combineReducers } from "redux";
import { getHealthcampListReducer } from "../healthcampList/Reducer";
import { getPatientDataPointListReducer } from "../patientDataPointList/patientDataPointListByPatientID/Reducer";
import { doctorsOnboardingImcompleteReducer } from "../doctorsOnboardingImcomplete/Reducer";
import { importFromPhoneReducer } from "../importFromPhone/Reducer";
import { executiveListReducer } from "../executiveListDropDown/Reducer";
import { assignExecutiveReducer } from "../assignExecutive/Reducer";
import { previewListReducer } from "../Preview/previewApi/Reducer";
import { templateListReducer } from "../Preview/templateListApi/Reducer";
import { historyandIllnessReducer } from "../HistoryAndIllness/AddHealthAndIllness/Reducer";
import { addhistoryandIllnessReducer } from "../HistoryAndIllness/GetHealthAndIllness/Reducer";
import { getIdentifyReducer } from "../IdentifydataAndSource/GetIdentify/Reducer";
import { addIdentifyReducer } from "../IdentifydataAndSource/AddIdentify/Reducer";
import { deleteIdentifyReducer } from "../IdentifydataAndSource/DeleteIdentify/Reducer";
import { getInsuranceReducer } from "../Insurance/GetInsurance/Reducer";
import { addInsuranceReducer } from "../Insurance/AddInsurance/Reducer";
import { acceptorRejectBookingReducer } from "../AppointmentBooking/Reducer";
import { caseDetailsReducer } from "../ConsultationOption/Reducer";
import { getCorporatePackageListReducer } from "../CorporatePackage/Package/Reducer";
import { getCorporatePackageToggleReducer } from "../CorporatePackage/TogglePackageStatus/Reducer";
import { getPatientListReducer } from "../CorporatePackage/PatientTeam/PatientList/Reducer";
import { getCorporatePackagePaymentListReducer } from "../CorporatePackage/Payment/Reducer";
import { addSummary, contactDetail, doctorAppointment } from "../Doctor/Reducer";
import { drApproveOrReject } from "../DoctorOnboarding/Reducer";
import {
  getExecutiveListReducer,
  deleteExecutiveReducer,
  addExecutiveReducer,
  editExecutiveReducer,
} from "../Executive/Reducer";
import {
  getDoctorReducer,
  getOnboardingDoctorRequestReducer,
  getOnboardingDoctorRejectReducer,
  getOnboardingDoctorIncompleteReducer,
  getDoctorListReducer,
  getPatientReducer,
  getTaskListReducer,
  getQuestionListReducer,
  getCaseReducer,
  getQuestionReducer,
  getCCReducer,
  addCCReducer,
  deleteDocumentReducer,
  getAllCaseReducer,
  getAllInactiveTaskListReducer,
  getActiveCorporateListReducer,
  getInactiveCorporateListReducer,
  getActiveCorporatePatientListReducer,
  getInactiveCorporatePatientListReducer,
  acceptOrRejectBookingAppointment,
  taskCloseByAdmiin,
} from "./Reducer";
import { stepandSectionListReducer } from "../StepsAndSection/Reducer";
import { addPastMedicalReducer } from "../PastMedicallHistory/AddPastMedicalHistory/Reducer";
import { getPastMedicalReducer } from "../PastMedicallHistory/GetPastMedicalHistory/Reducer";
import { deletePastMedicalReducer } from "../PastMedicallHistory/DeletePastMedicalHistory/Reducer";
import { symptomAddReducer } from "../Symptom/addSymptom/Reducer";
import { symptomListReducer } from "../Symptom/getSymptom/Reducer";
import { getTelehealthMeetingReducer } from "../Telehealth/Reducer";
import { getExecutiveTaskListReducer, taskCloseReducer } from "../Task/Reducer";
import {
  addPatientTeamReducer,
  appointmentPatientTeamListReducer,
} from "../Patient/AppointmentPatientTeam/Reducer";
import {
  addDrNotes,
  addNotesForDrReducer,
  changeDrNoteStatus,
  changeNoteStatusForDrReducer,
  NotesForDrReducer,
  getDrNoteReducer,
} from "../Notes/Reducer";
import {
  askQuestionReducer,
  createOrScheduleReducer,
  deleteMeetingReducer,
  doctorListForCaseReducer,
  meetingListReducer,
  questionListReducer,
} from "../Patient/DoctorPatientInteraction/Reducer";
import { addAnswer, questionverifyReducer } from "../QuestionAnswer/Reducer";
import {
  addPatientInPackageReducer,
  getPatientTeamListReducer,
} from "../CorporatePackage/PatientTeam/Reducer";
import {
  addOrupdateHealthRecordReducer,
  getBloodGroupDropdownListReducer,
  getHealthRecordReducer,
  getLanguageDropdownListReducer,
  getMaritalStatusDropdownListReducer,
  getSexualorientationDropdownListReducer,
  gettGenderDropdownListReducer,
  gettRelationshipDropdownListReducer,
} from "../Patient/DemographicsDetails/Reducer";
import {
  addOrEditPatientDataImportConfigReducer,
  getPatientDataImportConfigListReducer,
  getPatientImportListReducer,
} from "../Patient/ImportData/Reducer";
import { deleteOpinion, getOpinion } from "../Patient/FinalOpinion/Reducer";
import { addPatientteamByAdminReducer, getpatientTeamListReducer } from "../PatientTeam/Reducer";
import { getHealthcampPatientReducer } from "../patientListOfHealthcamp/Reducer";
import { addDemographicsReducer } from "../DemographicsDetails/AddDemographicsDetails/Reducer";
import { getBloodGroupListReducer } from "../DemographicsDetails/BloodGroupList/Reducer";
import { getGenderListReducer } from "../DemographicsDetails/GenderList/Reducer";
import { getDemographicsDetailsByPtIdReducer } from "../DemographicsDetails/GetPatientDemographicsDetails/Reducer";
import { getLanguageListReducer } from "../DemographicsDetails/LanguageList/Reducer";
import { getMaritalListListReducer } from "../DemographicsDetails/MaritalStatusList/Reducer";
import { getRelationshipListReducer } from "../DemographicsDetails/RelationShipList/Reducer";
import { getSexualOrientationListReducer } from "../DemographicsDetails/SexualOrientationList/Reducer";
import { updateDemographicsDetailsByPatientIdReducer } from "../DemographicsDetails/UpdateDemographicsDetails/Reducer";
import { imageUploadReducer } from "../ImageUpload/Reducer";
import { logOutReducer } from "../logOut/Reducer";
import { govtIDProofReducer } from "../DemographicsDetails/govtIdProofList/Reducer";
import { patientSearchReducer } from "../PatientSearchApi/Reducer";
import { addMasterConfigReducer } from "../addMasterImportConfig/Reducer";
import { getPatientImportConfigListReducer } from "../getPatientImportConfigList/Reducer";
import { addPatientImportConfigReducer } from "../addPatientImportConfig/Reducer";
import { editPatientImportConfigReducer } from "../editPatientImportConfig/Reducer";
import { UpdatePatientDataPointReducer } from "../UpdatePatientDataPoint/Reducer";
import { simpleHistoryReducer } from "../SimpleHistory/Reducer";
import { getPatientDataDetailsListReducer } from "../patientDataDetails/patientDataPointListByPatientID/Reducer";
import { addPointDataDetailsReducer } from "../patientDataDetails/addPatientDetails/Reducer";
import { updatePatientDataDetailsReducer } from "../patientDataDetails/UpdatePatientDataPoint/Reducer";
import { getAllTaskListReducer } from "../TaskAdmin/List/Reducer";
import { executiveDropDownListReducer } from "../TaskAdmin/ExecutiveList/Reducer";
import { assigntaskToExecutiveReducer } from "../TaskAdmin/AssignExecutive/Reducer";
import { updatePatientCaseStatusReducer } from "../Patient/AppointmentSelectPatient/updatePatientCaseDetails/Reducer";
import { getCaseStatusListReducer } from "../Patient/AppointmentSelectPatient/caseStatusList/Reducer";
import { getPatientCaseDetailsByIdReducer } from "../Patient/AppointmentSelectPatient/getPatientCseDetailsById/Reducer";
import { deletePatientCaseReducer } from "../Patient/FilePreparation/deletePatientCase/Reducer";
import { findUserReducer } from "../ImportData/FindUserNumber/Reducer";
import { importCallReducer } from "../ImportData/ImportFromPhone/Reducer";
import { getPatientCaseNoteListReducer } from "../Notes/PatientCaseNoteList/Reducer";
import { AddNoteReducer } from "../Notes/addNotes/Reducer";
import { updateNoteReducer } from "../Notes/updateNoteStatus/Reducer";
import { adminPaymentReducer } from "../PaymentAdmin/PaymentList/Reducer";
import { doctorQuriesListReducer } from "../DoctorQuriesList/Reducer";
import { getDoctorDetailsByIdReducer } from "../Doctor/GetDoctorDetailsById/Reducer";
import { statusPaymentReducer } from "../PaymentAdmin/PaymentStatus/Reducer";
import { patientQuriesListReducer } from "../PatientQuriesList /Reducer";
import { updateDoctorDetailsReducer } from "../Doctor/updateDoctorDetails/Reducer";
import { getDoctorContactDetailsReducer } from "../Doctor/GetDoctorContactDetails/Reducer";
import { updateDoctorContactDetailsReducer } from "../Doctor/updateDoctorContactDetails/Reducer";
import { allappointmentListReducer } from "../Appointment/AppointmentList/Reducer";
import { appointmentDeactivateReducer } from "../Appointment/AppointmentDeactive/Reducer";
import { GetExtractObservationByPatientIdReducer } from "../getExtractObservationByPatientId/Reducer";
import { getPatientDetailsByIdReducer } from "../patientDataDetails/patientDetailsById/Reducer";
import { extractObservationByDetailsIdReducer } from "../extractObservationDetailsById/Reducer";
import { extractObservationByPatientIdReducer } from "../extractObservationDetailsByPatientId/Reducer";
import { reviewNeededReducer } from "../patientDataDetails/reviewNeeded/Reducer";
import { getDoctorWorkScheduleListReducer } from "../Doctor/Schedule/GetDoctorWorkScheduleList/Reducer";
import { getWeekDayListReducer } from "../Doctor/Schedule/WeekDayList/Reducer";
import { addScheduleReducer } from "../Doctor/Schedule/AddSchedule/Reducer";
import { deleteScheduleReducer } from "../Doctor/Schedule/deleteSchedule/Reducer";
import { getQuestionnaireListReducer } from "../FilePreparation/Questionnaire/Reducer";
import { getPatientDataListReducer } from "../FilePreparation/GetPatientDataList/Reducer";
import { deletePatientDataByIdReducer } from "../FilePreparation/DeletePatientDataById/Reducer";
import { addPatientDataReducer } from "../FilePreparation/AddPatientDataList/Reducer";
import { getDoctorTeamTypeListReducer } from "../Patient/AppointmentSelectDoctor/DoctorTeamTypeList/Reducer";
import { addRequestAppointmentReducer } from "../Patient/PatientRequestAppointment/Reducer";
import { requestAppointmentDetailsReducer } from "../Patient/GetAppointmentList/Reducer";
import { patientSearchByHealthCampIdReducer } from "../PatientSearchByHealcampId/Reducer";
import { getOrganizationListReducer } from "../OrganizationList/Reducer";
import { patientSearchByOrganizationIdReducer } from "../PatientSearchByOrganizationId/Reducer";
import { organizationListMappedWithHealthCampReducer } from "../OrganizationListMappedWithHealthCamp/Reducer";
import { getHealthCampByOrganizationListReducer } from "../getMappedHealthcampsByOrganization/Reducer";
import { editDoctorTeamTypeReducer } from "../Patient/AppointmentSelectDoctor/EditDoctorTeamType/Reducer";
import { getDoctorCategoryListReducer } from "../Patient/AppointmentSelectDoctor/DoctorCategoryList/Reducer";
import { getDoctorListByCategoryReducer } from "../Patient/AppointmentSelectDoctor/DoctorListByCategory/Reducer";
import { getDoctorListForCaseReducer } from "../Patient/AppointmentSelectDoctor/DoctorListForCase/Reducer";
import { deleteDoctorForCaseReducer } from "../Patient/AppointmentSelectDoctor/DeleteDoctor/Reducer";
import { getDoctorTeamTypeForCaseReducer } from "../Patient/AppointmentSelectDoctor/DoctorTeamTypeForCase/Reducer";
import { addDoctorForCaseReducer } from "../Patient/AppointmentSelectDoctor/AddDoctorForCase/Reducer";
import { getPatientNoteListReducer } from "../Notes/PatientNotesList/Reducer";
import { getHealthCampDropDownListReducer } from "../getAllHealthcampDropdownList/Reducer";
import { getInactiveCorporatePackageListReducer } from "../CorporatePackage/InactiveCorporatePackage/Reducer";
import { getProviderOrganizationListReducer } from "../ProviderOrganizationList/Reducer";
import { getProviderOrganizationAdministratorListReducer } from "../ProviderOrganizationAdministratorList /Reducer";
import { getAdministratorDetailsReducer } from "../ProviderOrganizationAdministratorDetails/Reducer";
import { getAppointmentPaymentListReducer } from "../Patient/AppointmentPaymentList/Reducer";
import { checkAppointmentPaymentStatusReducer } from "../Patient/AppointmentPaymentList/CheckPaymentStatus/Reducer";
import { confirmAppointmentReducer } from "../Patient/AppointmentPaymentList/CheckPaymentStatus copy/Reducer";
import { trendsApiReducer } from "../TrendApi/Reducer";
import { getInactiveCaseListReducer } from "../GetInactiveCaseList/Reducer";
import { getPatientDashboardLatestDataListReducer } from "../patientDashboardLatestDataList/Reducer";
import { dataListByObservationTypeReducer } from "../patientDashboardDataListByObservationType/Reducer";
import { getLastModifiedPatientListReducer } from "../LastModifiedPatientList/Reducer";
import { adminLoginReducer } from "../logIn/Reducer";
import { jwtAuthTokenReducer } from "../jwtAdminLogIn/Reducer";
import { adminLoginDetailsReducer } from "../jwtAdminLogIn/adminLoginDetails/Reducer";
import { getViewOrganizationStaffListReducer } from "../ViewOrganizationStaffList/Reducer";
import { getAllUserListReducer } from "../ShowAllUserList/Reducer";

import { getProviderOrgDetailsReducer } from "../ProviderOrganizationDetails/Reducer";
import { addDoctorReducer } from "../AddDoctorApi/Reducer";
import { doctorActiveNoteListReducer } from "../Notes/DoctorActiveNote/Reducer";
import { doctorInactiveNoteListReducer } from "../Notes/DoctorInactiveNote/Reducer";
import { createInstantMeetingReducer } from "../CreateInstantMeeting/Reducer";
import { scheduleMeetingReducer } from "../CreateScheduleMeeting/Reducer";
import { getStepsDetailsReducer } from "../FilePreparation/GetSteps/Reducer";
import { getDoctorAppointmentListReducer } from "../Doctor/GetDoctorAppointmentList/Reducer";
import { opinionTypeReducer } from "../ConsultationOption/OpinionType/Reducer";
import { secondSecondOpinionTypeReducer } from "../ConsultationOption/SecondOpinionType/Reducer";
import { consultationTypeReducer } from "../ConsultationOption/ConsultationType/Reducer";
import { editCaseReducer } from "../ConsultationOption/EditCase/Reducer";
import { addCaseReducer } from "../ConsultationOption/AddCase/Reducer";
import { responseTimeReducer } from "../ConsultationOption/ResponseTimeType/Reducer";

const Reducer = combineReducers({
  apiAuthToken: jwtAuthTokenReducer,
  adminLoginDetailsRes: adminLoginDetailsReducer,
  doctorValue: getDoctorReducer,
  doctorRequestValue: getOnboardingDoctorRequestReducer,
  doctorRejectValue: getOnboardingDoctorRejectReducer,
  doctorIncompleteValue: getOnboardingDoctorIncompleteReducer,
  doctorListValue: getDoctorListReducer,
  patientValue: getPatientReducer,
  taskListValue: getTaskListReducer,
  healthcampListValue: getHealthcampListReducer,
  organizationListValue: getOrganizationListReducer,
  healthcampPatientListValue: getHealthcampPatientReducer,
  alltaskListValue: getAllTaskListReducer,
  allinactivetasklist: getAllInactiveTaskListReducer,
  questionListValue: getQuestionListReducer,
  caseValue: getCaseReducer,
  allcaseValue: getAllCaseReducer,
  questionValue: getQuestionReducer,
  ccValue: getCCReducer,
  ccAddVlaue: addCCReducer,
  deleteDocumentValue: deleteDocumentReducer,
  deleteAppointment: appointmentDeactivateReducer,
  allPatientTeamListValue: getpatientTeamListReducer,
  allPaymentListValue: adminPaymentReducer,
  adminPatientQueryListValue: patientQuriesListReducer,
  adminDoctorQueryListValue: doctorQuriesListReducer,
  activeCorporateListValue: getActiveCorporateListReducer,
  inactiveCorporateListValue: getInactiveCorporateListReducer,
  activeCorporatePatientValue: getActiveCorporatePatientListReducer,
  inactiveCorporatePatientValue: getInactiveCorporatePatientListReducer,
  patientDataPointList: getPatientDataPointListReducer,
  doctorOnboardingStatus: doctorsOnboardingImcompleteReducer,
  stepAndSection: stepandSectionListReducer,
  addpastMedical: addPastMedicalReducer,
  getPastMedical: getPastMedicalReducer,
  deletePastMedical: deletePastMedicalReducer,
  addSymptom: symptomAddReducer,
  symptomList: symptomListReducer,
  importfromphone: importFromPhoneReducer,
  importPhoneDataValue: importCallReducer,
  findUserValue: findUserReducer,
  executiveList: executiveListReducer,
  assignExecutive: assignExecutiveReducer,
  previewList: previewListReducer,
  templateList: templateListReducer,
  acceptOrRejectBookingAppointmentValue: acceptorRejectBookingReducer,
  healthandIllness: historyandIllnessReducer,
  addHealthAndIllness: addhistoryandIllnessReducer,
  getIdentify: getIdentifyReducer,
  addIdentify: addIdentifyReducer,
  deleteIdentify: deleteIdentifyReducer,
  getInsurance: getInsuranceReducer,
  addInsurance: addInsuranceReducer,
  opinionTypeValue: opinionTypeReducer,
  secondOpinionValue: secondSecondOpinionTypeReducer,
  consultationTypeValue: consultationTypeReducer,
  responseTimeValue: responseTimeReducer,
  addCase: addCaseReducer,
  editPatientCase: editCaseReducer,
  getPatientCaseDetails: caseDetailsReducer,
  corporatePackageTogglevalue: getCorporatePackageToggleReducer,
  corporatePackageValue: getCorporatePackageListReducer,
  patientTeamPatientListvalue: getPatientListReducer,
  patientteamPatientPaymentValue: getCorporatePackagePaymentListReducer,
  addDoctorSummary: addSummary,
  doctorContactDetails: contactDetail,
  workSchedule: getDoctorWorkScheduleListReducer,
  deleteSchedule: deleteScheduleReducer,
  addWorkSchedule: addScheduleReducer,
  appointment: doctorAppointment,
  doctorDetails: getDoctorDetailsByIdReducer,
  weekdayList: getWeekDayListReducer,
  addDoctorvalue: addDoctorReducer,
  drApproveRejectValue: drApproveOrReject,
  telehealthMeetingValue: getTelehealthMeetingReducer,
  executiveDropdownListvalue: executiveDropDownListReducer,
  assignExecutiveValues: assigntaskToExecutiveReducer,
  taskCloseValues: taskCloseReducer,
  executiveTaskList: getExecutiveTaskListReducer,
  executiveListValue: getExecutiveListReducer,
  addRequestAppointment: addRequestAppointmentReducer,
  getRequestAppointmentDetails: requestAppointmentDetailsReducer,
  deleteExecutive: deleteExecutiveReducer,
  addExecutive: addExecutiveReducer,
  editExecutive: editExecutiveReducer,
  appointmentPatientTeamValue: appointmentPatientTeamListReducer,
  addPatientTeamValue: addPatientTeamReducer,
  doctorTeamTypeListValue: getDoctorTeamTypeListReducer,
  caseStatusList: getCaseStatusListReducer,
  addStatus: updatePatientCaseStatusReducer,
  getPatientCaseStatus: getPatientCaseDetailsByIdReducer,
  changeDrNotesStatusValue: changeNoteStatusForDrReducer,
  addNotesForDrValue: addNotesForDrReducer,
  notesForDrvalues: NotesForDrReducer,
  changeDrNoteStatusValue: changeDrNoteStatus,
  addDrNote: addDrNotes,
  getDrNote: getDrNoteReducer,
  patientCaseNoteListRes: getPatientCaseNoteListReducer,
  bookingValue: acceptOrRejectBookingAppointment,
  taskCloseValue: taskCloseByAdmiin,
  updateDoctorDetails: updateDoctorContactDetailsReducer,
  createOrScheduleMeetingValue: createOrScheduleReducer,
  deletetelehealthMeetingValue: deleteMeetingReducer,
  askQuestionToPatientValue: askQuestionReducer,
  doctorListForcasevalue: doctorListForCaseReducer,
  meetingListValue: meetingListReducer,
  questionListValueUnderCase: questionListReducer,
  adminQuestionAnswerValue: addAnswer,
  verifyQuestionValue: questionverifyReducer,
  patientTeamValue: getPatientTeamListReducer,
  addPatientInCorporatePackagevalue: addPatientInPackageReducer,
  genderListvalue: gettGenderDropdownListReducer,
  relationshipListValue: gettRelationshipDropdownListReducer,
  bloodGroupListvalue: getBloodGroupDropdownListReducer,
  maritalStatusValue: getMaritalStatusDropdownListReducer,
  sexualOrientationValue: getSexualorientationDropdownListReducer,
  languagelistValue: getLanguageDropdownListReducer,
  addOrUpdateHealthRecordValue: addOrupdateHealthRecordReducer,
  gethealthRecordvalue: getHealthRecordReducer,
  getPatientImportConfigListConfigValue: getPatientDataImportConfigListReducer,
  addEditImportConfigValue: addOrEditPatientDataImportConfigReducer,
  getpatientDataListValue: getPatientImportListReducer,
  deleteCaseValue: deletePatientCaseReducer,
  opinion: getOpinion,
  deleteOpinion: deleteOpinion,
  addPatientteamByAdminvalue: addPatientteamByAdminReducer,
  verifyPaymentvalue: statusPaymentReducer,
  addDemographics: addDemographicsReducer,
  getDemographicsDetailsByPtId: getDemographicsDetailsByPtIdReducer,
  getPatientRelationshipList: getRelationshipListReducer,
  getPatientBloodGroupList: getBloodGroupListReducer,
  getPatientGenderList: getGenderListReducer,
  getPatientLanguageList: getLanguageListReducer,
  getPatientMaritalList: getMaritalListListReducer,
  getPatientSexualOrientation: getSexualOrientationListReducer,
  updateDemographicsDetails: updateDemographicsDetailsByPatientIdReducer,
  imageUploading: imageUploadReducer,
  logOutRes: logOutReducer,
  govtIdProofList: govtIDProofReducer,
  patientSearchListValue: patientSearchReducer,
  addMasterImport: addMasterConfigReducer,
  getPatientImportConfigList: getPatientImportConfigListReducer,
  addPatientImportConfig: addPatientImportConfigReducer,
  editImportConfig: editPatientImportConfigReducer,
  editPatientDataPoint: UpdatePatientDataPointReducer,
  showHistory: simpleHistoryReducer,
  getPatientDataDetailsRes: getPatientDataDetailsListReducer,
  addPointDataDetailsRes: addPointDataDetailsReducer,
  updatePatientDataDetailsRes: updatePatientDataDetailsReducer,
  addNoteRes: AddNoteReducer,
  updateNoteRes: updateNoteReducer,
  updateDoctorDetailsRes: updateDoctorDetailsReducer,
  getDoctorContactDetailsRes: getDoctorContactDetailsReducer,
  allApptList: allappointmentListReducer,
  extractObservationByDetailsIdValue: extractObservationByDetailsIdReducer,
  extractObservationByPatientIdValue: extractObservationByPatientIdReducer,
  getExtractObservationValue: GetExtractObservationByPatientIdReducer,
  getPatientDetailsById: getPatientDetailsByIdReducer,
  reviewNeeded: reviewNeededReducer,
  patientQuestionnaireListRes: getQuestionnaireListReducer,
  patientDataListRes: getPatientDataListReducer,
  deletePatientDataByIdRes: deletePatientDataByIdReducer,
  addPatientDataListRes: addPatientDataReducer,
  searchPatientByHealthCampIdValue: patientSearchByHealthCampIdReducer,
  patientSearchByOrganizationIdValue: patientSearchByOrganizationIdReducer,
  organizationListMappedWithHealthCampIdValue: organizationListMappedWithHealthCampReducer,
  getHealthCampByOrganizationList: getHealthCampByOrganizationListReducer,
  getDoctorTeamTypeForCaseRes: getDoctorTeamTypeForCaseReducer,
  editDoctorTeamTypeRes: editDoctorTeamTypeReducer,
  getDoctorCategoryListRes: getDoctorCategoryListReducer,
  getDoctorListByCategoryRes: getDoctorListByCategoryReducer,
  getDoctorListForCaseRes: getDoctorListForCaseReducer,
  deleteDoctorForCaseRes: deleteDoctorForCaseReducer,
  addDoctorForCaseRes: addDoctorForCaseReducer,
  patientNoteListRes: getPatientNoteListReducer,
  getHealthCampDropDownListValue: getHealthCampDropDownListReducer,
  getInactiveCorporatePackageListValue: getInactiveCorporatePackageListReducer,
  getProviderOrganizationListValue: getProviderOrganizationListReducer,
  getProviderOrganizationAdministratorListValue: getProviderOrganizationAdministratorListReducer,
  getAdministratorDetailsValue: getAdministratorDetailsReducer,
  getAppointmentPaymentListRes: getAppointmentPaymentListReducer,
  checkAppointmentPaymentStatusRes: checkAppointmentPaymentStatusReducer,
  confirmAppointmentRes: confirmAppointmentReducer,
  trendsApiRes: trendsApiReducer,
  getInactiveCaseListValue: getInactiveCaseListReducer,
  getPatientDashboardLatestDataListRes: getPatientDashboardLatestDataListReducer,
  dataListByObservationTypeRes: dataListByObservationTypeReducer,
  patientDashboardLatestdataList: getPatientDashboardLatestDataListReducer,
  dataListByObservationType: dataListByObservationTypeReducer,
  getLastModifiedPatientListValue: getLastModifiedPatientListReducer,
  adminLoginRes: adminLoginReducer,
  getWiewOrgStaffListValue: getViewOrganizationStaffListReducer,
  getProviderOrgDetailsValue: getProviderOrgDetailsReducer,
  getAllUserListValue: getAllUserListReducer,
  doctorActiveNoteListValue: doctorActiveNoteListReducer,
  doctorInactiveNoteListValue: doctorInactiveNoteListReducer,
  CreateInstantMeeting: createInstantMeetingReducer,
  scheduleMeeting: scheduleMeetingReducer,
  getStepsDetailsRes: getStepsDetailsReducer,
  getDoctorAppointmentListValue: getDoctorAppointmentListReducer,
});

export default Reducer;
