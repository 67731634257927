import {
  GET_WEEKDAY_LIST_FAIL,
  GET_WEEKDAY_LIST_LOADING,
  GET_WEEKDAY_LIST_SUCCESS,
  GET_WEEKDAY_LIST_UPDATE_API_MSG,
  GET_WEEKDAY_LIST_UPDATE_API_RES,
  GetWeekDayListActionTypes,
} from "./ActionTypes";
import { GetWeekDayListRes } from "./Model";

export const getWeekDayListLoadingAction = (loading: boolean): GetWeekDayListActionTypes => {
  return {
    type: GET_WEEKDAY_LIST_LOADING,
    loading: loading,
  };
};

export const getWeekDayListSuccessAction = (
  getWeekDayListResponse: GetWeekDayListRes,
  successMsg: string,
): GetWeekDayListActionTypes => {
  return {
    type: GET_WEEKDAY_LIST_SUCCESS,
    payload: getWeekDayListResponse,
    successMsg: successMsg,
  };
};

export const getWeekDayListErrorAction = (
  getWeekDayListResponse: GetWeekDayListRes,
  errMsg: string,
  status: number,
): GetWeekDayListActionTypes => {
  return {
    type: GET_WEEKDAY_LIST_FAIL,
    payload: getWeekDayListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getWeekDayListUpdateAPIMsgAction = (
  getWeekDayListResponse: GetWeekDayListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetWeekDayListActionTypes => {
  return {
    type: GET_WEEKDAY_LIST_UPDATE_API_MSG,
    payload: getWeekDayListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getWeekDayListAPIResClearAction = (
  getWeekDayListResponse: GetWeekDayListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetWeekDayListActionTypes => {
  return {
    type: GET_WEEKDAY_LIST_UPDATE_API_RES,
    payload: getWeekDayListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
