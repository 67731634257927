import { CorporatePackageList } from "./Model";

export const CORPORATE_LIST = "CORPORATE_LIST";
export const CORPORATE_LIST_LOADING = "CORPORATE_LIST_LOADING";
export const CORPORATE_LIST_SUCCESS = "CORPORATE_LIST_SUCCESS";
export const CORPORATE_LIST_FAIL = "CORPORATE_LIST_FAIL";

// Get Active Corporate Package List
export interface GetCorporatePackageListStateType {
  corporatePackageListValue: CorporatePackageList[];
  loading: boolean;
}
interface CorporatePackageListActionType {
  type: typeof CORPORATE_LIST;
  payload: CorporatePackageList[];
}
export interface CorporatePackageListLoading {
  type: typeof CORPORATE_LIST_LOADING;
}
export interface CorporatePackageListSuccess {
  type: typeof CORPORATE_LIST_SUCCESS;
  payload: CorporatePackageList[];
}
export interface CorporatePackageListFail {
  type: typeof CORPORATE_LIST_FAIL;
}
export type CorporatePackagesTypes =
  | CorporatePackageListActionType
  | CorporatePackageListLoading
  | CorporatePackageListSuccess
  | CorporatePackageListFail;
