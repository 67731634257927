import {
  AddChiefComplaintTypes,
  ADD_CC,
  CC_ADD_LIST_FAIL,
  CC_ADD_LIST_LOADING,
  CC_ADD_LIST_SUCCESS,
  CC_LIST_FAIL,
  CC_LIST_LOADING,
  CC_LIST_SUCCESS,
  ChiefComplaintGetActionTypes,
  DeleteCaseTypes,
  DeleteDocumentTypes,
  DELETE_CASE_FAIL,
  DELETE_CASE_LOADING,
  DELETE_CASE_SUCCESS,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_LIST_FAIL,
  DELETE_DOCUMENT_LIST_LOADING,
  DELETE_DOCUMENT_LIST_SUCCESS,
  QuestionGetActionTypes,
  QUESTION_LIST_FAIL,
  QUESTION_LIST_LOADING,
  QUESTION_LIST_SUCCESS,
  StepsGetActionTypes,
  STEPS_LIST_FAIL,
  STEPS_LIST_LOADING,
  STEPS_LIST_SUCCESS,
} from "./ActionTypes";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import axios from "axios";
import {
  AddChiefComplaintAction,
  deleteCaseAction,
  DeleteDocumentAction,
  getChiefComplaintAction,
  getQuestionAction,
  getStepAction,
} from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

export const getQuestionList = (getChiefComplaintBody: object) => {
  return function (dispatch: Dispatch<QuestionGetActionTypes>) {
    dispatch({
      type: QUESTION_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + "/patientapp/api/patientMedicalData/get_patientQuestionnaireList",
        getChiefComplaintBody,
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data.patientQuestionnaireList;
        dispatch(getQuestionAction(resData));
        dispatch({
          type: QUESTION_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: QUESTION_LIST_FAIL,
        });
      });
  };
};

export const getChiefComplaintList = (getChiefComplaintBody: object) => {
  return function (dispatch: Dispatch<ChiefComplaintGetActionTypes>) {
    dispatch({
      type: CC_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + "/patientapp/api/patientMedicalData/get_patientDetailsList",
        getChiefComplaintBody,
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data.patientDetailList;
        dispatch(getChiefComplaintAction(resData));
        dispatch({
          type: CC_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: CC_LIST_FAIL,
        });
      });
  };
};

export const addChiefComplaint = (state = {}) => {
  return function (dispatch: Dispatch<AddChiefComplaintTypes>) {
    dispatch({
      type: CC_ADD_LIST_LOADING,
    });
    dispatch(AddChiefComplaintAction(ADD_CC));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + "/patientapp/api/patientMedicalData/add_patientDetails", state, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          dispatch({
            type: CC_ADD_LIST_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: CC_ADD_LIST_FAIL,
        });
      });
  };
};

export const deleteFilePreparationDocument = (state = {}) => {
  return function (dispatch: Dispatch<DeleteDocumentTypes>) {
    dispatch({
      type: DELETE_DOCUMENT_LIST_LOADING,
    });
    dispatch(DeleteDocumentAction(DELETE_DOCUMENT));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + "/patientapp/api/patientMedicalData/delete_patientDetails", state, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          dispatch({
            type: DELETE_DOCUMENT_LIST_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DOCUMENT_LIST_FAIL,
        });
      });
  };
};

export const getSteps = () => {
  return function (dispatch: Dispatch<StepsGetActionTypes>) {
    dispatch({
      type: STEPS_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.baseUrl + "/secondopinionapp/api/patientcaseui/steps_and_sections", {
        headers: headers,
      })
      .then((res) => {
        let resData = res.data.SecondOpinionUi;
        dispatch(getStepAction(resData));
        dispatch({
          type: STEPS_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: STEPS_LIST_FAIL,
        });
      });
  };
};

export const deletePatientCaseAPI = (body: object) => {
  return function (dispatch: Dispatch<DeleteCaseTypes>) {
    dispatch({
      type: DELETE_CASE_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.baseUrl + "/patientapp/api/patientMedicalData/deactivate_patient_case", body, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          let message = result.data.message;
          let status = result.data.status;
          dispatch(deleteCaseAction(message, status));
          dispatch({
            type: DELETE_CASE_SUCCESS,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: DELETE_CASE_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
