import {
  UPDATE_DOCTOR_CONTACT_DETAILS_FAIL,
  UPDATE_DOCTOR_CONTACT_DETAILS_LOADING,
  UPDATE_DOCTOR_CONTACT_DETAILS_SUCCESS,
  UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG,
  UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES,
  UpdateDoctorContactDetailsActionTypes,
} from "./ActionTypes";
import { UpdateDoctorContactDetailsRes } from "./Model";

export const updateDoctorContactDetailsLoadingAction = (
  loading: boolean,
): UpdateDoctorContactDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_CONTACT_DETAILS_LOADING,
    loading: loading,
  };
};

export const updateDoctorContactDetailsSuccessAction = (
  updateDoctorContactDetailsResponse: UpdateDoctorContactDetailsRes,
  successMsg: string,
): UpdateDoctorContactDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_CONTACT_DETAILS_SUCCESS,
    payload: updateDoctorContactDetailsResponse,
    successMsg: successMsg,
  };
};

export const updateDoctorContactDetailsErrorAction = (
  updateDoctorContactDetailsResponse: UpdateDoctorContactDetailsRes,
  errMsg: string,
  status: number,
): UpdateDoctorContactDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_CONTACT_DETAILS_FAIL,
    payload: updateDoctorContactDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updateDoctorContactDetailsUpdateAPIMsgAction = (
  updateDoctorContactDetailsResponse: UpdateDoctorContactDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): UpdateDoctorContactDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG,
    payload: updateDoctorContactDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const updateDoctorContactDetailsAPIResClearAction = (
  updateDoctorContactDetailsResponse: UpdateDoctorContactDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): UpdateDoctorContactDetailsActionTypes => {
  return {
    type: UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES,
    payload: updateDoctorContactDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
