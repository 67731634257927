import axios from "axios";
import Url from "../../ApiConfig";
import { Dispatch } from "react";
import {
  assignTasktoExecutiveAction,
  executiveDropdownListAction,
  executiveTaskListAction,
  taskCloseAction,
} from "./Actions";
import {
  AssignTaskToExecutiveTypes,
  ASSIGN_EXECUTIVE_FAIL,
  ASSIGN_EXECUTIVE_LOADING,
  ASSIGN_EXECUTIVE_SUCCESS,
  ExecutiveDropdownListTypes,
  ExecutiveTaskListTypes,
  EXECUTIVE_DROPDOWN_LIST_FAIL,
  EXECUTIVE_DROPDOWN_LIST_LOADING,
  EXECUTIVE_DROPDOWN_LIST_SUCCESS,
  EXECUTIVE_TASK_LIST_FAIL,
  EXECUTIVE_TASK_LIST_LOADING,
  EXECUTIVE_TASK_LIST_SUCCESS,
  TaskCloseTypes,
  TASK_CLOSE_FAIL,
  TASK_CLOSE_LOADING,
  TASK_CLOSE_SUCCESS,
} from "./ActionTypes";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

// Executive Dropdown list
export const getExecutiveDropdownList = () => {
  return function (dispatch: Dispatch<ExecutiveDropdownListTypes>) {
    dispatch({
      type: EXECUTIVE_DROPDOWN_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/adminapp/api/executives/executive_list_dropdown`, { headers: headers })
      .then((result) => {
        let executiveList = result.data.executiveList;
        let message = result.data.message;
        let status = result.data.status;
        dispatch(executiveDropdownListAction(executiveList, message, status));
        if (result) {
          dispatch({
            type: EXECUTIVE_DROPDOWN_LIST_SUCCESS,
            payload: executiveList,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: EXECUTIVE_DROPDOWN_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};

// assign task to executive
export const assignTaskToExecutive = (body: object) => {
  return function (dispatch: Dispatch<AssignTaskToExecutiveTypes>) {
    dispatch({
      type: ASSIGN_EXECUTIVE_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/adminActions/assign_task_to_admin`, body, {
        headers: headers,
      })
      .then((result) => {
        let message = result.data.message;
        let status = result.data.status;
        dispatch(assignTasktoExecutiveAction(message, status));
        if (result) {
          dispatch({
            type: ASSIGN_EXECUTIVE_SUCCESS,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: ASSIGN_EXECUTIVE_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// Task Close
export const taskClose = (body: object) => {
  return function (dispatch: Dispatch<TaskCloseTypes>) {
    dispatch({
      type: TASK_CLOSE_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/adminTasks/admin_task_close`, body, { headers: headers })
      .then((result) => {
        let message = result.data.message;
        let status = result.data.status;
        dispatch(taskCloseAction(message, status));
        if (result) {
          dispatch({
            type: TASK_CLOSE_SUCCESS,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: TASK_CLOSE_FAIL,
          message: message,
          status: status,
        });
      });
  };
};

// Executive Dropdown list
export const getExecutiveTaskList = (state: {}) => {
  return function (dispatch: Dispatch<ExecutiveTaskListTypes>) {
    dispatch({
      type: EXECUTIVE_TASK_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/executives/executive_task_list_by_id`, state, {
        headers: headers,
      })
      .then((result) => {
        let executiveList = result.data.taskDataList;
        dispatch(executiveTaskListAction(executiveList));
        if (result) {
          dispatch({
            type: EXECUTIVE_TASK_LIST_SUCCESS,
            payload: executiveList,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EXECUTIVE_TASK_LIST_FAIL,
        });
      });
  };
};
