import { ImportCallResponse } from "./Model";
export const IMPORT_CALL_LOADING = "IMPORT_CALL_LOADING";
export const IMPORT_CALL_SUCCESS = "IMPORT_CALL_SUCCESS";
export const IMPORT_CALL_FAIL = "IMPORT_CALL_FAIL";
export const IMPORT_CALL_DATA = "IMPORT_CALL_DATA";
export const IMPORT_CALL_UPDATE_API_MSG = "IMPORT_CALL_UPDATE_API_MSG";
export const IMPORT_CALL_UPDATE_API_RES = "IMPORT_CALL_UPDATE_API_RES";

export interface ImportCallState {
  importCallRes: ImportCallResponse;
  loading: boolean;
  message: string;
  status: number;
}
export interface ImportCallLoading {
  type: typeof IMPORT_CALL_LOADING;
  loading: boolean;
}
export interface ImportCallSuccess {
  type: typeof IMPORT_CALL_SUCCESS;
  payload: ImportCallResponse;
}
export interface ImportCallFail {
  type: typeof IMPORT_CALL_FAIL;
  payload: ImportCallResponse;
  message: string;
  status: number;
}
export interface ImportCallUpdateAPIMsg {
  type: typeof IMPORT_CALL_UPDATE_API_MSG;
  payload: ImportCallResponse;
  message: string;
  status: number;
}
export interface ImportCallUpdateAPIRes {
  type: typeof IMPORT_CALL_UPDATE_API_RES;
  payload: ImportCallResponse;
  message: string;
  status: number;
}
interface ImportCallAction {
  type: typeof IMPORT_CALL_DATA;
  payload: ImportCallResponse;
}

export type ImportCallActionTypes =
  | ImportCallAction
  | ImportCallLoading
  | ImportCallSuccess
  | ImportCallFail
  | ImportCallUpdateAPIMsg
  | ImportCallUpdateAPIRes;
