import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import RefreshIcon from "@mui/icons-material/Refresh";
import AdminLoader from "./AdminLoader";
import CloseIcon from "@mui/icons-material/Close";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import PageLayout from "./Layout/PageLayout";
import { PatientQuriesListApi } from "../redux/PatientQuriesList /API";
import { DoctorHelpQuery } from "../redux/DoctorQuriesList/Model";
import { t } from "i18next";
export default function Patient() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const [descriptionTextShow, setDescriptionTextShow] = useState(false);
  const closeModal = () => {
    setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
  };

  const [descriptionModalActive, setDescriptionModalActive] = useState(false);
  const viewDescription = (selectedResponse: any, descriptionText: any) => {
    setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
    setDescriptionTextShow(descriptionText);
    console.log(selectedResponse);
  };

  // call Store
  const AdminPatientQueryList = useSelector((state: AppState) => state.adminPatientQueryListValue);

  const [patientQueryValues, setPatientQueryValues] = useState([] as any[]);

  const patientDataQueries: GridColDef[] = [
    {
      field: "number",
      headerName: `${t("accountdetail.phoneno", { ns: ["home"] })!}`,
      width: 250,
    },
    {
      field: "description",
      headerName: `${t("patientlistpage.des", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("common.view", { ns: ["home"] })!}`,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => {
            viewDescription(params.row.id, params.row.description);
          }}
        >
          {t("common.view", { ns: ["home"] })!}
        </Button>
      ),
      width: 250,
    },
  ];
  const reloadPatientQueryList = () => {
    dispatch(PatientQuriesListApi());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(PatientQuriesListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (AdminPatientQueryList !== undefined) {
      let imprtList = AdminPatientQueryList?.patientQueriesRes?.patientHelpQueries?.map(
        (element: DoctorHelpQuery) => ({
          id: element.id,
          number: element?.callbackNumbers !== null ? element?.callbackNumbers[0].number : "",
          description: element?.description !== "" ? element?.description : "",
        }),
      );
      setPatientQueryValues(imprtList);
    } else setPatientQueryValues([] as any[]);
  }, [AdminPatientQueryList]);

  // Query List table
  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => reloadPatientQueryList()}
              >
                {t("common.reload", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {patientQueryValues && patientQueryValues.length ? (
          <DataGrid
            rows={patientQueryValues}
            columns={patientDataQueries}
            getRowId={(row) => row.id}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  // Query Description Modal
  const descriptionModalBody = (
    <Dialog open={descriptionModalActive} onClose={closeModal} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">{t("patientlistpage.des", { ns: ["home"] })!}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>{descriptionTextShow}</DialogContent>
      <Divider />
    </Dialog>
  );

  return (
    <Box>
      {AdminPatientQueryList.loading ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle title={t("common.patientqueries", { ns: ["home"] })} />
        {actualPageMarkup}
      </PageLayout>
      {descriptionModalBody}
    </Box>
  );
}
