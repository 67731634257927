import {
  SecondOpinionTypeActionTypes,
  SECOND_OPINION_TYPE_FAIL,
  SECOND_OPINION_TYPE_LOADING,
  SECOND_OPINION_TYPE_SUCCESS,
  SecondOpinionTypeState,
  SECOND_OPINION_TYPE_API_MSG,
  SECOND_OPINION_TYPE_API_RES,
} from "./ActionTypes";
import { SecondOpinionListResponse } from "./Model";

const initialStateEditPosts: SecondOpinionTypeState = {
  loading: false,
  secondSecondOpinionTypeResponse: {} as SecondOpinionListResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const secondSecondOpinionTypeReducer = (
  state = initialStateEditPosts,
  action: SecondOpinionTypeActionTypes,
): SecondOpinionTypeState => {
  switch (action.type) {
    case SECOND_OPINION_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SECOND_OPINION_TYPE_SUCCESS:
      return {
        ...state,
        secondSecondOpinionTypeResponse: action.payload,
        successMsg: action.successMsg,
      };
    case SECOND_OPINION_TYPE_FAIL:
      return {
        ...state,
        secondSecondOpinionTypeResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SECOND_OPINION_TYPE_API_MSG:
      return {
        ...state,
        secondSecondOpinionTypeResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SECOND_OPINION_TYPE_API_RES:
      return {
        ...state,
        secondSecondOpinionTypeResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
