import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { GetDemographicsDetailsByPtIdActionTypes } from "./ActionTypes";
import {
  getDemographicsDetailsByPtIdLoadingAction,
  getDemographicsDetailsByPtIdSuccessAction,
  getDemographicsDetailsByPtIdErrorAction,
  getDemographicsDetailsByPtIdUpdateAPIMsgAction,
  getDemographicsDetailsByPtIdAPIResClearAction,
} from "./Action";
import { GetDemographicsDetailsByPtIdRequest, GetDemographicsDetailsByPtIdRes } from "./Model";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetDemographicsDetailsByPtIdRes;
export const getDemographicsDetailsByPtId = (payload: GetDemographicsDetailsByPtIdRequest) => {
  return function (dispatch: Dispatch<GetDemographicsDetailsByPtIdActionTypes>) {
    dispatch(getDemographicsDetailsByPtIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/patientapp/api/patient/get_patient_demographics_details`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data !== undefined ? res.data : ({} as GetDemographicsDetailsByPtIdRes);
        dispatch(getDemographicsDetailsByPtIdLoadingAction(false));
        dispatch(
          getDemographicsDetailsByPtIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetDemographicsDetailsByPtIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient details has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDemographicsDetailsByPtIdLoadingAction(false));
        dispatch(
          getDemographicsDetailsByPtIdErrorAction(
            {} as GetDemographicsDetailsByPtIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetDemographicsDetailsByPtIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDemographicsDetailsByPtIdActionTypes>) {
    dispatch(
      getDemographicsDetailsByPtIdUpdateAPIMsgAction(
        apiRes as GetDemographicsDetailsByPtIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetDemographicsDetailsByPtIdAPIRes = () => {
  return function (dispatch: Dispatch<GetDemographicsDetailsByPtIdActionTypes>) {
    dispatch(
      getDemographicsDetailsByPtIdAPIResClearAction(
        {} as GetDemographicsDetailsByPtIdRes,
        "",
        "",
        0,
      ),
    );
  };
};
