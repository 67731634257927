import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { DoctorActiveListActionTypes } from "./ActionTypes";
import { DoctorActiveListModel, DoctorActiveListResponse } from "./Model";
import {
  doctorActiveListAPIResClearAction,
  doctorActiveListErrorAction,
  doctorActiveListLoadingAction,
  doctorActiveListSuccessAction,
  doctorActiveListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as DoctorActiveListResponse;
export const doctorActiveListApi = (payload: DoctorActiveListModel) => {
  return function (dispatch: Dispatch<DoctorActiveListActionTypes>) {
    dispatch(doctorActiveListLoadingAction(true));
    axios
      .post(Url.baseUrl + `/erpapp/api/adminNote/get_admin_notes_by_doctor`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(doctorActiveListLoadingAction(false));
        dispatch(
          doctorActiveListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. Active Note List Not Available",
          ),
        );
      })
      .catch((error) => {
        dispatch(doctorActiveListLoadingAction(false));
        dispatch(
          doctorActiveListErrorAction(
            {} as DoctorActiveListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDoctorActiveNoteListAPIResMsg = () => {
  return function (dispatch: Dispatch<DoctorActiveListActionTypes>) {
    dispatch(doctorActiveListAPIMsgAction(apiRes as DoctorActiveListResponse, "", "", 0));
  };
};

export const clearDoctorActiveNoteListAPIRes = () => {
  return function (dispatch: Dispatch<DoctorActiveListActionTypes>) {
    dispatch(doctorActiveListAPIResClearAction({} as DoctorActiveListResponse, "", "", 0));
  };
};
