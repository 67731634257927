import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { Button, Card, CardContent, CardHeader, Grid, Box, AlertColor } from "@mui/material";
import {
  // RequestAppointmentDataAPIResMsg,
  addBookAppointment,
  clearRequestAppointmentDataAPIRes,
} from "../../../../redux/Patient/PatientRequestAppointment/API";
import MWSnackbar from "../../../../component/MWSnackbar";
import { requestAppointmentDetails } from "../../../../redux/Patient/RequestAppointment/ApiCall";
import { t } from "i18next";

type Props = {
  caseId?: string | any;
};
export default function RequestPatientAppointment({ caseId }: Props) {
  const dispatch = useDispatch();
  const [requestAppointmentSuccessAPIMsg, setRequestAppointmentSuccessAPIMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const [requestAppointmentErrorAPIMsg, setRequestAppointmentErrorAPIMsg] = useState("");
  console.log(requestAppointmentErrorAPIMsg);
  const [errorMsgType, setErrorMsgType] = useState<AlertColor>("error");
  console.log(errorMsgType);
  const [addPhoneNumber, setPhoneNumber] = useState("");
  const bookAppointmentBody = {
    caseId: caseId,
    phoneNumberForPatientCase: addPhoneNumber,
  };
  const selectionTypeBody = {
    caseId: caseId,
  };
  let addRequestAppointmentVal = useSelector((state: AppState) => state.addRequestAppointment);
  useEffect(() => {
    if (addRequestAppointmentVal?.successMsg !== "") {
      setRequestAppointmentSuccessAPIMsg(addRequestAppointmentVal?.successMsg);
      setMsgType("success");
      dispatch(requestAppointmentDetails(selectionTypeBody));
    } else if (addRequestAppointmentVal?.errorMsg !== "") {
      setRequestAppointmentErrorAPIMsg(addRequestAppointmentVal?.errorMsg);
      setErrorMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRequestAppointmentVal]);

  // RequestAppointment Api Value from store
  let requestAppointmentListFromAPI = useSelector(
    (state: AppState) => state.getRequestAppointmentDetails.getAppointmentListRes,
  );

  useEffect(() => {
    if (requestAppointmentListFromAPI?.phoneNumberForPatientCase !== undefined) {
      if (requestAppointmentListFromAPI?.phoneNumberForPatientCase !== null) {
        if (requestAppointmentListFromAPI?.phoneNumberForPatientCase !== "") {
          setPhoneNumber(requestAppointmentListFromAPI?.phoneNumberForPatientCase);
        } else setPhoneNumber("");
      } else setPhoneNumber("");
    } else setPhoneNumber("");
  }, [requestAppointmentListFromAPI]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearRequestAppointmentDataAPIRes());
    }
  };
  // const closeBanner = () => {
  //   dispatch(RequestAppointmentDataAPIResMsg());
  //   setRequestAppointmentErrorAPIMsg("");
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>{t("consultOpPage.reqApp", { ns: ["home"] })!}</Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={addRequestAppointmentVal?.loading}
                  onClick={() => dispatch(addBookAppointment(bookAppointmentBody))}
                  sx={{ textTransform: "none" }}
                >
                  {t("consultOpPage.reqApp", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {/* <TextField
            label="Phone Number"
            type="number"
            value={addPhoneNumber}
            disabled={addRequestAppointmentVal?.loading}
            onChange={(event) => setPhoneNumber(event.target.value)}
            placeholder="Enter Phone Number"
            fullWidth
            id="emailID"
            size="small"
          />
          {requestAppointmentErrorAPIMsg !== "" ? (
            <Box py={2}>
              <Alert severity={errorMsgType} onClose={closeBanner}>
                {requestAppointmentErrorAPIMsg}
              </Alert>
            </Box>
          ) : null} */}
        </CardContent>
      </Card>
      <MWSnackbar
        msg={requestAppointmentSuccessAPIMsg}
        type={msgType}
        alertClose={checkAlertClose}
      />
    </Box>
  );
}
