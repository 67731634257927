import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { GetDoctorWorkScheduleListActionTypes } from "./ActionTypes";
import { GetDoctorWorkScheduleListBody, GetDoctorWorkScheduleListRes } from "./Model";
import {
  getDoctorWorkScheduleListAPIResClearAction,
  getDoctorWorkScheduleListErrorAction,
  getDoctorWorkScheduleListLoadingAction,
  getDoctorWorkScheduleListSuccessAction,
  getDoctorWorkScheduleListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as GetDoctorWorkScheduleListRes;
export const getDoctorWorkScheduleList = (payload: GetDoctorWorkScheduleListBody) => {
  return function (dispatch: Dispatch<GetDoctorWorkScheduleListActionTypes>) {
    dispatch(getDoctorWorkScheduleListLoadingAction(true));
    axios
      .post(Url.baseUrl + `/appointmentapp/api/workSchedule/get_workScheduleList`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorWorkScheduleListLoadingAction(false));
        dispatch(
          getDoctorWorkScheduleListSuccessAction(
            res.data,
            "Doctor work schedule details has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDoctorWorkScheduleListLoadingAction(false));
        dispatch(
          getDoctorWorkScheduleListErrorAction(
            {} as GetDoctorWorkScheduleListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateGetDoctorWorkScheduleListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorWorkScheduleListActionTypes>) {
    dispatch(
      getDoctorWorkScheduleListUpdateAPIMsgAction(
        apiRes as GetDoctorWorkScheduleListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearDoctorWorkScheduleListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorWorkScheduleListActionTypes>) {
    dispatch(
      getDoctorWorkScheduleListAPIResClearAction({} as GetDoctorWorkScheduleListRes, "", "", 0),
    );
  };
};
