import { GetDoctorAppointmentListRes } from "./Model";
export const GET_DOCTOR_APPOINTMENT_LIST_LOADING = "GET_DOCTOR_APPOINTMENT_LIST_LOADING";
export const GET_DOCTOR_APPOINTMENT_LIST_SUCCESS = "GET_DOCTOR_APPOINTMENT_LIST_SUCCESS";
export const GET_DOCTOR_APPOINTMENT_LIST_FAIL = "GET_DOCTOR_APPOINTMENT_LIST_FAIL";
export const GET_DOCTOR_APPOINTMENT_LIST_API_MSG = "GET_DOCTOR_APPOINTMENT_LIST_API_MSG";
export const GET_DOCTOR_APPOINTMENT_LIST_UPDATE_API_RES =
  "GET_DOCTOR_APPOINTMENT_LIST_UPDATE_API_RES";

export interface GetDoctorAppointmentListState {
  getDoctorAppointmentListRes: GetDoctorAppointmentListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAppointmentListLoading {
  type: typeof GET_DOCTOR_APPOINTMENT_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorAppointmentListSuccess {
  type: typeof GET_DOCTOR_APPOINTMENT_LIST_SUCCESS;
  payload: GetDoctorAppointmentListRes;
  successMsg: string;
}
export interface GetDoctorAppointmentListFail {
  type: typeof GET_DOCTOR_APPOINTMENT_LIST_FAIL;
  payload: GetDoctorAppointmentListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAppointmentListUpdateAPIMsg {
  type: typeof GET_DOCTOR_APPOINTMENT_LIST_API_MSG;
  payload: GetDoctorAppointmentListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAppointmentListUpdateAPIRes {
  type: typeof GET_DOCTOR_APPOINTMENT_LIST_UPDATE_API_RES;
  payload: GetDoctorAppointmentListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorAppointmentListActionTypes =
  | GetDoctorAppointmentListLoading
  | GetDoctorAppointmentListSuccess
  | GetDoctorAppointmentListFail
  | GetDoctorAppointmentListUpdateAPIMsg
  | GetDoctorAppointmentListUpdateAPIRes;
