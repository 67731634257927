import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "./routes/PublicRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import Login from "./pages/Login";
import AdminDashboard from "./pages/AdminDashboard/index";
import DoctorList from "./pages/DoctorList";
import PatientList from "./pages/PatientList";
import NotFound from "./pages/NotFound";
import DoctorsOnboarding from "./pages/DoctorOnboarding/index";
import AppointmenList from "./pages/AppointmentList";
import PatientQueries from "./pages/PatientQueries";
import DoctorQueries from "./pages/DoctorQueries";
import Payment from "./pages/Payment";
import ImportData from "./pages/ImportData/index";
import PatientQueriesDetails from "./pages/PatientQueriesDetails";
import DoctorQueriesDetails from "./pages/DoctorQueriesDetails";
import Task from "./pages/Task/Task";
import TaskDetails from "./pages/Task/TaskDetails";
import SelfTaskDetails from "./pages/SelfTaskDetails";
import QuestionAnswer from "./pages/QuestionAnswer/QuestionAnswer";
import CaseDetails from "./pages/Patient/CaseDetails";
import AddHealthRecord from "./pages/Patient/FilePreparation";
import DemographicDetails from "./pages/Patient/FilePreparation/DemographicDetails";
import ExecutiveList from "./pages/ExecutiveList/index";
import DoctorAppointments from "./pages/DoctorAppointments";
import AllCaseList from "./pages/CaseList/AllCaseList";
import ExecutiveTaskDetails from "./pages/ExecutiveTaskDetails";
import DoctorDetails from "./pages/DoctorDetails";
import PatientTeam from "./pages/PatientTeam/index";
import TeleHealth from "./pages/TeleHealth/TeleHealth";
import PatientTeamDetails from "./pages/PatientTeamDetails";
import CorporatePackage from "./pages/CorporatePackage/CorporatePackage";
import ActiveCorporatePackageDetails from "./pages/CorporatePackage/ActiveCorporatePackageDetails";
import CorporatePackageInactivedetails from "./pages/CorporatePackage/CorporatePackageInactivedetails";
import ActivePatientListDetails from "./pages/CorporatePackage/CorporatePatientList/ActivepatientTeamDetails/ActivePatientListDetails";
import InactivePatientDetails from "./pages/CorporatePackage/CorporatePatientList/InActivePatientTeamDetails/InactivePatientDetails";
import PatientNotes from "./pages/Notes/PatientNote/PatientNotes";
import NotesDetails from "./pages/Notes/PatientCaseNotesForDoctor/NotesDetails";
import CaseListExpanded from "./pages/PatientDashboard/CaseList/CaseListExpanded";
import AdminDetails from "./pages/AdminDetails";

function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/signin" component={Login} />
      <PrivateRoute path="/admindetails" component={AdminDetails} />
      <PrivateRoute path="/dashboard" component={AdminDashboard} />
      <PrivateRoute path="/doctors" component={DoctorList} />
      <PrivateRoute path="/patients" component={PatientList} />
      <PrivateRoute path="/allcaselist" component={AllCaseList} />
      <PrivateRoute path="/addhealthrecord/:patientid?" component={AddHealthRecord} />
      <PrivateRoute path="/demographicdetails/:patientid?" component={DemographicDetails} />
      <PrivateRoute path="/casedetails/:patientid?/:caseid?/" component={CaseDetails} />
      <PrivateRoute path="/patient-queries" component={PatientQueries} />
      <PrivateRoute path="/doctor-queries" component={DoctorQueries} />
      <PrivateRoute path="/payment" component={Payment} />
      <PrivateRoute path="/importdata" component={ImportData} />
      <PrivateRoute path="/doctoronboardingrequests" component={DoctorsOnboarding} />
      <PrivateRoute path="/appointmentlist/" component={AppointmenList} />
      <PrivateRoute path="/patient-queries-details/:id?" component={PatientQueriesDetails} />
      <PrivateRoute path="/doctor-queries-details/:id?" component={DoctorQueriesDetails} />
      <PrivateRoute path="/task" component={Task} />
      <PrivateRoute path="/question-answer" component={QuestionAnswer} />
      <PrivateRoute path="/taskdetails/:currentPage?/:rowIndex?" component={TaskDetails} />
      <PrivateRoute path="/selftaskdetails/:rowIndex?" component={SelfTaskDetails} />
      <PrivateRoute path="/executive" component={ExecutiveList} />
      <PrivateRoute path="/doctorapoointment/:doctorId" component={DoctorAppointments} />
      <PrivateRoute path="/executivetaskdetails/:executiveId?" component={ExecutiveTaskDetails} />
      <PrivateRoute path="/doctordetails/:id?" component={DoctorDetails} />
      <PrivateRoute path="/patientteam" component={PatientTeam} />
      <PrivateRoute path="/telehealth" component={TeleHealth} />
      <PrivateRoute
        path="/patientteamdetails/:patientTeamCurrentPage?/:patientDetailsID?"
        component={PatientTeamDetails}
      />
      <PrivateRoute path="/corporatepackages" component={CorporatePackage} />
      <PrivateRoute
        path="/activecorporatepackagesdetails/:id?"
        component={ActiveCorporatePackageDetails}
      />
      <PrivateRoute
        path="/inactivecorporatepackagedetails/:id?"
        component={CorporatePackageInactivedetails}
      />
      <PrivateRoute
        path="/activepatientpaymentdetails/:packageId?/:profileId?"
        component={ActivePatientListDetails}
      />
      <PrivateRoute
        path="/inactivepatientpaymentdetails/:packageId?/:profileId?"
        component={InactivePatientDetails}
      />
      <PrivateRoute path="/patientnotes/:profileId?" component={PatientNotes} />
      <PrivateRoute path="/notesdetails/:patientid?/:caseid?/:doctorId?" component={NotesDetails} />
      <PrivateRoute path="/patientcaselist/:patientid" component={CaseListExpanded} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
