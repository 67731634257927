import {
  IMPORT_CALL_DATA,
  IMPORT_CALL_FAIL,
  IMPORT_CALL_LOADING,
  IMPORT_CALL_SUCCESS,
  ImportCallActionTypes,
} from "./ActionTypes";
import { ImportCallResponse } from "./Model";

export const importCallAction = (importCallRes: ImportCallResponse): ImportCallActionTypes => {
  return {
    type: IMPORT_CALL_DATA,
    payload: importCallRes,
  };
};

export const importCallLoadingAction = (loading: boolean): ImportCallActionTypes => {
  return {
    type: IMPORT_CALL_LOADING,
    loading: loading,
  };
};

export const importCallSuccessAction = (
  importCallRes: ImportCallResponse,
): ImportCallActionTypes => {
  return {
    type: IMPORT_CALL_SUCCESS,
    payload: importCallRes,
  };
};

export const importCallErrorAction = (
  importCallRes: ImportCallResponse,
  errMsg: string,
  status: number,
): ImportCallActionTypes => {
  return {
    type: IMPORT_CALL_FAIL,
    payload: importCallRes,
    message: errMsg,
    status: status,
  };
};
export const importCallUpdateAPIMsgAction = (
  importCallRes: ImportCallResponse,
  errMsg: string,
  status: number,
): ImportCallActionTypes => {
  return {
    type: IMPORT_CALL_FAIL,
    payload: importCallRes,
    message: errMsg,
    status: status,
  };
};

export const importCallAPIResClearAction = (
  importCallRes: ImportCallResponse,
  errMsg: string,
  status: number,
): ImportCallActionTypes => {
  return {
    type: IMPORT_CALL_FAIL,
    payload: importCallRes,
    message: errMsg,
    status: status,
  };
};
