import { CorporatePackagesTypes, CORPORATE_LIST } from "./ActionTypes";
import { CorporatePackageList } from "./Model";

export const getCorporateListActions = (
  corporatePackageList: CorporatePackageList[],
): CorporatePackagesTypes => {
  return {
    type: CORPORATE_LIST,
    payload: corporatePackageList,
  };
};
