import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Pagination,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdminLoader from "./AdminLoader";
import dayjs from "dayjs";
import MWExceptionList from "../component/MWExceptionList";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { getPatientListValue } from "../redux/effects/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import MWPageTitle from "../component/MWPageTitle";

export default function PatientListAdmin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientListValue(value));
  };

  function gotoDetails(patientId: number) {
    history.push(`/patientcaselist/${patientId}`);
  }
  // Call Patient List API on Page loading
  useEffect(() => {
    dispatch(getPatientListValue(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const truncate = (str: string) => {
    return str.length > 20 ? str.substring(0, 25) + "..." : str;
  };

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.patientValue);
  const pageCount = useSelector((state: AppState) => state.patientValue.patientPageCount);
  const patientListRes = AdminPatientList.patientValue;
  const [loading, setLoading] = useState(false);
  const [listCount, setListCount] = React.useState("");
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getPatientListValue(page));
    }
  };
  useEffect(() => {
    setLoading(AdminPatientList?.loading);
    AdminPatientList?.count !== undefined
      ? setListCount(String(AdminPatientList?.count))
      : setListCount("0");
  }, [AdminPatientList]);

  const [patientList, setPatientList] = useState([] as any[]);
  useEffect(() => {
    if (patientListRes !== undefined) {
      let ptList = patientListRes.map((element: any) => ({
        id: element.id,
        firstName: element?.firstname !== undefined ? element?.firstname : "",
        lastName: element?.lastname !== undefined ? element?.lastname : "",
        dob: dayjs(element.dob).format("MMM D, YYYY"),
        patientRelationshipWithOwner:
          element.patientRelationshipWithOwner?.displayValue !== undefined
            ? element.patientRelationshipWithOwner?.displayValue
            : "",
        gender: element?.gender.label !== undefined ? element?.gender?.label : "",
        bloodGroup: element?.bloodGroup !== undefined ? element.bloodGroup?.label : "",
        description: element?.description !== undefined ? truncate(element.description) : "",
      }));
      setPatientList(ptList);
    } else setPatientList([] as any[]);

    // setDataArray(dataPointArr);
  }, [patientListRes]);
  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: `${t("patientlistpage.view", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
        >
          {t("patientlistpage.view", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "id",
      headerName: `${t("patientlistpage.userid", { ns: ["home"] })!}`,
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: `${t("patientlistpage.firstname", { ns: ["home"] })!}`,
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: `${t("patientlistpage.lastname", { ns: ["home"] })!}`,
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "dob",
      headerName: `${t("patientlistpage.dob", { ns: ["home"] })!}`,
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "patientRelationshipWithOwner",
      headerName: `${t("patientlistpage.relation", { ns: ["home"] })!}`,
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "gender",
      headerName: `${t("patientlistpage.gender", { ns: ["home"] })!}`,
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "bloodGroup",
      headerName: `${t("patientlistpage.bloodgrp", { ns: ["home"] })!}`,
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: `${t("patientlistpage.des", { ns: ["home"] })!}`,
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const patientListTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {patientList && patientList.length ? (
          <DataGrid
            rows={patientList}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {loading ? <AdminLoader /> : null}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <MWPageTitle
                backButton={false}
                title={t("patientlistpage.title", { ns: ["home"] })!}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={reloadActionFromChild}
              />
            </Grid>
          </Grid>
          {patientListTable}
        </Stack>
      </Container>
    </Box>
  );
}
