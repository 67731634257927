import {
  DOCTOR_ACTIVE_NOTE_LIST_FAIL,
  DOCTOR_ACTIVE_NOTE_LIST_LOADING,
  DOCTOR_ACTIVE_NOTE_LIST_SUCCESS,
  DOCTOR_ACTIVE_NOTE_LIST_API_MSG,
  DOCTOR_ACTIVE_NOTE_LIST_API_RES,
  DoctorActiveListActionTypes,
} from "./ActionTypes";
import { DoctorActiveListResponse } from "./Model";

export const doctorActiveListLoadingAction = (loading: boolean): DoctorActiveListActionTypes => {
  return {
    type: DOCTOR_ACTIVE_NOTE_LIST_LOADING,
    loading: loading,
  };
};

export const doctorActiveListSuccessAction = (
  doctorActiveNoteListResponse: DoctorActiveListResponse,
  successMsg: string,
): DoctorActiveListActionTypes => {
  return {
    type: DOCTOR_ACTIVE_NOTE_LIST_SUCCESS,
    payload: doctorActiveNoteListResponse,
    successMsg: successMsg,
  };
};

export const doctorActiveListErrorAction = (
  doctorActiveNoteListResponse: DoctorActiveListResponse,
  errMsg: string,
  status: number,
): DoctorActiveListActionTypes => {
  return {
    type: DOCTOR_ACTIVE_NOTE_LIST_FAIL,
    payload: doctorActiveNoteListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const doctorActiveListAPIMsgAction = (
  doctorActiveNoteListResponse: DoctorActiveListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): DoctorActiveListActionTypes => {
  return {
    type: DOCTOR_ACTIVE_NOTE_LIST_API_MSG,
    payload: doctorActiveNoteListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const doctorActiveListAPIResClearAction = (
  doctorActiveNoteListResponse: DoctorActiveListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): DoctorActiveListActionTypes => {
  return {
    type: DOCTOR_ACTIVE_NOTE_LIST_API_RES,
    payload: doctorActiveNoteListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
