import { PatientListTypes, PATIENT_LIST } from "./ActionTypes";
import { PatientList } from "./Model";

export const getPatientListActions = (
  patientList: PatientList[],
  status: any,
): PatientListTypes => {
  return {
    type: PATIENT_LIST,
    payload: patientList,
    status: status,
  };
};
