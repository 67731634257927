import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardContent } from "@mui/material";
import { t } from "i18next";
import { DataGrid, GridColDef, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getTaskListValue } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import MWExceptionList from "../../component/MWExceptionList";
import dayjs from "dayjs";

export default function DashboardTasklist(props: any) {
  const { taskListLoader } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const gotoTaskDetails = (rowIndex: any) => {
    history.push(`/selftaskdetails/${rowIndex}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTaskListValue());
  }, [dispatch]);

  // call Store
  const AdminAllTaskList = useSelector((state: AppState) => state.taskListValue);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    if (AdminAllTaskList?.taskListValue !== undefined) {
      let dataPointArr = AdminAllTaskList?.taskListValue.map((element: any) => ({
        id: element.id,
        patientName: element.patientName,
        title: element.title,
        consultationType: element.consultationType,
        created_date: dayjs(element.created_date).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllTaskList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("common.view", { ns: ["home"] })!}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoTaskDetails(params.value);
          }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "patientName",
      headerName: `${t("common.patientname", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "title",
      headerName: `${t("admindashboardpage.title", { ns: ["home"] })!}`,
      width: 350,
      flex: 1,
    },
    {
      field: "consultationType",
      headerName: `${t("admindashboardpage.constype", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
    {
      field: "created_date",
      headerName: `${t("admindashboardpage.createDate", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
  ];

  const DataGridMarkup = (
    <Card>
      <CardContent>
        {AdminAllTaskList?.taskListValue !== undefined ? (
          AdminAllTaskList?.taskListValue.length > 0 ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminAllTaskList.loading === true ? taskListLoader(true) : taskListLoader(false)}
      {DataGridMarkup}
    </Box>
  );
}
