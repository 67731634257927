import {
  FIND_USER_DATA,
  FIND_USER_FAIL,
  FIND_USER_LOADING,
  FIND_USER_SUCCESS,
  FindUserActionTypes,
} from "./ActionTypes";
import { FindUserResponse } from "./Model";

export const findUserAction = (findUserRes: FindUserResponse): FindUserActionTypes => {
  return {
    type: FIND_USER_DATA,
    payload: findUserRes,
  };
};

export const findUserLoadingAction = (loading: boolean): FindUserActionTypes => {
  return {
    type: FIND_USER_LOADING,
    loading: loading,
  };
};

export const findUserSuccessAction = (findUserResponse: FindUserResponse): FindUserActionTypes => {
  return {
    type: FIND_USER_SUCCESS,
    payload: findUserResponse,
  };
};

export const findUserErrorAction = (
  findUserResponse: FindUserResponse,
  errMsg: string,
  status: number,
): FindUserActionTypes => {
  return {
    type: FIND_USER_FAIL,
    payload: findUserResponse,
    message: errMsg,
    status: status,
  };
};
export const findUserUpdateAPIMsgAction = (
  findUserResponse: FindUserResponse,
  errMsg: string,
  status: number,
): FindUserActionTypes => {
  return {
    type: FIND_USER_FAIL,
    payload: findUserResponse,
    message: errMsg,
    status: status,
  };
};

export const findUserAPIResClearAction = (
  findUserResponse: FindUserResponse,
  errMsg: string,
  status: number,
): FindUserActionTypes => {
  return {
    type: FIND_USER_FAIL,
    payload: findUserResponse,
    message: errMsg,
    status: status,
  };
};
