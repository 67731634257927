import {
  ADD_PATIENT_IMPORT_CONFIG_LIST_FAIL,
  ADD_PATIENT_IMPORT_CONFIG_LIST_LOADING,
  ADD_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
  ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
  ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
  PatientImportConfigListActionTypes,
} from "./ActionTypes";
import { AddPatientImportConfigValueRes } from "./Model";

export const addPatientImportConfigListLoadingAction = (
  loading: boolean,
): PatientImportConfigListActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_LIST_LOADING,
    loading: loading,
  };
};

export const addPatientImportConfigListSuccessAction = (
  addPatientImportConfigListResponse: AddPatientImportConfigValueRes,
  successMsg: string,
): PatientImportConfigListActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
    payload: addPatientImportConfigListResponse,
    successMsg: successMsg,
  };
};

export const addPatientImportConfigListErrorAction = (
  addPatientImportConfigListResponse: AddPatientImportConfigValueRes,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_LIST_FAIL,
    payload: addPatientImportConfigListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addPatientImportConfigListUpdateAPIMsgAction = (
  addPatientImportConfigListResponse: AddPatientImportConfigValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
    payload: addPatientImportConfigListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addPatientImportConfigListAPIResClearAction = (
  addPatientImportConfigListResponse: AddPatientImportConfigValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
    payload: addPatientImportConfigListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
