import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetDoctorDetailsByIdActionTypes } from "./ActionTypes";
import { GetDoctorDetailsByIdRes } from "./Model";
import {
  getDoctorDetailsByIdAPIResClearAction,
  getDoctorDetailsByIdErrorAction,
  getDoctorDetailsByIdLoadingAction,
  getDoctorDetailsByIdSuccessAction,
  getDoctorDetailsByIdUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetDoctorDetailsByIdRes;
export const getDoctorDetailsById = (doctorId: string) => {
  return function (dispatch: Dispatch<GetDoctorDetailsByIdActionTypes>) {
    dispatch(getDoctorDetailsByIdLoadingAction(true));
    axios
      .get(Url.baseUrl + `/landingeapp/api/doctor-details-by-id/${doctorId}`, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorDetailsByIdLoadingAction(false));
        dispatch(
          getDoctorDetailsByIdSuccessAction(
            res.data,
            "Doctor details has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDoctorDetailsByIdLoadingAction(false));
        dispatch(
          getDoctorDetailsByIdErrorAction(
            {} as GetDoctorDetailsByIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateAdminNoteListByPatientEditAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorDetailsByIdActionTypes>) {
    dispatch(getDoctorDetailsByIdUpdateAPIMsgAction(apiRes as GetDoctorDetailsByIdRes, "", "", 0));
  };
};

export const clearAdminNoteListByPatientEditAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorDetailsByIdActionTypes>) {
    dispatch(getDoctorDetailsByIdAPIResClearAction({} as GetDoctorDetailsByIdRes, "", "", 0));
  };
};
