import {
  ADD_SCHEDULE_FAIL,
  ADD_SCHEDULE_LOADING,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_UPDATE_API_MSG,
  ADD_SCHEDULE_UPDATE_API_RES,
  AddScheduleActionTypes,
} from "./ActionTypes";
import { AddScheduleRes } from "./Model";

export const addScheduleLoadingAction = (loading: boolean): AddScheduleActionTypes => {
  return {
    type: ADD_SCHEDULE_LOADING,
    loading: loading,
  };
};

export const addScheduleSuccessAction = (
  addScheduleResponse: AddScheduleRes,
  successMsg: string,
): AddScheduleActionTypes => {
  return {
    type: ADD_SCHEDULE_SUCCESS,
    payload: addScheduleResponse,
    successMsg: successMsg,
  };
};

export const addScheduleErrorAction = (
  addScheduleResponse: AddScheduleRes,
  errMsg: string,
  status: number,
): AddScheduleActionTypes => {
  return {
    type: ADD_SCHEDULE_FAIL,
    payload: addScheduleResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addScheduleUpdateAPIMsgAction = (
  addScheduleResponse: AddScheduleRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddScheduleActionTypes => {
  return {
    type: ADD_SCHEDULE_UPDATE_API_MSG,
    payload: addScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addScheduleAPIResClearAction = (
  addScheduleResponse: AddScheduleRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddScheduleActionTypes => {
  return {
    type: ADD_SCHEDULE_UPDATE_API_RES,
    payload: addScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
