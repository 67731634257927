import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  clearNoteAPIRes,
  updateNote,
  updateNoteAPIResMsg,
} from "../../../../redux/Notes/updateNoteStatus/API";
import AdminLoader from "../../../AdminLoader";
type Props = {
  status: boolean | any;
  selectedItem: string | any;
  updateNoteStatusDialog: boolean | any;
  updateNoteStatusEditDialogUpdateState: boolean | any;
};
export default function DeletePatientNote({
  status,
  selectedItem,
  updateNoteStatusDialog,
  updateNoteStatusEditDialogUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const [activeDialog, setActiveDialog] = useState(false);
  const closeDialog = () => {
    setActiveDialog(false);
    dispatch(clearNoteAPIRes());
    updateNoteStatusEditDialogUpdateState(false);
  };
  useEffect(() => {
    setActiveDialog(updateNoteStatusDialog);
  }, [updateNoteStatusDialog]);

  const addPatientNote = () => {
    if (selectedItem !== undefined) {
      dispatch(updateNote(Number(selectedItem)));
    }
  };
  const [addNoteErrMsg, setAddNoteErrMsg] = useState("");
  const [addNoteErrMsgType, setAddNoteErrMsgType] = useState<AlertColor>("error");
  // Update Notes status Api Value from store
  const updateNoteResVal = useSelector((state: AppState) => state.updateNoteRes);
  useEffect(() => {
    if (updateNoteResVal?.successMsg !== "") {
      setActiveDialog(false);
      updateNoteStatusEditDialogUpdateState(false);
    }
    if (updateNoteResVal?.errorMsg !== "") {
      setAddNoteErrMsgType("error");
      setAddNoteErrMsg(updateNoteResVal?.errorMsg);
    }
    if (updateNoteResVal?.errorMsg === "") {
      setAddNoteErrMsgType("error");
      setAddNoteErrMsg(updateNoteResVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNoteResVal]);
  return (
    <Dialog open={activeDialog} onClose={closeDialog} fullWidth>
      {updateNoteResVal?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">{status ? "Activate" : "Inactivate"} Note</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        Are you sure! You want to <strong>{status ? "Activate" : "Inactivate"}</strong> this patient
        note?
      </DialogContent>
      <Divider />
      {addNoteErrMsg !== "" ? (
        <Box>
          <Alert
            severity={addNoteErrMsgType}
            onClose={() => {
              dispatch(updateNoteAPIResMsg());
            }}
          >
            {addNoteErrMsg}
          </Alert>
        </Box>
      ) : null}
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={closeDialog} sx={{ textTransform: "none" }}>
              No
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={addPatientNote}
              disabled={updateNoteResVal?.loading}
              sx={{ textTransform: "none" }}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
