import {
  SCHEDULE_MEETING_FAIL,
  SCHEDULE_MEETING_LOADING,
  SCHEDULE_MEETING_SUCCESS,
  SCHEDULE_MEETING_UPDATE_API_MSG,
  SCHEDULE_MEETING_UPDATE_API_RES,
  ScheduleMeetingActionTypes,
} from "./ActionTypes";
import { CreateScheduleMeetingValueRes } from "./Model";

export const scheduleMeetingLoadingAction = (loading: boolean): ScheduleMeetingActionTypes => {
  return {
    type: SCHEDULE_MEETING_LOADING,
    loading: loading,
  };
};

export const scheduleMeetingSuccessAction = (
  scheduleMeetingResponse: CreateScheduleMeetingValueRes,
  successMsg: string,
): ScheduleMeetingActionTypes => {
  return {
    type: SCHEDULE_MEETING_SUCCESS,
    payload: scheduleMeetingResponse,
    successMsg: successMsg,
  };
};

export const scheduleMeetingErrorAction = (
  scheduleMeetingResponse: CreateScheduleMeetingValueRes,
  errMsg: string,
  status: number,
): ScheduleMeetingActionTypes => {
  return {
    type: SCHEDULE_MEETING_FAIL,
    payload: scheduleMeetingResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const scheduleMeetingUpdateAPIMsgAction = (
  scheduleMeetingResponse: CreateScheduleMeetingValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ScheduleMeetingActionTypes => {
  return {
    type: SCHEDULE_MEETING_UPDATE_API_MSG,
    payload: scheduleMeetingResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const scheduleMeetingAPIResClearAction = (
  scheduleMeetingResponse: CreateScheduleMeetingValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ScheduleMeetingActionTypes => {
  return {
    type: SCHEDULE_MEETING_UPDATE_API_RES,
    payload: scheduleMeetingResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
