import { CorporatePackagePaymentListTypes, CORPORATE_PACKAGE_PAYMENT_LIST } from "./ActionTypes";
import { PaymentList } from "./Model";

export const getPatientListActions = (
  patientList: PaymentList[],
  status: any,
): CorporatePackagePaymentListTypes => {
  return {
    type: CORPORATE_PACKAGE_PAYMENT_LIST,
    payload: patientList,
    status: status,
  };
};
