import React, { useState, useCallback, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  deactivetelehealthMeeting,
  getDoctorListForCase,
  getTelehealthMeetingList,
  getQuestionList,
  askQuestionToPatient,
  answerQuestionToPatient,
} from "../../redux/Patient/DoctorPatientInteraction/ApiCall";
import { AppState } from "../../redux/store/Store";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MWToast from "../../component/MWToast";
import MWExceptionList from "../../component/MWExceptionList";
import MWTextField from "../../component/MWTextField";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { t } from "i18next";
import { createInstantMeetingApi } from "../../redux/CreateInstantMeeting/ApiCall";
import { scheduleMeetingApi } from "../../redux/CreateScheduleMeeting/ApiCall";

interface Props {
  appointmentDoctorPatientInteractionLoader: any;
}

export default function DoctorPatientInteraction({
  appointmentDoctorPatientInteractionLoader,
}: Props) {
  // const { appointmentDoctorPatientInteractionLoader } = props;
  const dispatch = useDispatch();

  const [doctorId, setDoctorId] = useState("");
  //console.log("doctorId===", doctorId);
  const { caseid } = useParams() as {
    caseid: string;
  };

  // Telehealth Banner
  const [title] = useState(`${t("appointmentpage.docpatint", { ns: ["home"] })!}`);
  const [bannerbody] = useState(`${t("appointmentpage.consult", { ns: ["home"] })!}`);

  const [selectedDocName, setSelectedDocName] = useState<any | null>(null);

  const handleDocNameChange = (_selectedDocVal: any, newvalue: any) => {
    setSelectedDocName(newvalue.value);
    setDoctorId(newvalue.value);
  };

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  // const handleDateChange = (date: Date) => {
  //   setSelectedDate(date);
  // };
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  let newDate = dayjs(`${selectedDate}`);
  // success
  const [createInstantMeetingSuccess, setCreateInstantMeetingSuccess] = useState("");
  const [toastSuccessActive, setToastSuccessActive] = useState(false);
  const toggleToastSuccessActive = useCallback(
    () => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive),
    [],
  );

  const createInstantMeetingSuccessDiv = toastSuccessActive ? (
    <Snackbar open={toastSuccessActive} autoHideDuration={8000} onClose={toggleToastSuccessActive}>
      <Alert onClose={toggleToastSuccessActive} severity="success" sx={{ width: "100%" }}>
        {createInstantMeetingSuccess}
      </Alert>
    </Snackbar>
  ) : null;

  // error

  const [createInstantMeetingError, setCreateInstantMeetingError] = useState("");
  const [toastErrorActive, setToastErrorActive] = useState(false);
  const toggleToastErrorActive = useCallback(
    () => setToastErrorActive((toastErrorActive) => !toastErrorActive),
    [],
  );

  const createInstantMeetingErrorDiv = toastErrorActive ? (
    <Snackbar open={toastErrorActive} autoHideDuration={8000} onClose={toggleToastErrorActive}>
      <Alert onClose={toggleToastErrorActive} severity="error" sx={{ width: "100%" }}>
        {createInstantMeetingError}
      </Alert>
    </Snackbar>
  ) : null;

  const [questionDescription, setQuestionDescription] = useState("");
  const handleQuestionDescriptionChange = useCallback((newValue: any) => {
    setQuestionDescription(newValue);
  }, []);

  const [questionDescriptionError, setQuestionDescriptionError] = useState("");
  const questionDescriptionErrordiv = questionDescriptionError ? (
    <Typography color="error">{questionDescriptionError}</Typography>
  ) : (
    ""
  );

  const [questionModalActive, setQuestionModalActive] = useState(false);
  const handleToggle = () => {
    setQuestionModalActive((questionModalActive) => !questionModalActive);
    setQuestionDescription("");
    setQuestionDescriptionError("");
  };

  const [questionAnswerError, setQuestionAnswerError] = useState("");
  const questionAnswerErrordiv = questionAnswerError ? (
    <Typography color="error">{questionAnswerError}</Typography>
  ) : (
    ""
  );

  const [question, setQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [caseNo, setCaseNo] = useState("");
  const [answerquestion, setAnswerquestion] = useState("");
  const handleAnswerQuestionChange = useCallback(
    (newValue: any) => setAnswerquestion(newValue),
    [],
  );

  const [answerModalActive, setAnswerModalActive] = useState(false);
  const handleAnswerModalActive = () => {
    setAnswerModalActive((answerModalActive) => !answerModalActive);
    setAnswerquestion("");
  };
  function answerById(questionID: any, caseId: any, questionText: any) {
    handleAnswerModalActive();
    setQuestionId(questionID);
    setQuestion(questionText);
    setCaseNo(caseId);
  }

  const [answer, setAnswer] = useState("");

  const [viewAnswerModalActive, setViewAnswerModalActive] = useState(false);
  const handleViewAnswerModalActive = () => {
    setViewAnswerModalActive((viewAnswerModalActive) => !viewAnswerModalActive);
    setAnswerquestion("");
  };
  const truncate = (str: string) => {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  };

  // Get Selected Doctor List DropDown API
  const getDoctorDropDownlList = () => {
    dispatch(getDoctorListForCase(caseid));
  };

  // Meeting List GET API
  const getMeetingList = () => {
    dispatch(getTelehealthMeetingList(caseid));
  };
  // Create or Schedule Meeting Body
  const createOrScheduleMeetingBody = {
    doctorId: doctorId,
    caseId: String(caseid),
    starttime: dayjs(newDate).format("YYYY-MM-DD"),
  };
  // Create Meeting API
  const createMeeting = () => {
    dispatch(createInstantMeetingApi(createOrScheduleMeetingBody));
  };

  const CreateInstantMeetingResponse = useSelector((state: AppState) => state.CreateInstantMeeting);
  useEffect(() => {
    if (CreateInstantMeetingResponse?.createInstantMeetingRes?.message !== undefined) {
      setToastSuccessActive(true);
      setToastErrorActive(false);
      setCreateInstantMeetingSuccess(
        CreateInstantMeetingResponse?.createInstantMeetingRes?.message,
      );
      setCreateInstantMeetingError("");
    } else {
      setToastErrorActive(true);
      setToastSuccessActive(false);
      setCreateInstantMeetingError(CreateInstantMeetingResponse?.errorMsg);
      setCreateInstantMeetingSuccess("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CreateInstantMeetingResponse]);

  // Create schedule Meeting API
  const scheduleMeeting = () => {
    dispatch(scheduleMeetingApi(createOrScheduleMeetingBody));
  };

  const ScheduleMeetingResponse = useSelector((state: AppState) => state.scheduleMeeting);
  useEffect(() => {
    if (ScheduleMeetingResponse?.scheduleMeetingRes?.message !== undefined) {
      setToastSuccessActive(true);
      setToastErrorActive(false);
      setCreateInstantMeetingSuccess(ScheduleMeetingResponse?.scheduleMeetingRes?.message);
      setCreateInstantMeetingError("");
    } else {
      setToastErrorActive(true);
      setToastSuccessActive(false);
      setCreateInstantMeetingError(ScheduleMeetingResponse?.errorMsg);
      setCreateInstantMeetingSuccess("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ScheduleMeetingResponse]);

  const startMeeting = (meetingURL: any) => {
    window.open(meetingURL);
  };
  const joinMeeting = (joinMeetingUrl: any) => {
    window.open(joinMeetingUrl);
  };
  // Deactive Meeting API
  const deactivateMeeting = (meetingId: any) => {
    dispatch(deactivetelehealthMeeting(meetingId));
    setSuccessMeetingDeactiveToastActive(
      (successMeetingDeactiveToastActive) => !successMeetingDeactiveToastActive,
    );
    setErrorMeetingDeactiveToastActive(
      (errorMeetingDeactiveToastActive) => !errorMeetingDeactiveToastActive,
    );
  };
  // get Question List body
  const getQuestionBody = {
    caseId: caseid,
    doctorId: doctorId,
  };
  // Get Question List API
  const getAskedQuestionList = () => {
    dispatch(getQuestionList(getQuestionBody));
  };
  // Ask Question Body
  const questionBody = {
    doctorId: doctorId,
    caseId: caseid,
    questionText: questionDescription,
    questionType: "DoctorToPatient",
  };

  // Question Submit API
  const questionSubmit = () => {
    if (questionDescription === "") {
      setQuestionDescriptionError("Please add question");
    } else {
      dispatch(askQuestionToPatient(questionBody));
      handleToggle();
      setSuccessAskQuestionToastActive(
        (successAskQuestionToastActive) => !successAskQuestionToastActive,
      );
      setAskQuestionToastActive((errorAskQuestionToastActive) => !errorAskQuestionToastActive);
    }
  };
  // Answer question body
  const answerSubmitBody = {
    questionId: String(questionId),
    caseId: String(caseNo),
    type: "doctor",
    answerText: answerquestion,
  };
  // Submit Answer by Question API
  const answerSubmit = () => {
    if (answerquestion === "") {
      setQuestionAnswerError("Please add answer");
    } else {
      dispatch(answerQuestionToPatient(answerSubmitBody));
      setAnswerModalActive((answerModalActive) => !answerModalActive);
      setAnswerquestion("");
      setQuestionAnswerError("");
      setSuccessAnswerQuestionToastActive(
        (successAnswerQuestionToastActive) => !successAnswerQuestionToastActive,
      );
      setAnswerQuestionToastActive(
        (errorAnswerQuestionToastActive) => !errorAnswerQuestionToastActive,
      );
    }
  };

  useEffect(() => {
    getDoctorDropDownlList();
    getMeetingList();
    getAskedQuestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteMeetingMessage = useSelector(
    (state: AppState) => state.deletetelehealthMeetingValue.message,
  );
  const deleteMeetingStatus = useSelector(
    (state: AppState) => state.deletetelehealthMeetingValue.status,
  );

  // delete meeting success toast
  const [successMeetingDeactiveToastActive, setSuccessMeetingDeactiveToastActive] = useState(false);

  const successMeetingDeactiveToastMarkup = successMeetingDeactiveToastActive ? (
    <MWToast message={deleteMeetingMessage} />
  ) : null;
  // delete meeting error toast
  const [errorMeetingDeactiveToastActive, setErrorMeetingDeactiveToastActive] = useState(false);

  const errorMeetingDeactiveToastMarkup = errorMeetingDeactiveToastActive ? (
    <MWToast message={deleteMeetingMessage} />
  ) : null;

  const askQuestionMessage = useSelector(
    (state: AppState) => state.askQuestionToPatientValue?.message,
  );
  const askQuestionStatus = useSelector(
    (state: AppState) => state.askQuestionToPatientValue.status,
  );

  useEffect(() => {
    if (askQuestionMessage !== undefined) {
      dispatch(getQuestionList(getQuestionBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askQuestionMessage]);
  // Ask Question success toast
  const [successAskQuestionToastActive, setSuccessAskQuestionToastActive] = useState(false);
  const successAskQuestionToastMarkup = successAskQuestionToastActive ? (
    <MWToast message={askQuestionMessage} />
  ) : null;
  // Ask Question error toast
  const [errorAskQuestionToastActive, setAskQuestionToastActive] = useState(false);
  const errorAskQuestionToastMarkup = errorAskQuestionToastActive ? (
    <MWToast message={askQuestionMessage} />
  ) : null;

  const answerQuestionMessage = useSelector(
    (state: AppState) => state.askQuestionToPatientValue.message,
  );
  const answerQuestionStatus = useSelector(
    (state: AppState) => state.askQuestionToPatientValue.status,
  );

  // Ask Question success toast
  const [successAnswerQuestionToastActive, setSuccessAnswerQuestionToastActive] = useState(false);
  const successAnswerQuestionToastMarkup = successAnswerQuestionToastActive ? (
    <MWToast message={answerQuestionMessage} />
  ) : null;
  // Ask Question error toast
  const [errorAnswerQuestionToastActive, setAnswerQuestionToastActive] = useState(false);
  const errorAnswerQuestionToastMarkup = errorAnswerQuestionToastActive ? (
    <MWToast message={answerQuestionMessage} />
  ) : null;

  const doctorDropdownList = useSelector(
    (state: AppState) => state.doctorListForcasevalue.patientCaseDoctorlist,
  );

  let docList: Array<{ label: string; value: number }> = [];
  doctorDropdownList.forEach(
    (element: { firstname: string; lastname: string; id: number; speciality: string }) => {
      docList.push({
        label: element.firstname + " " + element.lastname + " ( " + element.speciality + " )",
        value: element.id,
      });
    },
  );
  const noteListValue: any[] = docList;

  const meetingList = useSelector((state: AppState) => state.meetingListValue.meetingList);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  // Configure Meeting list table
  let meetingValueList = [];
  for (var i = 0; i < meetingList.length; i++) {
    let displayName = meetingList[i].hasOwnProperty("displayName")
      ? meetingList[i].displayName === "" || meetingList[i].displayName === null
        ? "No Details Available"
        : meetingList[i].displayName
      : "";
    let meetingId = meetingList[i].hasOwnProperty("id")
      ? meetingList[i].id === null
        ? ""
        : meetingList[i].id
      : "";
    let meetingURL = meetingList[i].hasOwnProperty("startMeetingUrl")
      ? meetingList[i].startMeetingUrl === "" || meetingList[i].startMeetingUrl === null
        ? "No Details Available"
        : meetingList[i].startMeetingUrl
      : "";
    let joinMeetingUrl = meetingList[i].hasOwnProperty("joinMeetingUrl")
      ? meetingList[i].joinMeetingUrl === "" || meetingList[i].joinMeetingUrl === null
        ? "No Details Available"
        : meetingList[i].joinMeetingUrl
      : "";
    let appointmentDate = meetingList[i].hasOwnProperty("appointmentDate")
      ? meetingList[i].appointmentDate == null
        ? ""
        : new Date(meetingList[i].appointmentDate).toLocaleDateString("en-US", DATE_OPTIONS) +
          " at " +
          new Date(meetingList[i].appointmentDate).getHours() +
          ":" +
          new Date(meetingList[i].appointmentDate).getMinutes()
      : "";
    let createdDate =
      meetingList[i].dateCreated == null
        ? ""
        : new Date(meetingList[i].dateCreated).toLocaleDateString("en-US", DATE_OPTIONS);
    meetingValueList.push([
      <Button
        onClick={() => startMeeting(meetingURL)}
        disabled={meetingURL === "" ? true : false}
        variant="outlined"
        size="small"
      >
        {t("appointmentpage.startmeeting", { ns: ["home"] })!}
      </Button>,
      meetingId,
      displayName,
      appointmentDate,
      createdDate,
      <Button
        onClick={() => joinMeeting(joinMeetingUrl)}
        disabled={joinMeetingUrl === "" ? true : false}
        variant="outlined"
        size="small"
      >
        {t("appointmentpage.joinmeeting", { ns: ["home"] })!}
      </Button>,
      <Button onClick={() => deactivateMeeting(meetingId)} variant="outlined" size="small">
        {t("appointmentpage.diactive", { ns: ["home"] })!}
      </Button>,
    ]);
  }
  const meetingListValue: any[] = meetingValueList;

  const questionList = useSelector(
    (state: AppState) => state.questionListValueUnderCase.questionList,
  );
  console.log("Doctor-Patient questionList", questionList);
  let questionLIstValueConfig = [];
  if (questionList !== undefined) {
    for (var j = 0; j < questionList.length; j++) {
      let questionID = questionList[j].id == null ? "" : questionList[j].id;
      let caseID = questionList[j].caseId;
      let questionText = questionList[j].questionText === "" ? "" : questionList[j].questionText;
      let tableQuestionText =
        questionList[j].questionText === "" ? "" : truncate(questionList[j].questionText);
      let questionAskedBy = questionList[j].askedBy === "" ? "" : questionList[j].askedBy;
      let answer = questionList[j].hasOwnProperty("answerText")
        ? questionList[j].answerText === ""
          ? ""
          : truncate(questionList[j].answerText)
        : "";
      let fullAnswer = questionList[j].hasOwnProperty("answerText")
        ? questionList[j].answerText === ""
          ? ""
          : questionList[j].answerText
        : "";
      let answerBy = questionList[j].answeredBy === "" ? "" : questionList[j].answeredBy;
      let questionStatus =
        questionList[j].questionStatus === "" ? "" : questionList[j].questionStatus;
      questionLIstValueConfig.push([
        tableQuestionText,
        questionAskedBy,
        answer,
        answerBy,
        questionStatus,
        <Button
          onClick={() => answerById(questionID, caseID, questionText)}
          variant="outlined"
          size="small"
        >
          <IconButton color="primary">
            <EditIcon />
          </IconButton>
          {t("appointmentpage.answer", { ns: ["home"] })!}
        </Button>,
        <Button
          onClick={() => viewAnswerById(questionID, questionText, fullAnswer)}
          variant="outlined"
          size="small"
        >
          <IconButton color="primary">
            <VisibilityIcon />
          </IconButton>
          {t("appointmentpage.viewanswer", { ns: ["home"] })!}
        </Button>,
      ]);
    }
  }

  const QuestionListValue: any[] = questionLIstValueConfig;

  function viewAnswerById(questionID: any, questionText: any, fullAnswer: any) {
    handleViewAnswerModalActive();
    setQuestionId(questionID);
    setQuestion(questionText);
    setAnswer(fullAnswer);
  }

  const bannermarkup = bannerbody ? (
    <Box p={1}>
      <Alert severity="info" variant="outlined">
        <AlertTitle>
          <strong>{title}</strong>
        </AlertTitle>
        <Typography>{bannerbody}</Typography>
      </Alert>
    </Box>
  ) : (
    ""
  );
  // Doctor Dropdownlist Markup
  const doctorListMarkup = (
    <Box pt={1} pb={2}>
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h5" gutterBottom>
                {t("docTeamPage.selectDocZoomMeet", { ns: ["home"] })!}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                value={
                  noteListValue.find((element: any) => element.value === selectedDocName) || null
                }
                id="templateId"
                options={noteListValue}
                onChange={(event: any, newValue: any) => {
                  handleDocNameChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("selDocPage.selDoc", { ns: ["home"] })!} />
                )}
                getOptionLabel={(option) => `${option.label}`}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
  // Telehealth Meeting Table
  const appointmentTableBody = (
    <div>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>{t("appointmentpage.existmeetdetail", { ns: ["home"] })!}</Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDateTimePicker
                        inputFormat="DD/MM/YYYY HH:mm"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                            size="small"
                          />
                        )}
                        InputAdornmentProps={{ position: "end" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={scheduleMeeting}
                      disabled={selectedDocName === "" || selectedDocName === null ? true : false}
                      variant="outlined"
                      size="small"
                    >
                      {t("appointmentpage.schmeet", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={createMeeting}
                      disabled={selectedDocName === "" || selectedDocName === null ? true : false}
                      variant="outlined"
                      size="small"
                    >
                      {t("appointmentpage.createmeet", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={getMeetingList} variant="outlined" size="small">
                      {t("telehealthpage.reloadmeetlist", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {meetingListValue && meetingListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Start Meeting</TableCell>
                    <TableCell>Meeting Id</TableCell>
                    <TableCell>Appointment Name</TableCell>
                    <TableCell>Appointment Date & Time</TableCell>
                    <TableCell>Create Date</TableCell>
                    <TableCell>Join Meeting</TableCell>
                    <TableCell>Deactivate Meeting</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meetingListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                      <TableCell align="left">{row[4]}</TableCell>
                      <TableCell align="left">{row[5]}</TableCell>
                      <TableCell align="left">{row[6]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      <br />
    </div>
  );

  // Question Table
  const questionTableBody = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>{t("appointmentpage.Q&A", { ns: ["home"] })!}</Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Button onClick={getAskedQuestionList} variant="outlined" size="small">
                    {t("common.reload", { ns: ["home"] })!}
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleToggle} variant="contained" size="small">
                    {t("appointmentpage.askquestion", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {QuestionListValue && QuestionListValue.length ? (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("appointmentpage.desc", { ns: ["home"] })!}</TableCell>
                  <TableCell>{t("appointmentpage.askby", { ns: ["home"] })!}</TableCell>
                  <TableCell>{t("docpatientintpage.ans", { ns: ["home"] })!}</TableCell>
                  <TableCell>{t("appointmentpage.ansBy", { ns: ["home"] })!}</TableCell>
                  <TableCell>{t("appointmentpage.status", { ns: ["home"] })!}</TableCell>
                  <TableCell>{t("appointmentpage.ansSub", { ns: ["home"] })!}</TableCell>
                  <TableCell>{t("appointmentpage.fullAns", { ns: ["home"] })!}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {QuestionListValue.map((row: any) => (
                  <TableRow
                    key={row.description}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row[0]}</TableCell>
                    <TableCell align="left">{row[1]}</TableCell>
                    <TableCell align="left">{row[2]}</TableCell>
                    <TableCell align="left">{row[3]}</TableCell>
                    <TableCell align="left">{row[4]}</TableCell>
                    <TableCell align="left">{row[5]}</TableCell>
                    <TableCell align="left">{row[6]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  // Question Modal
  const questionModalBody = (
    <Dialog open={questionModalActive} onClose={handleToggle} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">
              {t("appointmentpage.askquestion", { ns: ["home"] })!}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Container maxWidth="xl">
          <Box pt={2}>
            <MWTextField
              label={t("appointmentpage.ques", { ns: ["home"] })!}
              required
              placeholder={t("appointmentpage.enterQues", { ns: ["home"] })!}
              value={questionDescription}
              onChange={(e) => handleQuestionDescriptionChange(e.target.value)}
              multiline
              maxRows={4}
              id="questionDescriptionID"
              fullWidth
            />
            <Typography id="questionDescriptionID">{questionDescriptionErrordiv}</Typography>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={questionSubmit} variant="contained">
          {t("Workschedulepage.submit", { ns: ["home"] })!}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Answer Question modal
  const answerQuestionComponent = (
    <Box>
      <Dialog open={answerModalActive} onClose={handleAnswerModalActive} fullWidth>
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">
                {t("docpatientintpage.addAns", { ns: ["home"] })!}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleAnswerModalActive}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ fontWeight: "bold" }}>
            {" "}
            {t("appointmentpage.ques", { ns: ["home"] })!}: {question}
          </Typography>
          <MWTextField
            label="Answer"
            value={answerquestion}
            onChange={(e) => handleAnswerQuestionChange(e.target.value)}
            multiline
            maxRows={4}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={answerSubmit} variant="contained">
            {t("Workschedulepage.submit", { ns: ["home"] })!}
          </Button>
        </DialogActions>
        <Box>{questionAnswerErrordiv}</Box>
      </Dialog>
    </Box>
  );
  // View Answer Markup
  const viewAnswerComponent = (
    <Box>
      <Dialog open={viewAnswerModalActive} onClose={handleViewAnswerModalActive} fullWidth>
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">
                {t("docpatientintpage.viewans", { ns: ["home"] })!}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleViewAnswerModalActive}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ fontWeight: "bold" }}>
            {" "}
            {t("appointmentpage.ques", { ns: ["home"] })!}: {question}
          </Typography>
          <MWTextField
            label="Answer"
            value={answer}
            onChange={(e) => handleAnswerQuestionChange(e.target.value)}
            multiline
            maxRows={4}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewAnswerModalActive} variant="contained">
            {t("appointmentpage.close", { ns: ["home"] })!}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  return (
    <Box>
      {/* {doctorDropdownListLoadingValue === true ||
        meetingListLoadingValue === true ||
        createOrScheduleMeetingLoadingValue === true ||
        deleteMeetingLoadingValue === true ||
        questionListLoadingValue === true ||
        askQuestionLoadingValue === true ||
        answerQuestionLoadingValue === true
          ? appointmentDoctorPatientInteractionLoader(true)
          : appointmentDoctorPatientInteractionLoader(false)}*/}

      {bannermarkup}
      {doctorListMarkup}
      {appointmentTableBody}
      {createInstantMeetingSuccess !== "" ? createInstantMeetingSuccessDiv : null}
      {createInstantMeetingError !== "" ? createInstantMeetingErrorDiv : null}
      {deleteMeetingStatus! === 1 ? successMeetingDeactiveToastMarkup : ""}
      {deleteMeetingStatus! === -2 || deleteMeetingStatus! === -3
        ? errorMeetingDeactiveToastMarkup
        : ""}
      {questionTableBody}
      {questionModalBody}
      {answerQuestionComponent}
      {viewAnswerComponent}
      {askQuestionStatus! === 1 ? successAskQuestionToastMarkup : ""}
      {askQuestionStatus! === 0 ? errorAskQuestionToastMarkup : ""}
      {answerQuestionStatus! === 1 ? successAnswerQuestionToastMarkup : ""}
      {answerQuestionStatus! === 0 ? errorAnswerQuestionToastMarkup : ""}
    </Box>
  );
}
