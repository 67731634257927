import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import MWTextField from "../../../../../component/MWTextField";
import { t } from "i18next";
export default function PersonalHistory() {
  return (
    <Card>
      <CardContent>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{t("perSocHisPage.qDrBe", { ns: ["home"] })}</Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={t("filePrepPage.yes", { ns: ["home"] })}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={t("filePrepPage.no", { ns: ["home"] })}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <MWTextField
                label=""
                placeholder={t("perSocHisPage.glPerDay", { ns: ["home"] })!}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{t("perSocHisPage.qSm", { ns: ["home"] })}</Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={t("filePrepPage.yes", { ns: ["home"] })}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={t("filePrepPage.no", { ns: ["home"] })}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <MWTextField
                label=""
                placeholder={t("perSocHisPage.forHowLong", { ns: ["home"] })!}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{t("perSocHisPage.qAlIntake", { ns: ["home"] })}</Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={t("filePrepPage.yes", { ns: ["home"] })}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={t("filePrepPage.no", { ns: ["home"] })}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <MWTextField
                label=""
                placeholder={t("perSocHisPage.noOrWk", { ns: ["home"] })!}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{t("perSocHisPage.qDrugIntake", { ns: ["home"] })}</Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={t("filePrepPage.yes", { ns: ["home"] })}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={t("filePrepPage.no", { ns: ["home"] })}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <MWTextField
                label=""
                placeholder={t("perSocHisPage.drugIntake", { ns: ["home"] })!}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>{t("perSocHisPage.qExeReg", { ns: ["home"] })}</Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={t("filePrepPage.yes", { ns: ["home"] })}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={t("filePrepPage.no", { ns: ["home"] })}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <MWTextField
                label=""
                placeholder={t("perSocHisPage.enterExe", { ns: ["home"] })!}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Grid item>
            <Button variant="contained" sx={{ textTransform: "none" }}>
              {t("Workschedulepage.submit", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
