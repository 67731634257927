import { CorporatePackageToggleResponse } from "./Model";

export const CORPORATE_PACKAGE_TOGGLE_RESPONSE = "CORPORATE_PACKAGE_TOGGLE_RESPONSE";
export const CORPORATE_PACKAGE_TOGGLE_LOADING = "CORPORATE_PACKAGE_TOGGLE_LOADING";
export const CORPORATE_PACKAGE_TOGGLE_SUCCESS = "CORPORATE_PACKAGE_TOGGLE_SUCCESS";
export const CORPORATE_PACKAGE_TOGGLE_FAIL = "CORPORATE_PACKAGE_TOGGLE_FAIL";

// Get Active Corporate Package List
export interface GetCorporatePackageToggleStateType {
  corporatePackageToggleValue: CorporatePackageToggleResponse;
  loading: boolean;
}
interface CorporatePackageToggleresponseActionType {
  type: typeof CORPORATE_PACKAGE_TOGGLE_RESPONSE;
  payload: CorporatePackageToggleResponse;
}
export interface CorporatePackageToggleLoading {
  type: typeof CORPORATE_PACKAGE_TOGGLE_LOADING;
}
export interface CorporatePackageToggleSuccess {
  type: typeof CORPORATE_PACKAGE_TOGGLE_SUCCESS;
  payload: CorporatePackageToggleResponse;
}
export interface CorporatePackageToggleFail {
  type: typeof CORPORATE_PACKAGE_TOGGLE_FAIL;
  payload: CorporatePackageToggleResponse;
}
export type CorporatePackageToggleTypes =
  | CorporatePackageToggleresponseActionType
  | CorporatePackageToggleLoading
  | CorporatePackageToggleSuccess
  | CorporatePackageToggleFail;
