import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { GetGenderListActionTypes } from "./ActionTypes";
import {
  getGenderListLoadingAction,
  getGenderListSuccessAction,
  getGenderListErrorAction,
  getGenderListUpdateAPIMsgAction,
  getGenderListAPIResClearAction,
} from "./Action";
import { GetGenderListRes } from "./Model";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetGenderListRes;
export const getGenderList = () => {
  return function (dispatch: Dispatch<GetGenderListActionTypes>) {
    dispatch(getGenderListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(baseUrl.baseUrl + `/indivoapp/api/gender/dropdown_list`, { headers: headers })
      .then((res) => {
        apiRes = res.data !== undefined ? res.data : ({} as GetGenderListRes);
        dispatch(getGenderListLoadingAction(false));
        dispatch(
          getGenderListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetGenderListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Gender list has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getGenderListLoadingAction(false));
        dispatch(
          getGenderListErrorAction(
            {} as GetGenderListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetGenderListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetGenderListActionTypes>) {
    dispatch(getGenderListUpdateAPIMsgAction(apiRes as GetGenderListRes, "", "", 0));
  };
};

export const clearGetGenderListAPIRes = () => {
  return function (dispatch: Dispatch<GetGenderListActionTypes>) {
    dispatch(getGenderListAPIResClearAction({} as GetGenderListRes, "", "", 0));
  };
};
