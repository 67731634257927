import { PageCount, GetViewOrganizationStaffListDetails } from "./Model";

// Patient Demographics Loading State
export const VIEW_ORG_STAFF_LIST_LOADING = "VIEW_ORG_STAFF_LIST_LOADING";
export const VIEW_ORG_STAFF_LIST_SUCCESS = "VIEW_ORG_STAFF_LIST_SUCCESS";
export const VIEW_ORG_STAFF_LIST_FAIL = "VIEW_ORG_STAFF_LIST_FAIL";
export const VIEW_ORG_STAFF_LIST_DATA = "VIEW_ORG_STAFF_LIST_DATA";
export const VIEW_ORG_STAFF_LIST_API_MSG = "VIEW_ORG_STAFF_LIST_API_MSG";
export const VIEW_ORG_STAFF_LIST_API_RES = "VIEW_ORG_STAFF_LIST_API_RES";

export interface ViewOrgStaffListState {
  viewOrgStaffListRes: GetViewOrganizationStaffListDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewOrganizationStaffListLoading {
  type: typeof VIEW_ORG_STAFF_LIST_LOADING;
  loading: boolean;
}
export interface ViewOrganizationStaffListSuccess {
  type: typeof VIEW_ORG_STAFF_LIST_SUCCESS;
  payload: GetViewOrganizationStaffListDetails;
  successMsg: string;
}
export interface ViewOrganizationStaffListFail {
  type: typeof VIEW_ORG_STAFF_LIST_FAIL;
  payload: GetViewOrganizationStaffListDetails;
  errorMsg: string;
  status: number;
}
export interface ViewOrganizationStaffListAPIMsg {
  type: typeof VIEW_ORG_STAFF_LIST_API_MSG;
  payload: GetViewOrganizationStaffListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewOrgStaffListAPIRes {
  type: typeof VIEW_ORG_STAFF_LIST_API_RES;
  payload: GetViewOrganizationStaffListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface ViewOrganizationStaffListDataAction {
  type: typeof VIEW_ORG_STAFF_LIST_DATA;
  payload: GetViewOrganizationStaffListDetails;
  pageCount: PageCount;
}

export type GetViewOrganizationStaffListActionTypes =
  | ViewOrganizationStaffListDataAction
  | ViewOrganizationStaffListLoading
  | ViewOrganizationStaffListSuccess
  | ViewOrganizationStaffListFail
  | ViewOrganizationStaffListAPIMsg
  | ViewOrgStaffListAPIRes;
