import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import AdminLoader from "./AdminLoader";
import PaymentIcon from "@mui/icons-material/Payment";
import PreviewIcon from "@mui/icons-material/Preview";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Card, CardContent, CardHeader, Grid, Box } from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import { paymentCallApi } from "../redux/PaymentAdmin/PaymentList/ApiCall";
import { AdminPaymentBody, PaymentList } from "../redux/PaymentAdmin/PaymentList/Model";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import PageLayout from "./Layout/PageLayout";
import { paymentStatusCallApi } from "../redux/PaymentAdmin/PaymentStatus/ApiCall";
import { PaymentStatusBody } from "../redux/PaymentAdmin/PaymentStatus/Model";
import MWResponseBanner from "../component/MWResponseBanner";
import { t } from "i18next";

export default function Payment() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const loadingMarkup = <AdminLoader />;
  const allPaymentValues = useSelector((state: AppState) => state.allPaymentListValue);
  const pageCount = useSelector(
    (state: AppState) => state.allPaymentListValue?.adminPaymentRes?.numberOfPages,
  );
  const paymentStatusValues = useSelector((state: AppState) => state.verifyPaymentvalue);

  function checkPaymentStatusDetails(id: number) {
    setBannerOpen(false);
    dispatch(paymentStatusCallApi({ paymentId: id } as PaymentStatusBody));
  }
  function capitalizeFirstLetter(status: string): string {
    const convertedStatus = status.charAt(0).toUpperCase() + status.slice(1);
    return convertedStatus;
  }
  const body = {
    paymentStatus: "",
  } as AdminPaymentBody;
  const paymentDatacolumns: GridColDef[] = [
    {
      field: "description",
      headerName: `${t("patientlistpage.des", { ns: ["home"] })!}`,
      flex: 2,
    },
    {
      field: "createDate",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "amount",
      headerName: `${t("payment.amt", { ns: ["home"] })!}`,
      maxWidth: 110,
    },
    {
      field: "paymentLink",
      headerName: `${t("payment.pay", { ns: ["home"] })!}`,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={
            allPaymentValues?.loading ||
            params.row.status === "Paid" ||
            params.row.status === "Cancelled" ||
            params.row.status === "Expired" ||
            params.row.status === "COMPLETED"
          }
          startIcon={<PaymentIcon />}
          onClick={() => {
            paymentLink(params.row.paymentLink);
          }}
        >
          {t("payment.payNow", { ns: ["home"] })!}
        </Button>
      ),
      flex: 1,
    },
    { field: "paidTime", headerName: `${t("payment.paidOn", { ns: ["home"] })!}`, flex: 1 },
    {
      field: "status",
      headerName: `${t("appointmentpage.status", { ns: ["home"] })!}`,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "id",
      headerName: `${t("payment.checkstat", { ns: ["home"] })!}`,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => {
            checkPaymentStatusDetails(params.row.id);
          }}
        >
          {t("corporatepackage.view", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(paymentCallApi(value, body));
  };

  function paymentLink(response: string) {
    window.open(response);
  }

  function reloadPaymentList() {
    dispatch(paymentCallApi(page, body));
  }

  const closeBannerState = (passedVal: boolean) => {
    setBannerOpen(passedVal);
  };
  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState("");
  const [bannerMsg, setBannerMsg] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(paymentCallApi(page, body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [paymentDataListValue, setPaymentData] = useState<any[]>([]);
  useEffect(() => {
    if (allPaymentValues !== undefined) {
      let paymentList = allPaymentValues?.adminPaymentRes?.paymentList?.map(
        (element: PaymentList) => ({
          id: element.id,
          description: element?.note !== "" ? element?.note : "",
          createDate:
            element?.create_date !== ""
              ? dayjs(element?.create_date).format("ddd, MMM D, YYYY")
              : "",
          amount: element?.amount !== "" ? element?.amount : "",
          paymentLink: element?.paymentLink !== "" ? element.paymentLink : "",
          paidTime:
            element?.paidTime !== null
              ? dayjs(element?.paidTime).format("ddd, MMM D, YYYY")
              : "Not Paid",
          status: element.status !== null ? capitalizeFirstLetter(element.status) : "",
        }),
      );
      setPaymentData(paymentList);
    } else setPaymentData([] as PaymentList[]);
  }, [allPaymentValues]);

  useEffect(() => {
    if (paymentStatusValues?.successMsg !== "") {
      setBannerOpen(true);
      setBannerColor("success");
      setBannerMsg(paymentStatusValues?.successMsg);
    } else if (paymentStatusValues?.errorMsg !== "") {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg(paymentStatusValues?.errorMsg);
    }
  }, [paymentStatusValues]);

  const tableMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">{t("payment.payListAdmin", { ns: ["home"] })!}</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
              >
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={() => reloadPaymentList()}
                  >
                    {t("common.reload", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {paymentDataListValue && paymentDataListValue.length ? (
          <DataGrid
            rows={paymentDataListValue}
            columns={paymentDatacolumns}
            hideFooter={true}
            autoHeight
            getRowId={(row) => row.id}
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {allPaymentValues.loading || paymentStatusValues?.loading ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle title={t("common.payment", { ns: ["home"] })} />
        {tableMarkup}
        {
          <MWResponseBanner
            bannerOpen={bannerOpen}
            bannerColor={bannerColor}
            responseMsg={bannerMsg}
            bannerClose={closeBannerState}
          />
        }
      </PageLayout>
    </Box>
  );
}
