import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ResponseTimeActionTypes } from "./ActionTypes";
import { ResponseTimeBody, ResponseTimeResponse } from "./Model";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  responseTimeAPIMsgAction,
  responseTimeAPIResClearAction,
  responseTimeErrorAction,
  responseTimeLoadingAction,
  responseTimeSuccessAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as ResponseTimeResponse;
export const responseTimeApi = (payload: ResponseTimeBody) => {
  return function (dispatch: Dispatch<ResponseTimeActionTypes>) {
    dispatch(responseTimeLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/patientMedicalData/responseTime_typeList`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(responseTimeLoadingAction(false));
        dispatch(
          responseTimeSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "Response time fetch successfully.",
          ),
        );
      })
      .catch((error) => {
        dispatch(responseTimeLoadingAction(false));
        dispatch(
          responseTimeErrorAction(
            {} as ResponseTimeResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const responseTimeAPIResMsg = () => {
  return function (dispatch: Dispatch<ResponseTimeActionTypes>) {
    dispatch(responseTimeAPIMsgAction(apiRes as ResponseTimeResponse, "", "", 0));
  };
};

export const clearresponseTimeAPIRes = () => {
  return function (dispatch: Dispatch<ResponseTimeActionTypes>) {
    dispatch(responseTimeAPIResClearAction({} as ResponseTimeResponse, "", "", 0));
  };
};
