import axios from "axios";
import { Dispatch } from "redux";
import { CreateInstantMeetingActionTypes } from "./ActionTypes";
import { CreateInstantMeetingDetailsBody, CreateInstantMeetingValueRes } from "./Model";
import {
  createInstantMeetingAPIResClearAction,
  createInstantMeetingErrorAction,
  createInstantMeetingLoadingAction,
  createInstantMeetingSuccessAction,
  createInstantMeetingUpdateAPIMsgAction,
} from "./Action";
import baseUrl from "../../ApiConfig";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "../Authentication/Interceptors";

let apiRes = {} as CreateInstantMeetingValueRes;
export const createInstantMeetingApi = (payload: CreateInstantMeetingDetailsBody) => {
  return function (dispatch: Dispatch<CreateInstantMeetingActionTypes>) {
    dispatch(createInstantMeetingLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/telehealthapp/api/telehealth/createInstantMeeting`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(createInstantMeetingLoadingAction(false));
        dispatch(
          createInstantMeetingSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "Meeting create successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(createInstantMeetingLoadingAction(false));
        dispatch(
          createInstantMeetingErrorAction(
            {} as CreateInstantMeetingValueRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateCreateInstantMeetingAPIResMsg = () => {
  return function (dispatch: Dispatch<CreateInstantMeetingActionTypes>) {
    dispatch(
      createInstantMeetingUpdateAPIMsgAction(apiRes as CreateInstantMeetingValueRes, "", "", 0),
    );
  };
};

export const clearCreateInstantMeetingAPIRes = () => {
  return function (dispatch: Dispatch<CreateInstantMeetingActionTypes>) {
    dispatch(createInstantMeetingAPIResClearAction({} as CreateInstantMeetingValueRes, "", "", 0));
  };
};
