import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { getCorporatePackagePaymentList } from "../../../redux/CorporatePackage/Payment/ApiCall";
import AdminLoader from "../../AdminLoader";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "../../../component/MWExceptionList";
import { t } from "i18next";

export default function InactivePaymentList(id: any) {
  const dispatch = useDispatch();
  let packageId = id.packageId;
  const loadingMarkup = <AdminLoader />;

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  // call Patient list Store
  const patientPaymentListLoadingValue = useSelector(
    (state: AppState) => state.patientteamPatientPaymentValue.loading,
  );
  const patientPaymentList = useSelector(
    (state: AppState) => state.patientteamPatientPaymentValue.paymentListValue,
  );

  let patientPaymentConfig = [];
  for (var i = 0; i < patientPaymentList.length; i++) {
    let create_date = patientPaymentList[i].hasOwnProperty("create_date")
      ? patientPaymentList[i].create_date == null
        ? ""
        : new Date(patientPaymentList[i].create_date).toLocaleDateString("en-US", DATE_OPTIONS) +
          " at " +
          new Date(patientPaymentList[i].create_date).getHours() +
          ":" +
          new Date(patientPaymentList[i].create_date).getMinutes()
      : "";
    let paidTime = patientPaymentList[i].hasOwnProperty("paidTime")
      ? patientPaymentList[i].paidTime == null
        ? ""
        : new Date(patientPaymentList[i].paidTime).toLocaleDateString("en-US", DATE_OPTIONS) +
          " at " +
          new Date(patientPaymentList[i].paidTime).getHours() +
          ":" +
          new Date(patientPaymentList[i].paidTime).getMinutes()
      : "";
    let amount =
      patientPaymentList[i].amount === null || patientPaymentList[i].amount === ""
        ? ""
        : patientPaymentList[i].amount;
    let note =
      patientPaymentList[i].note === null || patientPaymentList[i].note === ""
        ? ""
        : patientPaymentList[i].note;
    let paymentLink =
      patientPaymentList[i].paymentLink === null || patientPaymentList[i].paymentLink === ""
        ? ""
        : patientPaymentList[i].paymentLink;
    let status =
      patientPaymentList[i].status === null || patientPaymentList[i].status === ""
        ? ""
        : patientPaymentList[i].status;
    patientPaymentConfig.push([
      <Button
        variant="outlined"
        onClick={() => paymentLink(paymentLink)}
        startIcon={<PaymentIcon />}
      >
        {t("payment.payNow", { ns: ["home"] })}
      </Button>,
      amount,
      note,
      status,
      create_date,
      paidTime,
    ]);
  }
  const patientPaymentListTableValue: any[] = patientPaymentConfig;

  // get corporate package payment body
  const corporatePackageTeamPatientPaymentBody = {
    packageId: Number(packageId),
  };
  // reload corporate package payment API
  const reloadPatientPaymentList = () => {
    dispatch(getCorporatePackagePaymentList(corporatePackageTeamPatientPaymentBody));
  };

  useEffect(() => {
    dispatch(getCorporatePackagePaymentList(corporatePackageTeamPatientPaymentBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const actualPageMarkup = (
    <Card>
      <CardHeader title={t("payment.patientPayDetails", { ns: ["home"] })}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={reloadPatientPaymentList}
              sx={{ textTransform: "none" }}
            >
              {t("common.reload", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </CardHeader>
      <CardContent>
        {patientPaymentListTableValue && patientPaymentListTableValue.length ? (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("payment.paymentLink", { ns: ["home"] })}</TableCell>
                  <TableCell>{t("payment.amt", { ns: ["home"] })}</TableCell>
                  <TableCell>{t("common.note", { ns: ["home"] })}</TableCell>
                  <TableCell>{t("appointmentpage.status", { ns: ["home"] })}</TableCell>
                  <TableCell>{t("appointmentpage.createdate", { ns: ["home"] })}</TableCell>
                  <TableCell>{t("payment.paidDate", { ns: ["home"] })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientPaymentListTableValue.map((row: any) => (
                  <TableRow
                    key={row.description}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row[0]}</TableCell>
                    <TableCell align="left">{row[1]}</TableCell>
                    <TableCell align="left">{row[2]}</TableCell>
                    <TableCell align="left">{row[3]}</TableCell>
                    <TableCell align="left">{row[4]}</TableCell>
                    <TableCell align="left">{row[5]}</TableCell>
                    <TableCell align="left">{row[6]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      <Container maxWidth="xl">
        {patientPaymentListLoadingValue === true ? loadingMarkup : ""}
        {actualPageMarkup}
      </Container>
    </Box>
  );
}
