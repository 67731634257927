import {
  ADD_PATIENT_TEAM_TYPE,
  AdminGetPatientTeamListActionTypes,
  ALL_PATIENT_TEAM_LIST,
  AnswerPatientTeamTypes,
} from "./ActionTypes";
import { AdminPatientTeamList, PatientTeamListPageCount } from "./Model";
// Get Patient Team List Action
export const getPatientTeamListAction = (
  patientTeamList: AdminPatientTeamList,
  patientListPageCount: PatientTeamListPageCount,
): AdminGetPatientTeamListActionTypes => {
  return {
    type: ALL_PATIENT_TEAM_LIST,
    payload: patientTeamList,
    patientListPageCount: patientListPageCount,
  };
};
// Add Patient Team Action
export const addpatientTeamAction = (message: any, status: number): AnswerPatientTeamTypes => {
  return {
    type: ADD_PATIENT_TEAM_TYPE,
    message: message,
    status: status,
  };
};
