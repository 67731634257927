import {
  GET_DOCTOR_CONTACT_DETAILS_FAIL,
  GET_DOCTOR_CONTACT_DETAILS_LOADING,
  GET_DOCTOR_CONTACT_DETAILS_SUCCESS,
  GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG,
  GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES,
  GetDoctorContactDetailsActionTypes,
} from "./ActionTypes";
import { GetDoctorContactDetailsRes } from "./Model";

export const getDoctorContactDetailsLoadingAction = (
  loading: boolean,
): GetDoctorContactDetailsActionTypes => {
  return {
    type: GET_DOCTOR_CONTACT_DETAILS_LOADING,
    loading: loading,
  };
};

export const getDoctorContactDetailsSuccessAction = (
  getDoctorContactDetailsResponse: GetDoctorContactDetailsRes,
  successMsg: string,
): GetDoctorContactDetailsActionTypes => {
  return {
    type: GET_DOCTOR_CONTACT_DETAILS_SUCCESS,
    payload: getDoctorContactDetailsResponse,
    successMsg: successMsg,
  };
};

export const getDoctorContactDetailsErrorAction = (
  getDoctorContactDetailsResponse: GetDoctorContactDetailsRes,
  errMsg: string,
  status: number,
): GetDoctorContactDetailsActionTypes => {
  return {
    type: GET_DOCTOR_CONTACT_DETAILS_FAIL,
    payload: getDoctorContactDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorContactDetailsUpdateAPIMsgAction = (
  getDoctorContactDetailsResponse: GetDoctorContactDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorContactDetailsActionTypes => {
  return {
    type: GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG,
    payload: getDoctorContactDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorContactDetailsAPIResClearAction = (
  getDoctorContactDetailsResponse: GetDoctorContactDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorContactDetailsActionTypes => {
  return {
    type: GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES,
    payload: getDoctorContactDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
