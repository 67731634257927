import {
  AddSummaryActionTypes,
  AddWorkScheduleActionTypes,
  ADD_SUMMARY_LIST,
  ADD_WORKSCHEDULE_STATUS,
  AdminAppointmentGetActionTypes,
  APPOINTMENT_LIST,
  ContactDetailsActionTypes,
  CONTACT_DETAILS_LIST,
  DeleteWorkScheduleActionTypes,
  DELETE_WORK_SCHEDULE_LIST,
  DoctorDetailsActionTypes,
  DOCTOR_DETAILS_LIST,
  WeekDayListActionTypes,
  WEEKDAY_LIST,
  WorkScheduleActionTypes,
  WORK_SCHEDULE_LIST,
} from "./ActionTypes";
import {
  AddSummaryResponse,
  AdminAppointmentList,
  ContactDetail,
  Message,
  Status,
  WorkSchedule,
  WorkScheduleDeleteResponse,
  WeekdayListData,
  DoctorDetailsData,
} from "./Model";

export const getAptAction = (
  appointment: AdminAppointmentList[],
): AdminAppointmentGetActionTypes => {
  return {
    type: APPOINTMENT_LIST,
    payload: appointment,
  };
};

export const addSummaryAction = (response: AddSummaryResponse): AddSummaryActionTypes => {
  return {
    type: ADD_SUMMARY_LIST,
    payload: response,
  };
};

export const contactDetailAction = (
  contactDetailValue: ContactDetail,
): ContactDetailsActionTypes => {
  return {
    type: CONTACT_DETAILS_LIST,
    payload: contactDetailValue,
  };
};

export const workScheduleAction = (workscheduleValue: WorkSchedule[]): WorkScheduleActionTypes => {
  return {
    type: WORK_SCHEDULE_LIST,
    payload: workscheduleValue,
  };
};

export const deleteworkScheduleAction = (
  deleteworkscheduleValue: WorkScheduleDeleteResponse,
): DeleteWorkScheduleActionTypes => {
  return {
    type: DELETE_WORK_SCHEDULE_LIST,
    payload: deleteworkscheduleValue,
  };
};
// weekday list
export const getWeekdayAction = (
  weekdayListData: WeekdayListData[],
  message: Message,
  status: Status,
): WeekDayListActionTypes => {
  return {
    type: WEEKDAY_LIST,
    payload: weekdayListData,
    message: message,
    status: status,
  };
};
//  add work schedule list
export const AddWorkSchedule = (AddWorkScheduleResponse: any): AddWorkScheduleActionTypes => {
  return {
    type: ADD_WORKSCHEDULE_STATUS,
    payload: AddWorkScheduleResponse,
  };
};
// doctor details
export const getDoctorDetailsAction = (
  doctorDetailsValue: DoctorDetailsData,
  message: Message,
  status: Status,
): DoctorDetailsActionTypes => {
  return {
    type: DOCTOR_DETAILS_LIST,
    payload: doctorDetailsValue,
    message: message,
    status: status,
  };
};
