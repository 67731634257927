import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorOnboardingRejectList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Card, CardContent, CardHeader, Container, Stack, fabClasses } from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import { t } from "i18next";

export default function DoctorsOnboardingRequest(props: any) {
  const dispatch = useDispatch();
  const { docOndoardingRejectLoading } = props;
  const [isLoading, setIsLoading] = useState(false);
  docOndoardingRejectLoading(isLoading);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  // api call from store....
  const docotorList = useSelector((state: AppState) => state.doctorRejectValue);
  const doctorRejectList = docotorList.doctorRejectValue;
  useEffect(() => {
    if (doctorRejectList !== undefined) {
      let dataPointArr = doctorRejectList.map((element: any) => ({
        id: element.id,
        doctorId: element.id,
        name: element.fullName,
        qualification: element.qualification,
        specialty: element.specialty,
        yearsOfExperience: element.yearsOfExperience,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docotorList]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("docOnboarding.name", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "qualification",
      headerName: `${t("doctorlistpage.qualification", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "specialty",
      headerName: `${t("appointmentpage.speciality", { ns: ["home"] })!}`,
      type: "string",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "yearsOfExperience",
      headerName: `${t("docOnboarding.yearofExp", { ns: ["home"] })!}`,
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDoctorOnboardingRejectList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const DoctorListTable = (
    <Card>
      <CardHeader />
      <CardContent>
        {doctorRejectList && doctorRejectList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {docotorList.loading === true
        ? docOndoardingRejectLoading(true)
        : docOndoardingRejectLoading(fabClasses)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {DoctorListTable}
        </Stack>
      </Container>
    </Box>
  );
}
