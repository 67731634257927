import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import {
  AlertColor,
  Box,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import { t } from "i18next";
import MWSnackbar from "../../../component/MWSnackbar";
import { addNotesByDr, getDoctorNoteListForDoctor } from "../../../redux/Notes/ApiCall";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  addNoteDialogEnable?: boolean | any;
  addNoteDialogUpdateState?: boolean | any;
  doctorID?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: "8px",
  },
}))(MuiDialogActions);

export default function AddNoteDialog({
  addNoteDialogEnable,
  addNoteDialogUpdateState,
  doctorID,
}: Props) {
  const dispatch = useDispatch();
  // Call Add Answer store
  const addQuestionAnswerValue = useSelector((state: AppState) => state.adminQuestionAnswerValue);

  const [addNoteText, setAddNoteText] = useState("");
  const [notesError, setNotesError] = useState("");
  const [addNoteMsgType, setAddNoteMsgType] = useState<AlertColor>("success");
  const [addNoteMsg, setAddNoteMsg] = useState("");

  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addNoteDialogUpdateState(false);
    dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
  };

  React.useEffect(() => {
    setDialogState(addNoteDialogEnable);
  }, [addNoteDialogEnable]);

  // Get Doctor Note List Body
  const getDrNoteListBody = {
    doctorId: doctorID,
    isActive: true,
  };
  useEffect(() => {
    if (addQuestionAnswerValue?.payload?.message !== undefined) {
      setAddNoteMsg(addQuestionAnswerValue?.payload?.message);
      setAddNoteMsgType("success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addQuestionAnswerValue]);

  // Add Dr Note Body
  const addNoteBody = {
    doctorId: doctorID,
    note: addNoteText,
  };

  // Add notes for Doctor
  const addNotes = () => {
    if (addNoteText === "") {
      setNotesError("Please provide notes");
    } else {
      setNotesError("");
      dispatch(addNotesByDr(addNoteBody));
    }
  };

  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
    }
  };

  return (
    <div>
      <Dialog open={dialogState} onClose={handleDialogClose} fullWidth>
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">{t("docnotelist.addnote", { ns: ["home"] })!}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <TextField
                value={addNoteText}
                label={t("docnotelist.docnote", { ns: ["home"] })!}
                placeholder={t("docnotelist.placeholder", { ns: ["home"] })!}
                id="notesID"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAddNoteText(event.target.value);
                }}
                fullWidth
                multiline
                rows={4}
              />
              <Typography sx={{ color: "#c62828", margin: "5px" }}>{notesError}</Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Button variant="outlined" onClick={handleDialogClose}>
                {t("docnotelist.cancel", { ns: ["home"] })!}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={addNotes}>
                {t("docnotelist.submit", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {addNoteMsg !== undefined ? (
          <Box my={2}>
            <MWSnackbar msg={addNoteMsg} type={addNoteMsgType} alertClose={alertCloseAction} />
          </Box>
        ) : null}
      </Dialog>
    </div>
  );
}
