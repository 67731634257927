import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { t } from "i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import MWExceptionList from "../../../../component/MWExceptionList";
import { getChiefComplaintList } from "../../../../redux/Patient/FilePreparation/ApiCall";

export default function ImportedDataList() {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const importedValue = useSelector((state: AppState) => state.ccValue);
  const [imageInfo, setImageInfo] = useState("");

  const [importedDataListValue, setImportedDataListValue] = useState<any[]>([]);

  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive],
  );

  const truncate = (str: string) => {
    return str.length > 0 ? str.substring(0, 60) + "..." : str;
  };
  const getImportedDataBody = {
    caseId: caseid,
    secondOpinionUiSection: "importedData",
  };

  // Documents List
  useEffect(() => {
    dispatch(getChiefComplaintList(getImportedDataBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (importedValue?.ccValue !== undefined) {
      let resData = importedValue?.ccValue;
      let importedDataConfig = [];
      for (var i = 0; i < resData.length; i++) {
        let description = resData[i].description == null ? "" : truncate(resData[i].description);
        let dateStart =
          resData[i].startDate == null
            ? ""
            : new Date(resData[i].startDate).toLocaleDateString("en-US", DATE_OPTIONS);
        let dateEnd =
          resData[i].endDate == null
            ? ""
            : new Date(resData[i].endDate).toLocaleDateString("en-US", DATE_OPTIONS);
        let supportingDocuments =
          resData[i].patientDocument == null ? "" : resData[i].patientDocument.document;
        importedDataConfig.push([
          <Button
            onClick={() => {
              setDocViwerActive(true);
              setImageInfo(supportingDocuments);
            }}
          ></Button>,
          description,
          dateStart,
          dateEnd,
        ]);
      }
      setImportedDataListValue(importedDataConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedValue]);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  const inportedDataTable = (
    <Box pt={2}>
      <Card>
        <CardHeader title={t("importdadaPage.importData", { ns: ["home"] })}></CardHeader>
        <CardContent>
          {importedDataListValue && importedDataListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("addpatientpage.doc", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("appointment.desc", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("common.stDate", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("appointment.enddate", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("common.del", { ns: ["home"] })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {importedDataListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                      <TableCell align="left">{row[4]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      <br />
    </Box>
  );

  const docViewerComponent = (
    <Dialog open={docViwerActive} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Grid item>
            <IconButton onClick={handleDocViewerChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <p>
          <iframe src={imageInfo} title="Small document" width="100%" height="700" frameBorder="0">
            This is an embedded{" "}
            <a target="_blank" href="http://office.com" rel="noopener noreferrer">
              Microsoft Office
            </a>{" "}
            document, powered by{" "}
            <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
              Office Online
            </a>
            .
          </iframe>
        </p>
      </DialogContent>
    </Dialog>
  );
  return (
    <Box>
      {inportedDataTable}
      {docViewerComponent}
    </Box>
  );
}
