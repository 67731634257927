import {
  ImportPhoneActionTypes,
  GetPatientDemographicsState,
  IMPORT_FROM_PHONE_SUCCESS,
  IMPORT_FROM_PHONE_LOADING,
  IMPORT_FROM_PHONE_FAIL,
} from "./ActionTypes";
import { ImportFromPhoneResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  patientDemographicsRes: {} as ImportFromPhoneResponse,
};
export const importFromPhoneReducer = (
  state = initialStateGetPosts,
  action: ImportPhoneActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case IMPORT_FROM_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDemographicsRes: action.payload,
      };
    case IMPORT_FROM_PHONE_LOADING:
      return {
        ...state,
        loading: true,
        patientDemographicsRes: {} as ImportFromPhoneResponse,
      };
    case IMPORT_FROM_PHONE_FAIL:
      return {
        ...state,
        patientDemographicsRes: {} as ImportFromPhoneResponse,
        loading: false,
      };
    default:
      return state;
  }
};
