import {
  GetCorporatePackagePaymentListStateType,
  CorporatePackagePaymentListTypes,
  CORPORATE_PACKAGE_PAYMENT_LIST_LOADING,
  CORPORATE_PACKAGE_PAYMENT_LIST_SUCCESS,
  CORPORATE_PACKAGE_PAYMENT_LIST_FAIL,
} from "./ActionTypes";

const initialStateGetPaymentList: GetCorporatePackagePaymentListStateType = {
  loading: false,
  paymentListValue: [],
  status: Object.assign({}),
};

export const getCorporatePackagePaymentListReducer = (
  state = initialStateGetPaymentList,
  action: CorporatePackagePaymentListTypes,
): GetCorporatePackagePaymentListStateType => {
  switch (action.type) {
    case CORPORATE_PACKAGE_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentListValue: action.payload,
      };
    case CORPORATE_PACKAGE_PAYMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        paymentListValue: [],
      };
    case CORPORATE_PACKAGE_PAYMENT_LIST_FAIL:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    default:
      return state;
  }
};
