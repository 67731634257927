import { PatientImportConfigListValueRes } from "./Model";
export const PATIENT_IMPORT_CONFIG_LIST_LOADING = " PATIENT_IMPORT_CONFIG_LIST_LOADING";
export const PATIENT_IMPORT_CONFIG_LIST_SUCCESS = " PATIENT_IMPORT_CONFIG_LIST_SUCCESS";
export const PATIENT_IMPORT_CONFIG_LIST_FAIL = " PATIENT_IMPORT_CONFIG_LIST_FAIL";
export const PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG =
  " PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG";
export const PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES =
  " PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES";

export interface PatientImportConfigListState {
  patientImportConfigListRes: PatientImportConfigListValueRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientImportConfigListLoading {
  type: typeof PATIENT_IMPORT_CONFIG_LIST_LOADING;
  loading: boolean;
}
export interface PatientImportConfigListSuccess {
  type: typeof PATIENT_IMPORT_CONFIG_LIST_SUCCESS;
  payload: PatientImportConfigListValueRes;
  successMsg: string;
}
export interface PatientImportConfigListFail {
  type: typeof PATIENT_IMPORT_CONFIG_LIST_FAIL;
  payload: PatientImportConfigListValueRes;
  errorMsg: string;
  status: number;
}
export interface PatientImportConfigListUpdateAPIMsg {
  type: typeof PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG;
  payload: PatientImportConfigListValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientImportConfigListUpdateAPIRes {
  type: typeof PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES;
  payload: PatientImportConfigListValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientImportConfigListActionTypes =
  | PatientImportConfigListLoading
  | PatientImportConfigListSuccess
  | PatientImportConfigListFail
  | PatientImportConfigListUpdateAPIMsg
  | PatientImportConfigListUpdateAPIRes;
