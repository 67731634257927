import {
  EDIT_PATIENT_IMPORT_CONFIG_LIST_FAIL,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_LOADING,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
  PatientImportConfigListActionTypes,
} from "./ActionTypes";
import { EditPatientImportConfigValueRes } from "./Model";

export const editPatientImportConfigListLoadingAction = (
  loading: boolean,
): PatientImportConfigListActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_LIST_LOADING,
    loading: loading,
  };
};

export const editPatientImportConfigListSuccessAction = (
  editPatientImportConfigListResponse: EditPatientImportConfigValueRes,
  successMsg: string,
): PatientImportConfigListActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
    payload: editPatientImportConfigListResponse,
    successMsg: successMsg,
  };
};

export const editPatientImportConfigListErrorAction = (
  editPatientImportConfigListResponse: EditPatientImportConfigValueRes,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_LIST_FAIL,
    payload: editPatientImportConfigListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editPatientImportConfigListUpdateAPIMsgAction = (
  editPatientImportConfigListResponse: EditPatientImportConfigValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
    payload: editPatientImportConfigListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editPatientImportConfigListAPIResClearAction = (
  editPatientImportConfigListResponse: EditPatientImportConfigValueRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientImportConfigListActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
    payload: editPatientImportConfigListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
