import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import EditIcon from "@mui/icons-material/Edit";
import { t } from "i18next";
import { changeNotesForDoctorStatus } from "../../redux/Notes/ApiCall";
import { Alert, Button, Box, Snackbar } from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import { doctorActiveListApi } from "../../redux/Notes/DoctorActiveNote/API";
import { DoctorActiveListModel } from "../../redux/Notes/DoctorActiveNote/Model";
import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";

export default function ActiveNotes(props: any) {
  const dispatch = useDispatch();
  const { activeNoteLoader } = props;
  const doctorId = localStorage.getItem("doctorID");

  // Call Note List for Dr Store
  const docNoteListFromAPI = useSelector((state: AppState) => state.doctorActiveNoteListValue);
  const AddChiefComplaintStatus = useSelector((state: AppState) => state.addNotesForDrValue.status);

  // Add Dr Notes success toast
  const [successAddNotesForDoctor, setSuccessAddNotesForDoctor] = useState(false);
  const toggleAddNotesForDrSuccessToastActive = () => {
    setSuccessAddNotesForDoctor((successAddNotesForDoctor) => !successAddNotesForDoctor);
  };

  const toastSuccessAddNotesForDoctorMarkup = successAddNotesForDoctor ? (
    <Snackbar
      open={successAddNotesForDoctor}
      autoHideDuration={6000}
      onClose={toggleAddNotesForDrSuccessToastActive}
    >
      <Alert
        severity="success"
        onClose={toggleAddNotesForDrSuccessToastActive}
        sx={{ width: "100%" }}
      >
        Note added successfully
      </Alert>
    </Snackbar>
  ) : null;

  // Add Dr Notes error toast
  const [errorAddNotesForDoctorToastActive, setErrorAddNotesForDoctorToastActive] = useState(false);
  const toggleAddNotesForDrErrorToastActive = () => {
    setErrorAddNotesForDoctorToastActive(
      (errorAddNotesForDoctorToastActive) => !errorAddNotesForDoctorToastActive,
    );
  };
  const toastErrorAddNotesForDoctorMarkup = errorAddNotesForDoctorToastActive ? (
    <Snackbar
      open={errorAddNotesForDoctorToastActive}
      autoHideDuration={6000}
      onClose={toggleAddNotesForDrErrorToastActive}
    >
      <Alert severity="error" onClose={toggleAddNotesForDrErrorToastActive} sx={{ width: "100%" }}>
        Something went wrong
      </Alert>
    </Snackbar>
  ) : null;

  // call Change Dr. Notes Status Store
  const changeDrNoteStatusLoadingvalue = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.loading,
  );
  const changeDrNoteStatusStatus = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.status,
  );
  const changeDrNoteStatusMessage = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.message,
  );

  // Change Dr notes status Success toast
  const [successChangeDrNoteStatusToastActive, setSuccessChangeDrNoteStatusToastActive] =
    useState(false);
  const toggleSuccessChangeDrNoteStatusActive = () => {
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) => !successChangeDrNoteStatusToastActive,
    );
  };

  const toastChangeDrNoteStatusSuccessMarkup = successChangeDrNoteStatusToastActive ? (
    <Snackbar
      open={successChangeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessChangeDrNoteStatusActive}
    >
      <Alert
        severity="success"
        onClose={toggleSuccessChangeDrNoteStatusActive}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Change Dr notes status Error toast
  const [changeDrNoteStatusToastActive, setErrorChangeDrNoteStatusToastActive] = useState(false);
  const handleErrorChangeDrNoteStatusToastChange = () => {
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive,
    );
  };
  const toastChangeDrNoteStatusErrorMarkup = changeDrNoteStatusToastActive ? (
    <Snackbar
      open={changeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={handleErrorChangeDrNoteStatusToastChange}
    >
      <Alert
        severity="success"
        onClose={handleErrorChangeDrNoteStatusToastChange}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Get Doctor Note List Body
  const getDrNoteListBody = {
    doctorId: doctorId,
    isActive: true,
  } as DoctorActiveListModel;
  // Notes Status change API
  function toggleNoteStatus(response: any) {
    dispatch(changeNotesForDoctorStatus(response));
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) => !successChangeDrNoteStatusToastActive,
    );
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive,
    );
  }

  useEffect(() => {
    dispatch(doctorActiveListApi(getDrNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    if (docNoteListFromAPI?.doctorActiveNoteListRes?.noteList !== undefined) {
      let dataPointArr = docNoteListFromAPI?.doctorActiveNoteListRes?.noteList.map(
        (element: any) => ({
          id: element.id,
          note: element.note,
          createBy: element.createdBy.user.first_name + " " + element.createdBy.user.last_name,
          modifyBy: element.modifiedBy.user.first_name + " " + element.modifiedBy.user.last_name,
          createdDate: dayjs(element.createdDate).format("MMMM D, YYYY"),
          modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docNoteListFromAPI]);

  const columns: GridColDef[] = [
    {
      field: "note",
      headerName: `${t("docnotelist.note", { ns: ["home"] })!}`,
      width: 150,
    },
    {
      field: "createdDate",
      headerName: `${t("docnotelist.createddate", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "createBy",
      headerName: `${t("docnotelist.createdby", { ns: ["home"] })!}`,
      width: 350,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("docnotelist.modifiedstatus", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            toggleNoteStatus(params.value);
          }}
        >
          {
            t("docnotelist.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "modifyBy",
      headerName: `${t("docnotelist.modifyby", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: `${t("docnotelist.modifydate", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
  ];

  // Active Notes List
  const getActiveNoteList = (
    <Box pt={3}>
      {docNoteListFromAPI?.doctorActiveNoteListRes?.noteList !== undefined ? (
        docNoteListFromAPI?.doctorActiveNoteListRes?.noteList.length > 0 ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );

  return (
    <Box>
      {docNoteListFromAPI.loading === true || changeDrNoteStatusLoadingvalue === true
        ? activeNoteLoader(true)
        : activeNoteLoader(false)}
      {getActiveNoteList}
      {AddChiefComplaintStatus! === 1 ? toastSuccessAddNotesForDoctorMarkup : ""}
      {AddChiefComplaintStatus! === 0 ? toastErrorAddNotesForDoctorMarkup : ""}
      {changeDrNoteStatusStatus! === 1 ? toastChangeDrNoteStatusSuccessMarkup : ""}
      {changeDrNoteStatusStatus! === 0 ? toastChangeDrNoteStatusErrorMarkup : ""}
    </Box>
  );
}
