import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { EditCaseActionTypes } from "./ActionTypes";
import { EditCaseBody, EditCaseResponse } from "./Model";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";
import {
  editCaseAPIMsgAction,
  editCaseAPIResClearAction,
  editCaseErrorAction,
  editCaseLoadingAction,
  editCaseSuccessAction,
} from "./Actions";

let apiRes = {} as EditCaseResponse;
export const editPatientCase = (payload: EditCaseBody) => {
  return function (dispatch: Dispatch<EditCaseActionTypes>) {
    dispatch(editCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/patient/edit_patient_case`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(editCaseLoadingAction(false));
        dispatch(
          editCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient Case updated successfully.",
          ),
        );
      })
      .catch((error) => {
        dispatch(editCaseLoadingAction(false));
        dispatch(
          editCaseErrorAction(
            {} as EditCaseResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const editCaseAPIResMsg = () => {
  return function (dispatch: Dispatch<EditCaseActionTypes>) {
    dispatch(editCaseAPIMsgAction(apiRes as EditCaseResponse, "", "", 0));
  };
};

export const clearEditCaseAPIRes = () => {
  return function (dispatch: Dispatch<EditCaseActionTypes>) {
    dispatch(editCaseAPIResClearAction({} as EditCaseResponse, "", "", 0));
  };
};
