import {
  GetPatientListStateType,
  PatientListTypes,
  PATIENT_LIST_FAIL,
  PATIENT_LIST_LOADING,
  PATIENT_LIST_SUCCESS,
} from "./ActionTypes";

const initialStateGetPatientList: GetPatientListStateType = {
  loading: false,
  patientListValue: [],
  status: Object.assign({}),
};

export const getPatientListReducer = (
  state = initialStateGetPatientList,
  action: PatientListTypes,
): GetPatientListStateType => {
  switch (action.type) {
    case PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        patientListValue: action.payload,
      };
    case PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        patientListValue: [],
      };
    case PATIENT_LIST_FAIL:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    default:
      return state;
  }
};
