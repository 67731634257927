import { InactiveCorporatePackagesActionTypes, INACTIVE_CORPORATE_LIST } from "./ActionTypes";
import { InactiveCorporatePackageList } from "./Model";

export const getInactiveCorporateListActions = (
  inactiveCorporatePackageList: InactiveCorporatePackageList[],
): InactiveCorporatePackagesActionTypes => {
  return {
    type: INACTIVE_CORPORATE_LIST,
    payload: inactiveCorporatePackageList,
  };
};
