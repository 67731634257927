import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DrApproveRejectActionTypes } from "./ActionTypes";
import { DocotorOnboardIncompleteBody, DoctorOnboardIncompleteResponse } from "./Model";
import {
  drApproveRejectAPIResClearAction,
  drApproveRejectErrorAction,
  drApproveRejectLoadingAction,
  drApproveRejectSuccessAction,
  drApproveRejectAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as DoctorOnboardIncompleteResponse;
export const onBoardDoctorIncomplete = (payload: DocotorOnboardIncompleteBody) => {
  return function (dispatch: Dispatch<DrApproveRejectActionTypes>) {
    dispatch(drApproveRejectLoadingAction(true));
    axios
      .post(Url.baseUrl + `/adminapp/api/onboardRequestApproval/`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(drApproveRejectLoadingAction(false));
        dispatch(
          drApproveRejectSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. award has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(drApproveRejectLoadingAction(false));
        dispatch(
          drApproveRejectErrorAction(
            {} as DoctorOnboardIncompleteResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDrApproveRejectAPIResMsg = () => {
  return function (dispatch: Dispatch<DrApproveRejectActionTypes>) {
    dispatch(drApproveRejectAPIMsgAction(apiRes as DoctorOnboardIncompleteResponse, "", "", 0));
  };
};

export const clearDrApproveRejectAPIRes = () => {
  return function (dispatch: Dispatch<DrApproveRejectActionTypes>) {
    dispatch(drApproveRejectAPIResClearAction({} as DoctorOnboardIncompleteResponse, "", "", 0));
  };
};
