import React, { useEffect, useState } from "react";
import { Box, Chip, Grid, Tab, Typography } from "@mui/material";
import ImportFromPhone from "./ImportFromPhone";
import ImportFromEmail from "./ImportFromEmail";
import ImportFromWhatsApp from "./ImportFromWhatsApp";
import ImportFromChat from "./ImportFromChat";
import AdminLoader from "../AdminLoader";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import { t } from "i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { importCallApi } from "../../redux/ImportData/ImportFromPhone/ApiCall";

export default function ImportData() {
  const [selected, setSelected] = useState(1);
  const dispatch = useDispatch();
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };
  const loadingMarkup = <AdminLoader />;
  const [importFromPhoneLoader, setImportFromPhoneLoader] = useState(false);
  const [listCount, setListCount] = React.useState("0");
  const [emailListCount] = React.useState("0");
  const [chatListCount] = React.useState("0");
  const [whatsAppListCount] = React.useState("0");
  const importListValue = useSelector((state: AppState) => state.importPhoneDataValue);

  useEffect(() => {
    importListValue?.importCallRes?.callList !== undefined
      ? setListCount(String(importListValue?.importCallRes?.callList.length))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importListValue, selected]);

  const reloadImportedPhoneList = () => {
    dispatch(importCallApi());
  };

  const importPage = (
    <Grid>
      <Grid item>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>{t("importdadaPage.impFromPhn", { ns: ["home"] })!}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={listCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadImportedPhoneList();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("importdadaPage.impFromEmail", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={emailListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        //drRejectListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("importdadaPage.impFromWhatsApp", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={chatListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        //drIncompleteListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="3"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("importdadaPage.impFromChat", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={whatsAppListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        //drIncompleteListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="4"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ImportFromPhone importFromPhoneLoader={setImportFromPhoneLoader} />
            </TabPanel>
            <TabPanel value="2">
              <ImportFromEmail />
            </TabPanel>
            <TabPanel value="3">
              <ImportFromWhatsApp />
            </TabPanel>
            <TabPanel value="4">
              <ImportFromChat />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {importFromPhoneLoader ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle title={t("importdadaPage.importData", { ns: ["home"] })} />
        {importPage}
      </PageLayout>
    </Box>
  );
}
