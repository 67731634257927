import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTelehealthMeetingList } from "../../redux/Telehealth/API";
import { AppState } from "../../redux/store/Store";
import MWExceptionList from "../../component/MWExceptionList";
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { MeetingList } from "../../redux/Telehealth/Model";
import RefreshIcon from "@mui/icons-material/Refresh";
import { t } from "i18next";

export default function ActiveMeeting(props: any) {
  const dispatch = useDispatch();
  const { activemeetingLoader } = props;
  // Meeting List Body
  const activeMeetingListBody = {
    type: true,
  };
  // Meeting List GET API
  const getMeetingList = () => {
    dispatch(getTelehealthMeetingList(activeMeetingListBody));
  };
  // Call Meeting List Store
  const activeMeetingListFromModel = useSelector((state: AppState) => state.telehealthMeetingValue);
  const activeMeetingListLoadingValues = useSelector(
    (state: AppState) => state.telehealthMeetingValue.loading,
  );
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  const activeMeetingListColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 200,
    },
    {
      field: "patientCase",
      headerName: `${t("telehealthpage.patientCaseId", { ns: ["home"] })}`,
      width: 250,
    },
    {
      field: "displayName",
      headerName: `${t("telehealthpage.appoinmentname", { ns: ["home"] })}`,
      width: 300,
    },
    {
      field: "appointmentDate",
      headerName: `${t("telehealthpage.appoDateTime", { ns: ["home"] })}`,
      width: 300,
    },
    {
      field: "dateCreated",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })}`,
      width: 300,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTelehealthMeetingList(activeMeetingListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeMeetingListFromModel?.teleHealthRes?.meetingList !== undefined) {
      let activeMeetingList = activeMeetingListFromModel?.teleHealthRes?.meetingList.map(
        (element: any) => ({
          id: element.id,
          patientCase: element.patientCase,
          displayName: element.displayName,
          appointmentDate: dayjs(element.appointmentDate).format("ddd, MMMM D, YYYY h:mm A"),
          dateCreated: dayjs(element.dateCreated).format("ddd, MMMM D, YYYY"),
        }),
      );
      setDataArray(activeMeetingList);
    } else setDataArray([] as MeetingList[]);
  }, [activeMeetingListFromModel]);

  const appointmentTableBody = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">
                {t("telehealthpage.actMeetDetails", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <Grid item>
                  <Button variant="outlined" onClick={getMeetingList} startIcon={<RefreshIcon />}>
                    {t("common.reload", { ns: ["home"] })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {dataGridArray !== undefined ? (
          dataGridArray.length > 0 ? (
            <DataGrid
              rows={dataGridArray}
              columns={activeMeetingListColumns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {activeMeetingListLoadingValues === true
        ? activemeetingLoader(true)
        : activemeetingLoader(false) && null}
      {appointmentTableBody}
    </Box>
  );
}
