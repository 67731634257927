import {
  GenderListDetails,
  GetGenderListActionTypes,
  GET_GENDER_LIST_FAIL,
  GET_GENDER_LIST_LOADING,
  GET_GENDER_LIST_SUCCESS,
  GET_GENDER_LIST_API_MSG,
  GET_GENDER_LIST_API_RES,
} from "./ActionTypes";
import { GetGenderListRes } from "./Model";

const initialStateGetPosts: GenderListDetails = {
  loading: false,
  getGenderListRes: {} as GetGenderListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getGenderListReducer = (
  state = initialStateGetPosts,
  action: GetGenderListActionTypes,
): GenderListDetails => {
  switch (action.type) {
    case GET_GENDER_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_GENDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getGenderListRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_GENDER_LIST_FAIL:
      return {
        ...state,
        getGenderListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_GENDER_LIST_API_MSG:
      return {
        ...state,
        getGenderListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_GENDER_LIST_API_RES:
      return {
        ...state,
        getGenderListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
