import { GetDoctorContactDetailsRes } from "./Model";
export const GET_DOCTOR_CONTACT_DETAILS_LOADING = "GET_DOCTOR_CONTACT_DETAILS_LOADING";
export const GET_DOCTOR_CONTACT_DETAILS_SUCCESS = "GET_DOCTOR_CONTACT_DETAILS_SUCCESS";
export const GET_DOCTOR_CONTACT_DETAILS_FAIL = "GET_DOCTOR_CONTACT_DETAILS_FAIL";
export const GET_DOCTOR_CONTACT_DETAILS_DATA = "GET_DOCTOR_CONTACT_DETAILS_DATA";
export const GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG =
  "GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG";
export const GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES =
  "GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES";

export interface GetDoctorContactDetailsState {
  getDoctorContactDetailsRes: GetDoctorContactDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorContactDetailsLoading {
  type: typeof GET_DOCTOR_CONTACT_DETAILS_LOADING;
  loading: boolean;
}
export interface GetDoctorContactDetailsSuccess {
  type: typeof GET_DOCTOR_CONTACT_DETAILS_SUCCESS;
  payload: GetDoctorContactDetailsRes;
  successMsg: string;
}
export interface GetDoctorContactDetailsFail {
  type: typeof GET_DOCTOR_CONTACT_DETAILS_FAIL;
  payload: GetDoctorContactDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorContactDetailsUpdateAPIMsg {
  type: typeof GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG;
  payload: GetDoctorContactDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorContactDetailsUpdateAPIRes {
  type: typeof GET_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES;
  payload: GetDoctorContactDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorContactDetailsActionTypes =
  | GetDoctorContactDetailsLoading
  | GetDoctorContactDetailsSuccess
  | GetDoctorContactDetailsFail
  | GetDoctorContactDetailsUpdateAPIMsg
  | GetDoctorContactDetailsUpdateAPIRes;
