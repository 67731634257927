import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import MWSnackbar from "../../../component/MWSnackbar";
import { CaseDetailsBody } from "../../../redux/ConsultationOption/Model";
import { patientCaseDetails } from "../../../redux/ConsultationOption/ApiCall";
import { ResponseTimeBody } from "../../../redux/ConsultationOption/ResponseTimeType/Model";
import { addPatientCase } from "../../../redux/ConsultationOption/AddCase/ApiCall";
import { responseTimeApi } from "../../../redux/ConsultationOption/ResponseTimeType/ApiCall";
import {
  clearEditCaseAPIRes,
  editPatientCase,
} from "../../../redux/ConsultationOption/EditCase/ApiCall";
import { AddCaseBody } from "../../../redux/ConsultationOption/AddCase/Model";

export default function ResponseTimeType() {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  const ResponseTimeTypeListValue = useSelector(
    (state: AppState) => state.responseTimeValue?.responseTimeResponse?.responseTimeTypeList,
  );

  const PatientCaseDetailsValue = useSelector(
    (state: AppState) => state.getPatientCaseDetails?.caseDetailsResponse?.patientCaseDetail,
  );
  const editPatientCaseIdvalue = useSelector((state: AppState) => state.editPatientCase);

  const [isEdit, setIsEdit] = useState(caseid ? false : true);
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [value, setValue] = React.useState("");

  const [alertMsg, setAlertMsg] = useState("");
  const [alertMsgType, setAlertMsgType] = useState<AlertColor>("success");
  const closeAlert = (passedVal: boolean) => {
    if (passedVal) {
      setAlertMsg("");
      dispatch(clearEditCaseAPIRes());
    }
  };

  const addBody = {
    caseId: caseid,
    responseTimeTypeId: value,
  } as AddCaseBody;

  const createCase = async () => {
    if (caseid !== "") {
      await dispatch(editPatientCase(Object.assign(addBody, { caseId: caseid })));
      await setIsEdit(false);
      await setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
      await dispatch(patientCaseDetails({ caseId: caseid } as CaseDetailsBody));
    } else {
      await dispatch(addPatientCase(addBody));
      await dispatch(patientCaseDetails({ caseId: caseid } as CaseDetailsBody));
    }
  };

  useEffect(() => {
    if (caseid !== undefined) {
      dispatch(
        responseTimeApi({
          caseId: caseid,
        } as ResponseTimeBody),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      patientCaseDetails({
        caseId: caseid,
      } as CaseDetailsBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    PatientCaseDetailsValue === undefined
      ? setValue("")
      : setValue(String(PatientCaseDetailsValue?.responseTimeType?.map((item) => item.value)));
  }, [PatientCaseDetailsValue]);

  useEffect(() => {
    if (editPatientCaseIdvalue?.successMsg !== "") {
      setAlertMsg(editPatientCaseIdvalue?.successMsg);
      setAlertMsgType("success");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPatientCaseIdvalue]);

  const thirdComponent = (
    <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item>
        {ResponseTimeTypeListValue?.map((opinion: any) => (
          <FormControl
            key={opinion.value}
            disabled={PatientCaseDetailsValue?.responseTimeType.length > 0 ? !isEdit : isEdit}
          >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue((event.target as HTMLInputElement).value)
              }
            >
              <FormControlLabel value={opinion.value} control={<Radio />} label={opinion.label} />
            </RadioGroup>
          </FormControl>
        ))}
      </Grid>
      <Grid item>
        {PatientCaseDetailsValue?.responseTimeType.length > 0 ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit
                  ? `${t("accountdetail.cancel", { ns: ["home"] })}`
                  : `${t("userprofilepage.edit", { ns: ["home"] })}`}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                {t("demographicdetailpage.update", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button size="large" variant="contained" onClick={createCase}>
              {t("common.add", { ns: ["home"] })}
            </Button>
          </Grid>
        )}
        {alertMsg !== "" ? (
          <MWSnackbar type={alertMsgType} msg={alertMsg} alertClose={closeAlert} />
        ) : null}
      </Grid>
    </Grid>
  );

  return <Container maxWidth="xl">{thirdComponent}</Container>;
}
