import React, { useEffect, useState } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import AdminLoader from "./AdminLoader";
import { getExecutiveTaskList } from "../redux/Task/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Container } from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import { t } from "i18next";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";

export default function ExecutiveTaskDetails() {
  const dispatch = useDispatch();
  const loadingMarkup = <AdminLoader />;
  const { executiveId } = useParams() as {
    executiveId: string;
  };
  const [toSignin] = useState(false);
  const history = useHistory();
  const [listCount, setListCount] = React.useState("");
  function gotoDetails(executiveCaseId: Number, executivePatientId: Number) {
    history.push(`/casedetails/${executivePatientId}/${executiveCaseId}`);
  }
  const executiveTaskListBody = {
    executiveId: executiveId,
  };
  // Executive task list by id api
  const executiveTaskList = () => {
    dispatch(getExecutiveTaskList(executiveTaskListBody));
  };

  // CAll Executive task list from Store
  const executiveTaskListValue = useSelector((state: AppState) => state.executiveTaskList);
  const appointmentList = executiveTaskListValue?.executiveList;

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    executiveTaskListValue?.executiveList !== undefined
      ? setListCount(JSON.stringify(executiveTaskListValue?.executiveList.length))
      : setListCount("0");
    if (appointmentList !== undefined) {
      let dataPointArr = appointmentList.map((element: any) => ({
        id: element.caseId,
        caseId: element.caseId,
        patientId: element.patientId,
        patientName: element.patientName,
        title: element.title,
        secondOpinionReasons: element.secondOpinionReasons,
        consultationType: element.consultationType,
        created_date: dayjs(element.created_date).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveTaskListValue]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("common.view", { ns: ["home"] })!}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.caseId, params.row.patientId);
          }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "title",
      headerName: `${t("admindashboardpage.title", { ns: ["home"] })!}`,
      width: 350,
      flex: 1,
    },
    {
      field: "patientName",
      headerName: `${t("patientcaselistpage.patientname", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "secondOpinionReasons",
      headerName: `${t("patientcaselistpage.secondopinion", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
    {
      field: "consultationType",
      headerName: `${t("patientcaselistpage.consultationtype", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
    {
      field: "created_date",
      headerName: `${t("taskDetails.taskCreateDate", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
  ];

  useEffect(() => {
    executiveTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExecutiveTaskTable = (
    <Box sx={{ pt: 4 }}>
      {appointmentList !== undefined ? (
        appointmentList.length > 0 ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );

  return (
    <Box>
      {executiveTaskListValue?.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <MWPageTitle
          backButton={true}
          title={t("execuList.exeTaskList", { ns: ["home"] })}
          enableCount={true}
          count={listCount}
        />
        {toSignin ? <Redirect to="/signin" /> : null}
        {ExecutiveTaskTable}
      </Container>
    </Box>
  );
}
