import * as React from "react";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import { getPatientTeamList } from "../../redux/PatientTeam/ApiCall";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { PatientList } from "../../redux/PatientTeam/Model";
import { t } from "i18next";
import { Button, Card, CardContent, CardHeader, Container, Grid, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWPageTitle from "../../component/MWPageTitle";
import AddPatientTeamDialog from "./AddPatientTeamDialog";
import MWExceptionList from "../../component/MWExceptionList";

export default function PatientTeam() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientTeamList(value));
  };

  const loadingMarkup = <AdminLoader />;
  // get Patient Team List api store value
  const patientTeamList = useSelector((state: AppState) => state.allPatientTeamListValue);
  const patientTeamListValue = patientTeamList?.allPatientTeamListValue?.patientList;
  const patientTeamPageCount = useSelector(
    (state: AppState) => state.allPatientTeamListValue.patientListPageCount,
  );
  const patientTeamCurrentPage = patientTeamList.patientTeamCurrentPageCount;

  function patientTeamDetails(patientTeamCurrentPage: any, patientDetailsID: any) {
    history.push(`/patientteamdetails/${patientTeamCurrentPage}/${patientDetailsID}`);
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("patientteamlistpage.view", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            patientTeamDetails(patientTeamCurrentPage, params.value);
          }}
          variant="outlined"
        >
          {t("patientteamlistpage.view", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: `${t("patientteamlistpage.userid", { ns: ["home"] })!}`,
      width: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: `${t("patientteamlistpage.firstname", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: `${t("patientteamlistpage.lastname", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: `${t("patientteamlistpage.email", { ns: ["home"] })!}`,
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "username",
      headerName: `${t("patientteamlistpage.phone", { ns: ["home"] })!}`,
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
  ];

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    if (patientTeamListValue !== undefined) {
      let dataPointArr = patientTeamListValue.map((element: PatientList) => ({
        id: element.profileId,
        firstname: element.firstname,
        lastname: element.lastname,
        email: element.email,
        patientId: element.profileId,
        username: element.phone,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientTeamList]);
  // Ask Question success toast
  const [successAddPatientTeamToastActive, setSuccessAddPatientTeamToastActive] = useState(false);
  console.log(successAddPatientTeamToastActive);

  const toggleSuccessAddPatientTeamToastActive = () => {
    setSuccessAddPatientTeamToastActive(
      (successAddPatientTeamToastActive) => !successAddPatientTeamToastActive,
    );
    dispatch(getPatientTeamList(page));
  };
  console.log(toggleSuccessAddPatientTeamToastActive);

  // Ask Question error toast
  const [errorAddPatientTeamToastActive, setErrorAddPatientTeamToastActive] = useState(false);
  console.log(errorAddPatientTeamToastActive);
  const toggleErrorAddPatientTeamToastActive = () => {
    setErrorAddPatientTeamToastActive(
      (errorAddPatientTeamToastActive) => !errorAddPatientTeamToastActive,
    );
    dispatch(getPatientTeamList(page));
  };
  console.log(toggleErrorAddPatientTeamToastActive);

  useEffect(() => {
    dispatch(getPatientTeamList(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Reload Appointment API
  const reloadPatientList = () => {
    dispatch(getPatientTeamList(page));
  };
  const patirntTeamTable = (
    <div>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Typography>Page: {page}</Typography>
              <Pagination
                color="primary"
                count={Number(patientTeamPageCount)}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          }
        />

        <CardContent>
          {patientTeamListValue && patientTeamListValue.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </div>
  );

  const [showAddPatientTeamDialog, setShowAddPatientTeamDialog] = React.useState(false);
  const updatePatientTeamDialogState = () => {
    setShowAddPatientTeamDialog(true);
  };
  const updateAddPatientTeamDialogValFromChild = (passedVal: boolean) => {
    setShowAddPatientTeamDialog(passedVal);
  };

  return (
    <Box>
      {patientTeamList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                title={t("patientteamlistpage.title", { ns: ["home"] })!}
                enableCount={true}
                count={patientTeamList.allPatientTeamListValue.count}
                reload={true}
                reloadAction={reloadPatientList}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={updatePatientTeamDialogState}
                sx={{ textTransform: "none" }}
              >
                {t("patientteamlistpage.addUser", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
          {patirntTeamTable}
          {showAddPatientTeamDialog ? (
            <AddPatientTeamDialog
              addPatientTeamDialogEnable={showAddPatientTeamDialog}
              addPatientTeamDialogUpdateState={updateAddPatientTeamDialogValFromChild}
            />
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
}
