import { CorporatePackageToggleTypes, CORPORATE_PACKAGE_TOGGLE_RESPONSE } from "./ActionTypes";
import { CorporatePackageToggleResponse } from "./Model";

export const getCorporatepackageToggleActions = (
  corporatePackageToggleResponse: CorporatePackageToggleResponse,
): CorporatePackageToggleTypes => {
  return {
    type: CORPORATE_PACKAGE_TOGGLE_RESPONSE,
    payload: corporatePackageToggleResponse,
  };
};
