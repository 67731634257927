import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import RequestPatientAppointment from "./RequestPatientAppointment";
import SelectedOption from "./SelectedOption";
import ExistingAppointmentList from "./ExistingAppointmentList";
import { requestAppointmentDetails } from "../../../redux/Patient/GetAppointmentList/API";
import { GetAppointmentListBody } from "../../../redux/Patient/GetAppointmentList/Model";
import { useDispatch } from "react-redux";

type Props = {
  appointmentBookLoader?: boolean | any;
};
export default function RequestAppointment({ appointmentBookLoader }: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };

  const selectOptionsLoaderUpdatedState = (passedVal: boolean) => {
    appointmentBookLoader(passedVal);
  };
  useEffect(() => {
    dispatch(requestAppointmentDetails({ caseId: caseid } as GetAppointmentListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <SelectedOption selectedAppointmentLoader={selectOptionsLoaderUpdatedState} />
      <RequestPatientAppointment caseId={caseid} />
      <ExistingAppointmentList caseId={caseid} />
    </Stack>
  );
}
