import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Typography,
  Box,
  Alert,
  Button,
  Grid,
  CardContent,
  CardHeader,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Divider,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { AddPatientImportConfigDetailsBody } from "../../../../redux/addPatientImportConfig/Model";
import { PatientImportConfigListDetailsBody } from "../../../../redux/getPatientImportConfigList/Model";
import { EditPatientImportConfigDetailsBody } from "../../../../redux/editPatientImportConfig/Model";
import { editPatientImportConfigApi } from "../../../../redux/editPatientImportConfig/API";
import { addPatientImportConfigApi } from "../../../../redux/addPatientImportConfig/API";
import { getPatientInfortConfigListApi } from "../../../../redux/getPatientImportConfigList/API";
import MWExceptionList from "../../../../component/MWExceptionList";
import MWTextField from "../../../../component/MWTextField";
import { t } from "i18next";
import MWLoader from "../../../../component/MWLoader";

export default function ImportedConfig() {
  const dispatch = useDispatch();
  const [importEmail, setImportEmail] = useState("");
  const [importWhatsapp, setImportWhatsapp] = useState("");
  const [editConfigId, seteditConfigId] = useState("");
  const [phonenumber] = useState("");

  const loadingMarkup = <MWLoader />;
  const [loading, setIsLoading] = React.useState<boolean | undefined>(false);

  const closeerror = () => {
    setError("");
  };
  const [masterConfigList, setMasterConfigList] = useState<any[]>([]);
  const { patientid } = useParams() as {
    patientid: string;
  };

  const [error, setError] = useState("");
  const errorDiv = error ? (
    <Alert onClick={closeerror} severity="error">
      <Typography>{error}</Typography>
    </Alert>
  ) : null;

  const [importedConfigSuccess, setImportedConfigSuccess] = useState("");
  const [toastSuccessActive, setToastSuccessActive] = useState(false);
  const toggleToastSuccessActive = useCallback(
    () => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive),
    [],
  );

  const importedConfigSuccessDiv = toastSuccessActive ? (
    <Snackbar open={toastSuccessActive} autoHideDuration={6000} onClose={toggleToastSuccessActive}>
      <Alert onClose={toggleToastSuccessActive} severity="success" sx={{ width: "100%" }}>
        {importedConfigSuccess}
      </Alert>
    </Snackbar>
  ) : null;

  const [instituteerror] = useState("");
  const instituteErrordiv = instituteerror ? <Typography>{instituteerror}</Typography> : null;

  // objemail: any, objcreatedate: any, objmodifieddate: any, objphno: any, objwhatsappno:any
  function updateImportConfig(
    id: any,
    objemail: any,
    objcreatedate: any,
    objmodifieddate: any,
    objphno: any,
    objwhatsappno: any,
  ) {
    setModalActive((modalActive) => !modalActive);
    seteditConfigId(id);
    setImportEmail(objemail);
    setImportWhatsapp(objwhatsappno);
    setImportedConfigSuccess("");
    setError("");
  }

  const [modalActive, setModalActive] = useState(false);
  const handleToggle = () => {
    setModalActive((modalActive) => !modalActive);
    seteditConfigId("");
    setImportEmail("");
    setImportWhatsapp("");
    // setPhonenumber('');
    setImportedConfigSuccess("");
    setError("");
  };

  let newAwardBody = {
    patientId: patientid,
    email: importEmail,
    phoneNumber: phonenumber,
    whatsappNo: importWhatsapp,
  } as AddPatientImportConfigDetailsBody;

  const getconfig = {
    patientId: patientid,
  } as PatientImportConfigListDetailsBody;

  const addSymptomSubmit = () => {
    if (editConfigId) {
      let editBody = Object.assign(newAwardBody, {
        patientConfigId: editConfigId,
      }) as EditPatientImportConfigDetailsBody;
      dispatch(editPatientImportConfigApi(editBody));
    } else {
      dispatch(addPatientImportConfigApi(newAwardBody));
    }
  };
  const addPatientImportConfig = useSelector((state: AppState) => state.addPatientImportConfig);

  useEffect(() => {
    addPatientImportConfig?.loading ? setIsLoading(true) : setIsLoading(false);
    if (addPatientImportConfig?.addPatientImportConfigListRes.message !== undefined) {
      setToastSuccessActive(true);
      setImportedConfigSuccess(addPatientImportConfig?.addPatientImportConfigListRes.message);
      setError("");
      setModalActive((modalActive) => !modalActive);
      dispatch(getPatientInfortConfigListApi(getconfig));
    } else {
      setError(addPatientImportConfig?.errorMsg);
      setImportedConfigSuccess("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientImportConfig]);

  const getPatientImportConfigList = useSelector(
    (state: AppState) => state.getPatientImportConfigList,
  );

  const editImportConfig = useSelector((state: AppState) => state.editImportConfig);

  useEffect(() => {
    editImportConfig?.loading ? setIsLoading(true) : setIsLoading(false);
    if (editImportConfig?.editPatientImportConfigListRes?.message !== undefined) {
      setToastSuccessActive(true);
      setImportedConfigSuccess(editImportConfig?.editPatientImportConfigListRes?.message);
      setError("");
      setModalActive((modalActive) => !modalActive);
      dispatch(getPatientInfortConfigListApi(getconfig));
    } else {
      setError(editImportConfig?.errorMsg);
      setImportedConfigSuccess("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editImportConfig]);

  useEffect(() => {
    if (
      getPatientImportConfigList?.patientImportConfigListRes?.patientDataImportConfigList !==
      undefined
    ) {
      if (
        getPatientImportConfigList?.patientImportConfigListRes.patientDataImportConfigList !==
        undefined
      ) {
        let masterConfig =
          getPatientImportConfigList?.patientImportConfigListRes.patientDataImportConfigList.map(
            (resData: any) => ({
              id: resData.id,
              objcreatedate: new Date(resData.createDate).toLocaleDateString("en-US", DATE_OPTIONS),
              objmodifieddate: new Date(resData.modifiedDate).toLocaleDateString(
                "en-US",
                DATE_OPTIONS,
              ),
              objemail: resData.email,
              objwhatsappno: resData.whatsappNo,
              objphno: resData.phoneNumber,
            }),
          );
        setMasterConfigList(masterConfig);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientImportConfigList]);

  useEffect(() => {
    dispatch(getPatientInfortConfigListApi(getconfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  const columns: GridColDef[] = [
    { field: "objemail", headerName: `${t("common.emailorphno", { ns: ["home"] })!}`, flex: 1 },
    {
      field: "objcreatedate",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "objmodifieddate",
      headerName: `${t("appointmentpage.modifieddate", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "objwhatsappno",
      headerName: `${t("importdadaPage.wpNo", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })!}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            updateImportConfig(
              params.row.id,
              params.row.objemail,
              params.row.objcreatedate,
              params.row.objmodifieddate,
              params.row.objphno,
              params.row.objwhatsappno,
            );
          }}
        >
          {t("common.edit", { ns: ["home"] })}
        </Button>
      ),
    },
  ];

  const masterImportConfigList = (
    <Box sx={{ maxHeight: "340px", overflow: "auto" }}>
      <CardContent>
        {masterConfigList && masterConfigList.length ? (
          <DataGrid
            rows={masterConfigList}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Box>
  );

  const importedConfigDetails = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography>{t("importdadaPage.impConfigs", { ns: ["home"] })!}</Typography>

              <Grid item>
                <Button variant="contained" onClick={handleToggle}>
                  {t("impConfigPage.addConfig", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        {masterImportConfigList}
      </Card>
      <Dialog open={modalActive} onClose={handleToggle} fullWidth>
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <Typography>
                {editConfigId
                  ? `${t("impConfigPage.updateImpConfig", { ns: ["home"] })!}`
                  : `${t("impConfigPage.addImpConfig", { ns: ["home"] })!}`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleToggle}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            p={2}
          >
            <Grid item>
              <MWTextField
                fullWidth
                value={importEmail}
                onChange={(event: any) => setImportEmail(event?.target.value)}
                label={t("impConfigPage.impPatData", { ns: ["home"] })!}
                type="email"
                placeholder={t("impConfigPage.enterEmAdd", { ns: ["home"] })!}
                id="Emailname"
              />
            </Grid>
            <Typography>{instituteErrordiv}</Typography>
            <Grid item>
              <MWTextField
                fullWidth
                value={importWhatsapp}
                onChange={(event: any) => setImportWhatsapp(event.target.value)}
                label={t("impConfigPage.wpPatData", { ns: ["home"] })!}
                type="number"
                placeholder={t("impConfigPage.enterWpNo", { ns: ["home"] })!}
              />
            </Grid>
            <Box pt={2}>{errorDiv}</Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addSymptomSubmit}>
            {t("Workschedulepage.submit", { ns: ["home"] })}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
  return (
    <Box>
      {loading ? loadingMarkup : null}
      {importedConfigDetails}
      {importedConfigSuccessDiv}
    </Box>
  );
}
